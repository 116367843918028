
import Vue from "vue";

export default Vue.extend({
  name: "AverageGiftWidget",
  props: {
    pledgesToReport: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    pledgesToReport: function () {
      this.getPledgesData();
    },
  },
  data() {
    return {
      total: "0",
    };
  },
  mounted() {
    this.getPledgesData();
  },
  methods: {
    getPledgesData() {
      this.total = "0";

      if (this.pledgesToReport.length == 0) {
        return;
      }

      let avgGiftSum = 0;

      for (var pledge of this.pledgesToReport) {
        avgGiftSum += ((pledge as any).annualPledgeAmount || 0) / 12;
      }

      const result = (avgGiftSum || 0) / this.pledgesToReport.length;

      this.total = result.toFixed(2);
    },
  },
});
