
import Vue from "vue";

export default Vue.extend({
  name: "VersionComponent",
  props: {},
  data() {
    return {
      year: new Date().getFullYear(),
      version: process.env.VUE_APP_EDC_VERSION,
    };
  },
});
