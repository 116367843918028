
import Vue from "vue";
import { LogService } from "@/services/logService";
import { Udf } from "@/interfaces/udf.interface";
import { BasePledge } from "@/models/BasePledge";

export default Vue.extend({
  name: "UdfsComponent",
  props: {
    pledge: {
      type: Object as () => BasePledge,
    },
    validationErrors: {
      type: Object as () => Record<string, { message: string }>,
    },
    page: String,
  },
  //   data() {
  //     return {
  //       udfs: [] as Array<Udf>,
  //     };
  //   },
  computed: {
    udfs(): Array<Udf> {
      const pageUdfs = this.pledge.pledgeUdfs.filter((o) => o.page == this.page);
      return pageUdfs;
    },
  },
  beforeMount: async function () {
    // before
  },
  methods: {
    clearError(field: string) {
      if (this.validationErrors && this.validationErrors[field]) {
        delete this.validationErrors[field];
      }
    },
  },
});
