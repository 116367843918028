import { v4 as uuidv4 } from "uuid";
import { CryptHelper } from "@/helpers/cryptHelper";
import { Udf } from "@/interfaces/udf.interface";
import { BasePledge } from "../BasePledge";
import parsePhoneNumber from "libphonenumber-js/max";
import { PledgeLog } from "../PledgeLog";

export abstract class BaseCallback {
  callbackGuid: string;
  callbackId: number;
  edcPledgeId: number;
  dateCreated: Date | null;

  clientId: number;
  clientEdcName: string;
  locationId: number;
  bookingId: number;
  fundraiserId: number;
  longitude: number | null;
  latitude: number | null;

  type: string;
  company: string;
  position: string;
  title: string;
  gender: string;
  firstName: string;
  lastName: string;
  occupation: string;
  dateOfBirth: Date | null;
  country: string;
  suggestedAddress: string;
  mobilePhone: string;
  homePhone: string;
  workPhone: string;
  phoneToCall: string;
  verificationSmsResponse: string | null;
  creationSmsResponse: string | null;
  email: string;
  frequency: string | null;
  amount: number;
  paymentMethod: string;
  firstPaymentDate: string | null;
  agreedDate: Date | null;
  agreedHour: number;
  agreedMinute: number | null;
  ampm: string;
  notes: string | null;

  firstSignature: string;
  secondSignature: string;
  thirdSignature: string;
  signature: string;

  callbackLogs?: Array<PledgeLog>;
  callbackUdfs: Array<Udf>;

  constructor(basePledge: BasePledge) {
    this.callbackGuid = uuidv4();
    this.callbackId = 0;
    this.dateCreated = null;
    this.edcPledgeId = 0;

    this.fundraiserId = basePledge.fundraiserId;
    this.clientId = basePledge.clientId;
    this.clientEdcName = "";
    this.locationId = basePledge.locationId;
    this.bookingId = basePledge.bookingId;
    this.longitude = basePledge.longitude;
    this.latitude = basePledge.latitude;

    this.type = basePledge.type;
    this.company = basePledge.company;
    this.position = basePledge.position;
    this.title = basePledge.title;
    this.gender = basePledge.gender;
    this.firstName = basePledge.firstName;
    this.lastName = basePledge.lastName;
    this.occupation = basePledge.occupation;
    this.dateOfBirth = basePledge.dateOfBirth;
    this.country = basePledge.country;
    this.suggestedAddress = basePledge.suggestedAddress;
    this.mobilePhone = basePledge.mobilePhone;
    this.homePhone = basePledge.homePhone;
    this.workPhone = basePledge.workPhone;
    this.phoneToCall = "";
    this.verificationSmsResponse = basePledge.verificationSmsResponse;
    this.creationSmsResponse = "";
    this.email = basePledge.email;
    this.frequency = basePledge.frequency;
    this.amount = basePledge.amount;
    this.paymentMethod = basePledge.paymentMethod;
    this.firstPaymentDate = basePledge.firstPaymentDate;
    this.agreedDate = null;
    this.agreedHour = 0;
    this.agreedMinute = null;
    this.ampm = "";
    this.notes = "";

    this.firstSignature = "";
    this.secondSignature = "";
    this.thirdSignature = "";
    this.signature = "";

    // this.callbackLogs = basePledge.pledgeLogs;
    this.callbackUdfs = basePledge.pledgeUdfs;
  }

  validateCallbackCreation(): Record<string, { message: string }> {
    const validationErrors = {} as Record<string, { message: string }>;

    if (!this.agreedDate) validationErrors["agreedDate"] = { message: "Agreed date required" };
    if (!this.agreedHour) validationErrors["agreedHour"] = { message: "Agreed hour required" };
    if (this.agreedMinute != 0 && !this.agreedMinute)
      validationErrors["agreedMinute"] = { message: "Agreed minute required" };
    if (!this.ampm) validationErrors["ampm"] = { message: "AM/PM required" };

    const now = new Date();
    if (!this.agreedDate) validationErrors["agreedHour"] = { message: "Agreed time required" };
    else if (this.agreedDate <= now) validationErrors["agreedHour"] = { message: "Agreed time cannot be in the past" };

    // phone to call
    if (!this.phoneToCall) {
      validationErrors["phoneToCall"] = { message: "Phone required" };
    } else {
      const phone = parsePhoneNumber(this.phoneToCall, this.getCountryCode());
      if (!phone?.isValid()) {
        validationErrors["phoneToCall"] = { message: "Phone not valid" };
      }
    }

    if (this.notes && this.notes.length > 75) validationErrors["notes"] = { message: "Maximum 75 characters allowed" };

    // signatures
    if (!this.firstSignature) validationErrors["firstSignature"] = { message: "Signature required" };
    if (!this.secondSignature) validationErrors["secondSignature"] = { message: "Signature required" };
    if (!this.thirdSignature) validationErrors["thirdSignature"] = { message: "Signature required" };
    if (!this.signature) validationErrors["signature"] = { message: "Signature required" };

    return validationErrors;
  }

  /* CREATE A HELPER FOR THE DUPLICATED METHODS BELOW */
  getCountryCode(): "AU" | "NZ" {
    return this.country == "Australia" ? "AU" : "NZ";
  }

  assignJson(jsonObject: BaseCallback): BaseCallback {
    const obj = Object.assign(this, jsonObject);

    this.changeDateFieldsFromStringToDate(obj);

    return obj;
  }

  private changeDateFieldsFromStringToDate(obj: this & BaseCallback) {
    if (typeof obj.dateOfBirth == "string") {
      obj.dateOfBirth = new Date(obj.dateOfBirth);
    }

    if (typeof obj.agreedDate == "string") {
      obj.agreedDate = new Date(obj.agreedDate);
    }
  }

  encrypted(rsaPublicKey: string): string {
    const enc = CryptHelper.encryptAES(JSON.stringify(this));
    const encKey = CryptHelper.encryptRSA(enc.key, rsaPublicKey);
    const encIv = CryptHelper.encryptRSA(enc.iv, rsaPublicKey);

    return `${encKey}\n${encIv}\n${enc.encryptedPayload}`;
  }

  /**
   * Calculate age using the date of birth, from: https://stackoverflow.com/a/21984136/4119092
   * @returns age
   */
  calculateAge(birthday: Date): number {
    // birthday is a date
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  /**
   * Create a pledge from a callback
   * @returns Pledge (Australia or NewZealand pledge depending on the callback country)
   */
  abstract createPledge(): BasePledge;
}
