import { v4 as uuidv4 } from "uuid";

export class PledgeLog {
  logGuid: string;
  type: "debug" | "info" | "warning" | "error";
  datetime: string;
  latitude: number | null;
  longitude: number | null;
  data: unknown;

  constructor(type: "debug" | "info" | "warning" | "error", latitude: number | null, longitude: number | null) {
    this.logGuid = uuidv4();
    this.type = type;
    this.datetime = new Date().toISOString();
    this.data = {};
    this.latitude = latitude;
    this.longitude = longitude;
  }
}
