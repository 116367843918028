import { AustraliaPledge } from "@/models/AustraliaPledge";
import { BaseCallback } from "@/models/Callback/BaseCallback";
import { BasePledge } from "../BasePledge";

export class AustraliaCallback extends BaseCallback {
  isPoBox: boolean;
  poBox: string;
  unit: string;
  streetNumber: string;
  street: string;
  suburb: string;
  postcode: string;
  state: string;

  constructor(ausPledge: AustraliaPledge) {
    super(ausPledge);

    this.isPoBox = ausPledge.isPoBox;
    this.poBox = ausPledge.poBox;
    this.unit = ausPledge.unit;
    this.streetNumber = ausPledge.streetNumber;
    this.street = ausPledge.street;
    this.suburb = ausPledge.suburb;
    this.postcode = ausPledge.postcode;
    this.state = ausPledge.state;
  }

  get formattedStreet(): string {
    let result = "";

    if (this.isPoBox) {
      result = this.poBox;
    } else {
      if (this.unit && this.streetNumber) {
        result = `${this.unit}/${this.streetNumber} ${this.street}`;
      } else if (this.streetNumber) {
        result = `${this.streetNumber} ${this.street}`;
      } else {
        result = this.street;
      }
    }

    return result;
  }

  /**CREATE HELPER FOR DUPLICATED METHOD BELOW */

  clearAddressDetails() {
    this.poBox = "";
    this.unit = "";
    this.streetNumber = "";
    this.street = "";
    this.suburb = "";
    this.postcode = "";
    this.state = "";
  }

  createPledge(): BasePledge {
    const pledge = new AustraliaPledge(this.fundraiserId, this.clientId, this.locationId, this.bookingId);

    pledge.callbackId = this.callbackId;
    pledge.edcPledgeId = this.edcPledgeId;
    pledge.clientId = this.clientId;
    pledge.locationId = this.locationId;

    // coordinates
    pledge.longitude = this.longitude;
    pledge.latitude = this.latitude;
    // personal details
    pledge.type = this.type;
    pledge.company = this.company;
    pledge.position = this.position;
    pledge.title = this.title;
    pledge.gender = this.gender;
    pledge.firstName = this.firstName;
    pledge.lastName = this.lastName;
    pledge.occupation = this.occupation;
    pledge.dateOfBirth = this.dateOfBirth;
    // contact details
    pledge.country = this.country;
    pledge.suggestedAddress = this.suggestedAddress;
    pledge.isPoBox = this.isPoBox;
    pledge.poBox = this.poBox;
    pledge.unit = this.unit;
    pledge.streetNumber = this.streetNumber;
    pledge.street = this.street;
    pledge.suburb = this.suburb;
    pledge.postcode = this.postcode;
    pledge.state = this.state;
    pledge.mobilePhone = this.mobilePhone;
    pledge.homePhone = this.homePhone;
    pledge.workPhone = this.workPhone;
    pledge.verificationSmsResponse = this.verificationSmsResponse || "";
    pledge.email = this.email;
    // contribution details
    pledge.frequency = this.frequency;
    pledge.amount = this.amount;
    pledge.paymentMethod = this.paymentMethod;
    pledge.firstPaymentDate = this.firstPaymentDate;
    // signatures
    pledge.firstSignature = this.firstSignature;
    pledge.secondSignature = this.secondSignature;
    pledge.thirdSignature = this.thirdSignature;
    pledge.signature = this.signature;
    // logs
    pledge.pledgeLogs = this.callbackLogs;
    pledge.pledgeUdfs = this.callbackUdfs;

    return pledge;
  }
}
