
import { ToastHelper } from "@/helpers/toastHelper";
import { ComplianceService } from "@/services/complianceService";
import { LogService } from "@/services/logService";
import Vue from "vue";

export default Vue.extend({
  name: "ComplianceTextQuestions",
  components: {},
  props: {
    clientId: {
      type: Number,
    },
  },
  computed: {
    online(): boolean {
      return this.$store.state.onlineIndicator;
    },
    fundraiserId(): number {
      return this.$store.getters.currentUserId;
    },
  },
  watch: {},
  data() {
    return {
      loadingQuestions: false,
      questions: [] as Array<any>,
    };
  },
  mounted() {
    this.loadQuestions();
  },
  methods: {
    loadQuestions() {
      this.loadingQuestions = true;
      new ComplianceService()
        .getTextQuestionsWithAnswers(this.clientId)
        .then((response) => {
          this.questions = response;
        })
        .finally(() => {
          this.loadingQuestions = false;
        });
    },
    saveAndProceed() {
      for (const item of this.questions) {
        if (!item.answer || !item.answer.trim()) {
          ToastHelper.error("Please answer all questions before proceeding");
          return;
        }
      }

      new ComplianceService()
        .saveTextAnswers(this.questions)
        .then((response) => {
          console.log(response);
          ToastHelper.success("Text answers saved successfully");
          this.$emit("moveNext", true);
        })
        .catch(() => {
          ToastHelper.error("Error saving text answers. Please contact IT support");
        });
    },
  },
});
