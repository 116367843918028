import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
import Login from "../views/Login.vue";
import Setup from "../views/Setup.vue";
import Reports from "../views/Reports.vue";
import ComplianceReport from "../views/Compliance/ComplianceReport.vue";
import NewCompliance from "../views/Compliance/NewCompliance.vue";
import Profile from "../views/Profile.vue";
import RecoverPledge from "../views/RecoverPledge.vue";
// import CovidSafety from "../views/CovidSafety.vue";
import PersonalDetails from "../views/PledgeSignFlow/PersonalDetails.vue";
import ContactDetails from "../views/PledgeSignFlow/ContactDetails.vue";
import ContributionDetails from "../views/PledgeSignFlow/ContributionDetails.vue";
import PaymentDetails from "../views/PledgeSignFlow/PaymentDetails.vue";
import Confirmation from "../views/PledgeSignFlow/Confirmation.vue";
import Thankyou from "../views/PledgeSignFlow/ThankYou.vue";
import CreateCallback from "../views/Callback/CreateCallback.vue";
import CallbackCreated from "../views/Callback/CallbackCreated.vue";
import Callbacks from "../views/Callback/Callbacks.vue";
import CallbackContributionDetails from "../views/Callback/CallbackContributionDetails.vue";
import CallbackPaymentDetails from "../views/Callback/CallbackPaymentDetails.vue";
import CallbackConfirmation from "../views/Callback/CallbackConfirmation.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // {
  //   path: "/",
  //   name: "Covid Safety",
  //   component: CovidSafety,
  //   meta: {
  //     requiresAuth: false,
  //     requiresClient: false,
  //   },
  // },
  {
    // path: "/login",
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
      requiresClient: false,
    },
  },
  {
    path: "/setup",
    name: "Setup",
    component: Setup,
    meta: {
      requiresAuth: true,
      requiresClient: false,
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
      requiresClient: false,
    },
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    meta: {
      requiresAuth: true,
      requiresClient: false,
    },
  },
  {
    path: "/compliance/report",
    name: "ComplianceReport",
    component: ComplianceReport,
    meta: {
      requiresAuth: true,
      requiresClient: false,
    },
  },
  {
    path: "/compliance/new",
    name: "NewCompliance",
    component: NewCompliance,
    meta: {
      requiresAuth: true,
      requiresClient: false,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
      requiresClient: false,
    },
  },
  {
    path: "/recoverpledge",
    name: "RecoverPledge",
    component: RecoverPledge,
    meta: {
      requiresAuth: true,
      requiresClient: false,
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/pledge/personaldetails",
    name: "Personal Details",
    component: PersonalDetails,
    meta: {
      requiresAuth: true,
      requiresClient: true,
    },
  },
  {
    path: "/pledge/contactdetails",
    name: "Contact Details",
    component: ContactDetails,
    meta: {
      requiresAuth: true,
      requiresClient: true,
    },
  },
  {
    path: "/pledge/contributiondetails",
    name: "Contribution Details",
    component: ContributionDetails,
    meta: {
      requiresAuth: true,
      requiresClient: true,
    },
  },
  {
    path: "/pledge/paymentdetails",
    name: "Payment Details",
    component: PaymentDetails,
    meta: {
      requiresAuth: true,
      requiresClient: true,
    },
  },
  {
    path: "/pledge/confirmation",
    name: "confirmation",
    component: Confirmation,
    meta: {
      requiresAuth: true,
      requiresClient: true,
    },
  },
  {
    path: "/pledge/thankyou",
    name: "thankyou",
    component: Thankyou,
    meta: {
      requiresAuth: true,
      requiresClient: true,
    },
  },
  {
    path: "/callback/createcallback",
    name: "CreateCallback",
    component: CreateCallback,
    meta: {
      requiresAuth: true,
      requiresClient: true,
    },
  },
  {
    path: "/callback/callbackcreated",
    name: "CallbackCreated",
    component: CallbackCreated,
    meta: {
      requiresAuth: true,
      requiresClient: true,
    },
  },
  {
    path: "/callback/callbacks",
    name: "Callbacks",
    component: Callbacks,
    meta: {
      requiresAuth: true,
      requiresClient: true,
    },
  },
  {
    path: "/callback/contributiondetails/",
    name: "CallbackContributionDetails",
    component: CallbackContributionDetails,
    meta: {
      requiresAuth: true,
      requiresClient: true,
    },
  },
  {
    path: "/callback/paymentdetails/",
    name: "CallbackPaymentDetails",
    component: CallbackPaymentDetails,
    meta: {
      requiresAuth: true,
      requiresClient: true,
    },
  },
  {
    path: "/callback/confirmation/",
    name: "CallbackConfirmation",
    component: CallbackConfirmation,
    meta: {
      requiresAuth: true,
      requiresClient: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    if (!store.getters.isLoggedIn || store.getters.isTokenExpired) {
      store.dispatch("logout"); // login out if a token exists and is expired
      // redirect to the login page.
      next({
        // path: "/login",
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else {
      // this route requires a client selected
      if (to.matched.some((record) => record.meta.requiresClient)) {
        if (!store.state.selectedClient) {
          // redirect to the setup page
          next({
            path: "/setup",
            query: { redirect: to.fullPath },
          });
        } else {
          next(); // requires auth and client
        }
      } else {
        next(); // requires auth but no client
      }
    }
  } else {
    next(); // requires nothing
  }
});

export default router;
