export interface CssDetails {
  buttonBackgroundColour: string | null;
  buttonHoverColour: string | null;
  buttonFontColour: string | null;
  buttonBorderColour: string | null;
  bodyBackgroundColour: string | null;
  headerBackgroundColour: string | null;
  headerFontColour: string | null;
}

export class ClientStyleBuilder {
  cssDetails: CssDetails;
  buttonStyle: Record<string, string>;

  constructor(
    cssDetails: CssDetails = {
      buttonBackgroundColour: "#ffffff", // "#e4b163",
      buttonHoverColour: "#e4b163",
      buttonFontColour: "black",
      buttonBorderColour: "#e4b163",
      bodyBackgroundColour: "#ffffff",
      headerBackgroundColour: "",
      headerFontColour: "black",
    }
  ) {
    this.cssDetails = cssDetails;

    this.buttonStyle = this.buildButtonStyle();
  }

  buildButtonStyle() {
    const styleObj: any = {};

    styleObj.backgroundColor = this.cssDetails?.buttonBackgroundColour || "#fff";
    styleObj.color = this.cssDetails?.buttonFontColour || "#000";

    if (this.cssDetails?.buttonBorderColour) {
      styleObj.border = `solid 1px ${this.cssDetails?.buttonFontColour}`;
    }

    return styleObj;
  }
}
