import { ToastProgrammatic as Toast } from "buefy";

export class ToastHelper {
  static success(message: string) {
    Toast.open({
      message: message,
      queue: false,
      type: "is-success",
      duration: 3000,
    });
  }

  static info(message: string) {
    Toast.open({
      message: message,
      queue: false,
      type: "is-info",
      duration: 3000,
    });
  }

  static warn(message: string) {
    Toast.open({
      message: message,
      queue: false,
      type: "is-warning",
      duration: 3000,
    });
  }

  static error(message: string) {
    Toast.open({
      message: message,
      queue: false,
      type: "is-danger",
      duration: 5000,
    });
  }
}
