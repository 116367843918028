import { v4 as uuidv4 } from "uuid";

export class Log {
  edcLogId: number;
  logGuid: string;
  type: "debug" | "info" | "warning" | "error";
  datetime: string;
  fundraiserId: number | null;
  latitude: number | null;
  longitude: number | null;
  deviceData: { deviceGuid: string; language: string; platform: string; agent: string };
  data: unknown;
  appVersion: string | undefined;
  pledgeGuid: string | null;

  constructor(
    type: "debug" | "info" | "warning" | "error",
    fundraiserId: number | null,
    geo: GeolocationPosition | null,
    deviceData: { deviceGuid: string; language: string; platform: string; agent: string }
  ) {
    this.edcLogId = 0;
    this.logGuid = uuidv4();
    this.type = type;
    this.datetime = new Date().toISOString();
    this.fundraiserId = fundraiserId;
    this.deviceData = deviceData;
    this.pledgeGuid = null;
    this.data = {};
    this.appVersion = process.env.VUE_APP_EDC_VERSION;

    this.latitude = null;
    this.longitude = null;
    if (geo && geo.coords) {
      this.latitude = geo.coords.latitude;
      this.longitude = geo.coords.longitude;
    }
  }
}
