
import Vue from "vue";

import CreditCardComponent from "@/components/Payment/CreditCardComponent.vue";
import DirectDebitAustraliaComponent from "@/components/Payment/DirectDebitAustraliaComponent.vue";
import DirectDebitNewZealandComponent from "@/components/Payment/DirectDebitNewZealandComponent.vue";
import DirectDebitStripeBecsComponent from "@/components/Payment/DirectDebitStripeBecsComponent.vue";
import PledgeFlowTemplateComponent from "@/components/PledgeFlowTemplateComponent.vue";
import UdfsComponent from "@/components/UdfsComponent.vue";
import { ToastHelper } from "@/helpers/toastHelper";
import { IClient } from "@/interfaces/IClient";
import { AustraliaPledge } from "@/models/AustraliaPledge";
import { BasePledge } from "@/models/BasePledge";
import { ClientStyleBuilder } from "@/models/ClientStyleBuilder";
import { BankDetailsService } from "@/services/bankDetailsService";
import { LogService } from "@/services/logService";
import { PledgeService } from "@/services/pledgeService";
import { StripeService } from "@/services/StripeService";

export default Vue.extend({
  name: "PaymentDetails",
  components: {
    CreditCardComponent,
    DirectDebitAustraliaComponent,
    DirectDebitNewZealandComponent,
    DirectDebitStripeBecsComponent,
    PledgeFlowTemplateComponent,
    UdfsComponent,
  },
  data() {
    return {
      isLoading: false,
      oldPledge: {} as BasePledge,
      pledge: {} as BasePledge,
      validationErrors: {} as Record<string, { message: string }>,
    };
  },
  computed: {
    cssStyle(): ClientStyleBuilder {
      const client = this.$store.getters.selectedClient as IClient;
      return new ClientStyleBuilder(client?.cssDetails);
    },
    usingStripe(): boolean {
      const client = this.$store.getters.selectedClient as IClient;
      return client.isStripeDD;
    },
  },
  beforeMount: function () {
    const currentPledge = this.$store.getters.currentPledge;
    if (currentPledge) this.pledge = currentPledge;

    this.oldPledge = Object.assign({}, this.pledge);

    console.log("method:", this.pledge.paymentMethod);

    if (this.pledge.paymentMethod.trim().toUpperCase() == "CREDIT CARD") {
      this.pledge.clearDirectDebitDetails();
    } else {
      this.pledge.clearCreditCardDetails();
    }
  },
  methods: {
    async nextButton() {
      this.isLoading = true;
      // validate payment details
      this.validationErrors = await this.pledge.validatePaymentDetails(
        this.$store.state.rsaPublicKey,
        new BankDetailsService()
      );
      this.isLoading = false;

      // validate udfs
      const udfsValidationErrors = this.pledge.validateUdfs("payment-details");
      this.validationErrors = { ...this.validationErrors, ...udfsValidationErrors };

      // try to tokenize with payshield if online and payment method is credit card
      if (
        this.$store.state.onlineIndicator &&
        this.pledge.paymentMethod.trim().toUpperCase() == "CREDIT CARD" &&
        this.pledge.payshieldApproved == false
      ) {
        LogService.pledgeLog("Attempting to tokenize with payshield", this.pledge);

        this.isLoading = true;
        const response = await new PledgeService().tokenizePledgeCcNumberWithPayshield(this.pledge);
        this.isLoading = false;

        LogService.pledgeLog(`Payshield response: ${JSON.stringify(response)}`, this.pledge);

        if (response.success == false) {
          if (response.message == "Failed to reach PaymentsAPI") {
            // problem reaching the paymentsAPI, allow to continue
            ToastHelper.warn("Error communicating with the payment services, tokenization will happen at a later time");
          } else if (response.message == "Problems communicating with payment provider") {
            // problem reaching the payment provider, allow to continue
            ToastHelper.warn(
              "There were problems communicating with payment provider, tokenization will happen at a later time"
            );
          } else if (response.message == "Credit card rejected by SequenceShift") {
            // payshield rejected the CC, block flow
            this.validationErrors = {
              ...this.validationErrors,
              creditCardNumber: { message: "Credit card rejected" },
            };
          } else if (response.message) {
            // any error came from payment provider, block flow
            this.validationErrors = {
              ...this.validationErrors,
              creditCardNumber: { message: response.message },
            };
          } else {
            // unknown error, allow to continue
            LogService.pledgeLogError(`Unknown error while tokenizing cc`, this.pledge);
          }
        }
      }

      // check for any errors
      if (this.validationErrors && Object.keys(this.validationErrors).length > 0) {
        LogService.error({ message: "Erros validating payment details", validationErrors: this.validationErrors });
        return;
      }

      if (this.usingStripe && this.pledge.paymentMethod.trim().toUpperCase() == "DIRECT DEBIT") {
        LogService.pledgeLog("Attempting to tokenize with Stripe BECS", this.pledge);

        if (!this.$store.getters.getOnlineIndicator) {
          LogService.pledgeLogError("Attempted to tokenize with Stripe BECS while offline", this.pledge);

          this.$buefy.toast.open({
            type: "is-danger",
            message: "Cannot tokenise with Stripe while offline!",
            duration: 5000,
          });

          return;
        }

        if (!this.pledge.email.match(/.+@.+\..+/)) {
          LogService.pledgeLogError("Attempted to tokenize with Stripe BECS without email address", this.pledge);

          this.$buefy.toast.open({
            type: "is-danger",
            message: "Failed to tokenise - Missing email address (required by Stripe)",
            duration: 5000,
          });

          return;
        }

        this.isLoading = true;
        const token = await StripeService.tokenise(this.pledge as AustraliaPledge);
        this.isLoading = false;

        if (token.success) {
          LogService.pledgeLog(`Stripe BECS token: ${token.data}}`, this.pledge);
        } else {
          this.$buefy.toast.open({
            type: "is-danger",
            message: `Failed to tokenise - ${token.error}`,
            duration: 5000,
          });
          return;
        }
      }

      await LogService.pledgeChangesLog(
        `Payment details fulfilled, going to confimation page`,
        this.oldPledge,
        this.pledge
      );

      // save pledge
      try {
        this.$store.dispatch("setCurrentPledge", this.pledge);
      } catch {
        console.error("error");
      }

      // redirect to the next page
      this.$router.push("/pledge/confirmation");
    },
    previousButton: function () {
      // redirect to contribution details
      this.$router.push("/pledge/contributiondetails");
    },
    clearPledge() {
      this.$store.dispatch("clearCurrentPledge", this.pledge);
    },
  },
});
