
import Vue from "vue";

export default Vue.extend({
  name: "WelcomeCallContactWidget",
  props: {
    pledgesToReport: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    pledgesToReport: function () {
      this.getPledgesData();
    },
  },
  data() {
    return {
      total: "0",
    };
  },
  mounted() {
    this.getPledgesData();
  },
  methods: {
    getPledgesData() {
      this.total = "0";

      if (this.pledgesToReport.length == 0) {
        return;
      }

      const fundraisersArray = [];
      let contactMadeSum = 0;

      for (var pledge of this.pledgesToReport) {
        const contactMade = (pledge as any).contactMade;

        if (contactMade) contactMadeSum++;
      }

      this.total = ((contactMadeSum / this.pledgesToReport.length) * 100).toFixed(2);
    },
  },
});
