import { LogService } from "@/services/logService";

export class LogSyncHelper {
  syncingLogs = false;

  private constructor() {
    this.setSyncInterval();
  }

  // singleton
  private static instance: LogSyncHelper;
  public static getInstance(): LogSyncHelper {
    if (!this.instance) this.instance = new LogSyncHelper();
    return this.instance;
  }

  private setSyncInterval() {
    const logSyncInterval = Number(process.env.VUE_APP_LOG_SYNC_INTERVAL);

    setInterval(async () => {
      if (this.syncingLogs) return; // don't try to sync if already in progress (taking too long)

      try {
        this.syncingLogs = true;
        await this.syncLogs();
      } catch (error: any) {
        console.error("Error syncing logs", error);
      } finally {
        this.syncingLogs = false;
      }
    }, logSyncInterval);
  }

  private async syncLogs() {
    // console.log("syncing logs...");
    const logService = LogService.getInstance();
    await logService.syncLogs();
  }
}
