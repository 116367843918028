
import { NewZealandPledge } from "@/models/NewZealandPledge";
import Vue from "vue";

export default Vue.extend({
  name: "DirectDebitNewZealandComponent",
  props: {
    pledge: {
      type: Object as () => NewZealandPledge,
    },
    validationErrors: {
      type: Object as () => Record<string, { message: string }>,
    },
  },
  data() {
    return {
      authorizedSignatorReasons: [
        "Previous known name",
        "Authorised signator of business card",
        "Authorised signator of partners account",
        "Preferred name differs from given name",
      ],
    };
  },
  computed: {
    authorizedSignator(): boolean {
      if (this.pledge.firstName || this.pledge.lastName)
        return this.pledge.accountName == this.pledge.firstName + " " + this.pledge.lastName;
      return true;
    },
  },
  beforeMount() {
    if (!this.pledge.accountName) {
      this.pledge.accountName = this.pledge.firstName + " " + this.pledge.lastName;
    }

    if (this.pledge.accountName == this.pledge.firstName + " " + this.pledge.lastName) {
      this.pledge.authorizedSignatorReason = null;
    }
  },
  methods: {
    clearError(field: string) {
      if (this.validationErrors && this.validationErrors[field]) {
        delete this.validationErrors[field];
      }
    },
    accountNameChange() {
      this.clearError("accountName");

      if (this.pledge.accountName == this.pledge.firstName + " " + this.pledge.lastName) {
        this.pledge.authorizedSignatorReason = null;
      }
    },
  },
});
