import { Database } from "@/database";
import { IsOnlineHelper } from "@/helpers/isOnlineHelper";
import { IClient } from "@/interfaces/IClient";
import { BaseService } from "./baseService";
import { LogService } from "./logService";

export class ClientService extends BaseService {
  database: Database;
  onlineHelper: IsOnlineHelper;

  constructor() {
    super("clients");
    this.database = Database.getInstance();
    this.onlineHelper = IsOnlineHelper.getInstance();
  }

  async getAvailableClients(searchOnline = true): Promise<IClient[]> {
    try {
      LogService.info(`Getting clients. Searching online: ${searchOnline}`);

      const isOnline = await this.onlineHelper.isOnline();

      if (isOnline && searchOnline) {
        LogService.info(`App online, feaching the database for available clients`);

        const axiosInstance = await this.getAxiosInstance();
        const response = await axiosInstance.get<IClient[]>("/availableclients");

        // store clients in the database
        if (response && response.data) {
          await this.database.clients.clear();
          await this.database.clients.bulkPut(response.data);
        }

        LogService.info(
          `${response.data.length} clients retrieved from the API, IndexedDB updated with the new information`
        );

        return response.data;
      } else {
        LogService.info(`App offline. Getting clients from IndexedDB`);

        // get clients from database
        const clients = await this.database.clients.toArray();

        LogService.info(`${clients.length} clients found in the IndexedDB`);

        return clients;
      }
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }
}
