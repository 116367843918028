import { BaseService } from "./baseService";
import { LogService } from "./logService";
import { IKpis } from "@/interfaces/IKpis";

export class ReportService extends BaseService {
  constructor() {
    super("reports");
  }

  async getManagedFundraisers(): Promise<[any]> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.get(`/managedfundraisers`);

      console.log(response.data);

      return response.data;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async getSubmittedPledges(dateFrom: Date, dateTo: Date, fundraiserId: number | null = null): Promise<[any]> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      let url = `/dashboard?dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`;
      if (fundraiserId) url += `&supplierId=${fundraiserId}`;

      const response = await axiosInstance.get(url);

      return response.data;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async getEdcActivityReport(dateFrom: Date, dateTo: Date, fundraiserId: number | null = null): Promise<[any]> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      let url = `/activityreport?dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`;
      if (fundraiserId) url += `&supplierId=${fundraiserId}`;

      const response = await axiosInstance.get(url);

      return response.data;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async getKpis(): Promise<IKpis | null> {
    try {
      const axios = await this.getAxiosInstance();

      const response = await axios.get<IKpis | null>("/kpis");

      return !response.data ? null : response.data;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }
}
