
import { ToastHelper } from "@/helpers/toastHelper";
import { ComplianceService } from "@/services/complianceService";
import { LogService } from "@/services/logService";
import Vue from "vue";
import SignatureComponent from "@/components/SignatureComponent.vue";

export default Vue.extend({
  name: "ComplianceAdminQuestions",
  components: { SignatureComponent },
  props: {
    clientId: {
      type: Number,
    },
  },
  computed: {
    online(): boolean {
      return this.$store.state.onlineIndicator;
    },
    fundraiserId(): number {
      return this.$store.getters.currentUserId;
    },
  },
  watch: {},
  data() {
    return {
      loadingQuestions: false,
      questions: [] as Array<any>,
      agreedToTerms: false,
      signature: "",
      signatureError: null as string | null,
    };
  },
  mounted() {
    this.loadQuestions();
  },
  methods: {
    loadQuestions() {
      this.loadingQuestions = true;
      new ComplianceService()
        .getAdminQuestionsWithAnswers()
        .then((response) => {
          this.questions = response;
        })
        .finally(() => {
          this.loadingQuestions = false;
        });
    },
    clearSignatureError() {
      this.signatureError = null;
    },
    saveAndProceed() {
      for (const item of this.questions) {
        if (!item.yesNoAnswer || !item.yesNoAnswer) {
          ToastHelper.error("Please check all the boxes before proceeding");
          return;
        }
      }

      if (!this.agreedToTerms) {
        ToastHelper.error("You need to agree to the terms before proceeding");
        return;
      }

      if (!this.signature || !this.signature.trim()) {
        ToastHelper.error("Please sign before proceeding");
        return;
      }

      new ComplianceService()
        .saveAdminAnswers(this.questions, this.clientId, this.agreedToTerms, this.signature)
        .then((response) => {
          console.log(response);
          ToastHelper.success("Admin answers saved successfully");
          this.$emit("moveNext", true);
        })
        .catch(() => {
          ToastHelper.error("Error saving admin answers. Please contact IT support");
        });
    },
  },
});
