import { Log } from "@/models/Log";
import { GeolocationHelper } from "@/helpers/geolocationHelper";
import store from "@/store";
import { Database } from "@/database";
import { BasePledge } from "@/models/BasePledge";
import { BaseService } from "./baseService";
import { BaseCallback } from "@/models/Callback/BaseCallback";
import { PledgeLog } from "@/models/PledgeLog";

export class LogService extends BaseService {
  geoHelper: GeolocationHelper;
  database: Database;

  private constructor() {
    super("/log");
    this.geoHelper = GeolocationHelper.getInstance();
    this.database = Database.getInstance();
  }

  // singleton
  private static instance: LogService;
  public static getInstance(): LogService {
    if (!this.instance) this.instance = new LogService();
    return this.instance;
  }

  async getLogsToSync(): Promise<Log[]> {
    return await this.database.logs.where({ edcLogId: 0 }).toArray();
  }

  async syncLogs(): Promise<number> {
    let numLogsSynced = 0;

    try {
      const logsFound = await this.getLogsToSync();

      const axiosInstance = await this.getAxiosInstance();

      // submit 20 at a time
      let logsToSync = logsFound.splice(0, 20);

      while (logsToSync.length > 0) {
        const response = await axiosInstance.post("", logsToSync);

        // verify edc log ids
        const edcLogsIds = response.data as { logGuid: string; edcLogId: number }[];

        for (let i = 0; i < edcLogsIds.length; i++) {
          if (isNaN(edcLogsIds[i].edcLogId) || edcLogsIds[i].edcLogId <= 0) {
            console.log("Edc log id not recognized");
            continue;
          }

          await this.database.logs.update(edcLogsIds[i].logGuid, { edcLogId: edcLogsIds[i].edcLogId });

          numLogsSynced++;
        }

        logsToSync = logsFound.splice(0, 20);
      }
    } catch (error) {
      console.error("Error submitting logs to the API:", error);
    } finally {
      LogService.debug("Finished sending logs to the API");
      await this.removeSubmittedLogs();
    }

    return numLogsSynced;
  }

  async removeSubmittedLogs(): Promise<void> {
    LogService.debug("removing submitted logs from device");

    const submittedLogs = await this.database.logs.where("edcLogId").notEqual(0).toArray();

    for (let i = 0; i < submittedLogs.length; i++) {
      const log = submittedLogs[i];

      LogService.debug(`log to be deleted, edcLogId:, ${log.edcLogId}, logGuid: ${log.logGuid}`);

      await this.database.logs.delete(log.logGuid);
    }
  }

  static async debug(data: string | Record<string, unknown>): Promise<Log> {
    const instance = this.getInstance();
    return instance.debug(data);
  }

  static async info(data: string | Record<string, unknown>): Promise<Log> {
    const instance = this.getInstance();
    return instance.info(data);
  }

  static async warn(data: string | Record<string, unknown>): Promise<Log> {
    const instance = this.getInstance();
    return instance.warn(data);
  }

  static async error(data: string | Record<string, unknown>): Promise<Log> {
    const instance = this.getInstance();
    return instance.error(data);
  }

  static async pledgeLog(message: string, pledge: BasePledge): Promise<Log> {
    const data = {
      message: message,
    };

    const instance = this.getInstance();

    const log = await instance.info(data, pledge.pledgeGuid);

    // insert a log into the pledge
    const pledgeLog = new PledgeLog("info", log.latitude, log.longitude);
    pledgeLog.data = data;
    pledge.pledgeLogs?.push(pledgeLog);

    return log;
  }

  static async pledgeChangesLog(message: string, oldPledge: BasePledge | null, newPledge: BasePledge): Promise<Log> {
    const changes: Record<string, unknown> = {};

    let k: keyof BasePledge;
    for (k in newPledge) {
      if (k == "pledgeLogs") continue;

      if (oldPledge != null) {
        const oldValue = oldPledge[k];
        const newValue = newPledge[k];

        if (k == "pledgeUdfs") {
          console.log("udf logs");
          console.log(newValue);
        } else if (oldValue != newValue) {
          changes[k] = newValue;
        }
      } else {
        const newValue = newPledge[k];

        if (newValue != null) {
          if (k == "pledgeUdfs") {
            console.log("udf logs");
            console.log(newValue);
          } else if (newValue != "" && newValue != 0) {
            changes[k] = newValue;
          }
        }
      }
    }

    const data = {
      message: message,
      pledgeChanges: changes,
    };

    const instance = this.getInstance();

    const log = await instance.info(data, newPledge.pledgeGuid);

    // insert a log into the pledge
    const pledgeLog = new PledgeLog("info", log.latitude, log.longitude);
    pledgeLog.data = data;
    newPledge.pledgeLogs?.push(pledgeLog);

    return log;
  }

  static async pledgeLogError(message: string, pledge: BasePledge): Promise<Log> {
    const pledgeCopy = Object.assign({}, pledge);
    delete pledgeCopy.pledgeLogs;

    const data = {
      message: message,
      pledgeSnapshot: pledgeCopy,
    };

    const instance = this.getInstance();

    const log = await instance.error(data, pledge.pledgeGuid);

    pledge.pledgeLogs?.push(log);

    return log;
  }

  static async callbackLog(message: string, callback: BaseCallback): Promise<Log> {
    const data = {
      message: message,
    };

    const instance = this.getInstance();
    const log = await instance.info(data);
    callback.callbackLogs?.push(log);

    return log;
  }

  //#region private methods

  private browserName() {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(" ");
  }

  private deviceData() {
    return {
      deviceGuid: store.getters.currentDeviceId,
      language: navigator.language,
      platform: navigator.platform,
      agent: navigator.userAgent,
      browser: this.browserName(),
    };
  }

  private async createLog(type: "debug" | "info" | "warning" | "error"): Promise<Log> {
    // get geolocation
    let geolocation: GeolocationPosition | null = null;
    try {
      geolocation = await this.geoHelper.getGeolocation();
    } catch {
      if (process.env.VUE_APP_LOG_SHOW && process.env.VUE_APP_LOG_SHOW == "1")
        console.warn("Location error not available");
    }

    // create log
    const log = new Log(type, store.getters.currentUserId, geolocation, this.deviceData());
    return log;
  }

  private async debug(data: string | Record<string, unknown>, pledgeGuid: string | null = null): Promise<Log> {
    if (typeof data == "string") {
      data = { message: data };
    }

    const log = await this.createLog("debug");
    log.data = data;
    log.pledgeGuid = pledgeGuid;

    // this.database.logs.add(log, log.logGuid); // don't save debug logs

    if (process.env.VUE_APP_LOG_SHOW && process.env.VUE_APP_LOG_SHOW == "1") console.debug(log.data);

    return log;
  }

  private async info(data: string | Record<string, unknown>, pledgeGuid: string | null = null): Promise<Log> {
    if (typeof data == "string") {
      data = { message: data };
    }

    const log = await this.createLog("info");
    log.data = data;
    log.pledgeGuid = pledgeGuid;

    this.database.logs.add(log, log.logGuid);

    if (process.env.VUE_APP_LOG_SHOW && process.env.VUE_APP_LOG_SHOW == "1") console.info(log.data);

    return log;
  }

  private async warn(data: string | Record<string, unknown>, pledgeGuid: string | null = null): Promise<Log> {
    if (typeof data == "string") {
      data = { message: data };
    }

    const log = await this.createLog("warning");
    log.data = data;
    log.pledgeGuid = pledgeGuid;

    this.database.logs.add(log, log.logGuid);

    if (process.env.VUE_APP_LOG_SHOW && process.env.VUE_APP_LOG_SHOW == "1") console.warn(log.data);

    return log;
  }

  private async error(data: string | Record<string, unknown>, pledgeGuid: string | null = null): Promise<Log> {
    if (typeof data == "string") {
      data = { message: data };
    }

    const log = await this.createLog("error");
    log.data = data;
    log.pledgeGuid = pledgeGuid;

    this.database.logs.add(log, log.logGuid);

    if (process.env.VUE_APP_LOG_SHOW && process.env.VUE_APP_LOG_SHOW == "1") console.error(log.data);

    return log;
  }

  //#endregion
}
