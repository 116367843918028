import { Database } from "@/database";
import { BaseCallback } from "@/models/Callback/BaseCallback";
import { BaseService } from "./baseService";
import store from "@/store";
import { LogService } from "@/services/logService";
import { AustraliaCallback } from "@/models/Callback/AustraliaCallback";
import { NewZealandCallback } from "@/models/Callback/NewZealandCallback";
import { AustraliaPledge } from "@/models/AustraliaPledge";
import { NewZealandPledge } from "@/models/NewZealandPledge";
import { ToastHelper } from "@/helpers/toastHelper";

export class CallbackService extends BaseService {
  database: Database;

  constructor() {
    super("callback");

    this.database = Database.getInstance();
  }

  async syncCallback(callbackGuid: string): Promise<number> {
    try {
      LogService.info(`About to sync callback ${callbackGuid} using the API`);

      const queryResult = await this.database.callbacks.where({ callbackGuid: callbackGuid, callbackId: 0 }).toArray();

      if (queryResult == null || queryResult.length == 0) {
        LogService.error(`No callback found inthe IndexedDB with GUID: ${callbackGuid} and no Callback ID`);
        throw Error(`No callback found with GUID: ${callbackGuid} and no Callback ID`);
      }

      if (queryResult.length > 1) {
        LogService.error(`More than one callback found with GUID: ${callbackGuid} and no Callback ID`);
        throw Error(`More than one callback found with GUID: ${callbackGuid} and no Callback ID`);
      }

      const callback = queryResult[0];

      await LogService.callbackLog("Callback found on IndexedDB, about to upload it via API", callback);

      // submit callback
      const axiosInstance = await this.getAxiosInstance();
      const response = await axiosInstance.post("", callback);

      // verify callback id
      const callbackId = Number(response.data);
      if (isNaN(callbackId) || callbackId <= 0) {
        await LogService.error({ message: `Callback ${callbackGuid} submitted. Response Callback ID not recognized` });
        throw Error("Callback ID not recognized");
      }

      await LogService.info({ message: `Callback ${callbackGuid} submitted. Response Callback ID: ${callbackId}` });

      // update the callback in dexie with the callback id
      await this.database.callbacks.update(callback.callbackGuid, { callbackId: callbackId });

      await LogService.callbackLog("Callback synced successfully!", callback);

      LogService.info(`Callback submitted. CallbackGuid: ${callbackGuid}. CallbackId: ${callbackId}`);

      ToastHelper.success(`Callback synced. CallbackId: ${callbackId}`);

      return callbackId;
    } catch (error: any) {
      let errorMessage = "";

      // error handling
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // update the callback in dexie if its a duplicate
        if (error.response.data.error == "Duplicated callback found") {
          const callbackId = Number(error.response.data.callbackId);
          if (isNaN(callbackId) || callbackId <= 0) {
            throw Error("Callback ID not recognized");
          } else {
            await this.database.callbacks.update(callbackGuid, { callbackId: error.response.data.callbackId });
            errorMessage = `Duplicated callback found. Callback ID: ${callbackId}. Deleting the duplicated callback`;
          }
        } else {
          // any other outcome
          errorMessage = error.response.data;
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        errorMessage = "No response, please try again later";
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        errorMessage = error.message;
      }

      LogService.error(`Error while syncing callback. Message: ${errorMessage}`);

      ToastHelper.error(`${errorMessage}`);

      throw Error(errorMessage);
    } finally {
      await this.removeCreatedCallbacksFromIndexedDb();
    }
  }

  async syncCallbacks(): Promise<string> {
    store.commit("setCallbacksSyncing", true); // started syncing

    LogService.info("Syncing all callbacks...");

    const pendingCallbacks = await this.database.callbacks.where("callbackId").equals(0).toArray();

    LogService.info(`Number of pending callbacks to be synced: ${pendingCallbacks.length}`);

    let totalCallbacksSynced = 0;

    for (let i = 0; i < pendingCallbacks.length; i++) {
      const callback = pendingCallbacks[i];

      let callbackId = 0;
      try {
        callbackId = await this.syncCallback(callback.callbackGuid);
      } catch (error) {
        console.log(error);
      }

      if (callbackId > 0) {
        totalCallbacksSynced++;
      }
    }

    store.commit("setCallbacksSyncing", false); // finished syncing

    LogService.info(`${totalCallbacksSynced} of ${pendingCallbacks.length} callback(s) synced`);

    return `${totalCallbacksSynced} of ${pendingCallbacks.length} callback(s) synced`;
  }

  async storeCallback(callback: BaseCallback): Promise<{ error: string }> {
    try {
      LogService.info(`About to store callback ${callback.callbackGuid} in the IndexedDB`);

      await this.database.callbacks.add(callback, callback.callbackGuid);

      LogService.info(`Sucessfully stored callback ${callback.callbackGuid} in the IndexedDB`);

      ToastHelper.success("Callback stored in the device");

      return { error: "" };
    } catch (error: any) {
      if (error.name == "ConstraintError" && error.inner.message == "Key already exists in the object store.") {
        LogService.error(`Callback ${callback.callbackGuid} already exists in the IndexedDB`);
        ToastHelper.error("Callback already stored in the device.");
        throw new Error("Callback already stored in the device.");
      } else {
        LogService.error(`Error storing callback ${callback.callbackGuid} in the IndexedDB`);
        ToastHelper.error("Error storing callback in the device, contact IT support");
        throw new Error(error);
      }
    }
  }

  async removeCreatedCallbacksFromIndexedDb(): Promise<void> {
    LogService.info("Removing created callbacks from IndexedDB");

    const createdCallbacks = await this.database.callbacks.where("callbackId").notEqual(0).toArray();

    LogService.info(
      `Number of callbacks already synced that will be removed from the device: ${createdCallbacks.length}`
    );

    for (let i = 0; i < createdCallbacks.length; i++) {
      const callback = createdCallbacks[i];

      LogService.info(
        `Deleting callback, Callback ID: ${callback.callbackId}, Callback Guid: ${callback.callbackGuid}`
      );

      await this.database.callbacks.delete(callback.callbackGuid);

      LogService.info(`Callback ID: ${callback.callbackId} removed from the device`);
    }
  }

  async getPendingCallbacks(fundraiserId: number): Promise<BaseCallback[]> {
    return await this.database.callbacks.where({ fundraiserId: fundraiserId, callbackId: 0 }).toArray();
  }

  async deleteCallback(callbackId: number): Promise<string> {
    try {
      await LogService.info(`Callback ${callbackId} about to be deleted from the database via API`);

      // delete callback
      const axiosInstance = await this.getAxiosInstance();
      const response = await axiosInstance.delete(`?callbackId=${callbackId}`);

      await LogService.info(`Callback ${callbackId} delete from database. Reponse: ${response.data}`);

      return `Callback ${callbackId} deleted`;
    } catch (error: any) {
      let errorMessage = "";

      // error handling
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        errorMessage = "No response, please try again later";
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        errorMessage = error.message;
      }

      await LogService.error(`Error deleting callback ${callbackId} from database. Error: ${errorMessage}`);

      throw Error(`Error deleting callback ${callbackId} from database. Error: ${errorMessage}`);
    }
  }

  async getCallback(callbackId: number): Promise<BaseCallback> {
    try {
      LogService.info(`Getting callback ${callbackId} from the database`);

      const axiosInstance = await this.getAxiosInstance();
      const response = await axiosInstance.get<BaseCallback>(`?callbackId=${callbackId}`);

      let callback = response.data;

      if (callback && callback.country == "Australia") {
        callback = new AustraliaCallback(new AustraliaPledge(0, 0, 0, 0)).assignJson(callback);
      } else if (callback && callback.country == "NewZealand") {
        callback = new NewZealandCallback(new NewZealandPledge(0, 0, 0, 0)).assignJson(callback);
      } else {
        throw new Error("Callback not found or country not recognized");
      }

      LogService.info(`Callback ${callbackId} retrivied from the database`);

      return callback;
    } catch (error: any) {
      let errorMessage = "";

      // error handling
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        errorMessage = "No response, please try again later";
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        errorMessage = error.message;
      }

      LogService.error(`Error getting callback ${callbackId} from database. Error: ${errorMessage}`);

      throw Error(`Error getting callback ${callbackId} from database. Error: ${errorMessage}`);
    }
  }
}
