import { ILocation } from "@/interfaces/ILocation";
import { BaseService } from "./baseService";
import { LogService } from "./logService";

export class LocationsService extends BaseService {
  constructor() {
    super("locations");
  }

  async getAvailableLocations(): Promise<ILocation[]> {
    try {
      LogService.info("About to request bookings from the API");

      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.get<ILocation[]>("");

      LogService.info(`Received ${response.data.length} bookings from the API.`);

      // console.log(response.data);

      // const db = Database.getInstance();

      // response.data.forEach((location) => {
      //   db.locations.add(location);
      // });

      return response.data;
    } catch (error: any) {
      LogService.error(`Error retrieving bookings from the API`);
      throw { data: error.response.data, status: error.response.status };
    }
  }
}
