type Success<T> = {
  success: true;
  data: T;
};

type Failure = {
  success: false;
  error: string;
};

export type Result<T> = Success<T> | Failure;

export class Results {
  static success<T>(data: T): Success<T> {
    return {
      success: true,
      data: data,
    };
  }

  static failure(error: string): Failure {
    return {
      success: false,
      error,
    };
  }
}
