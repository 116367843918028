import { AxiosError } from "axios";

import { Result, Results } from "@/helpers/resultHelper";
import { AustraliaPledge } from "@/models/AustraliaPledge";
import { loadStripe, Stripe } from "@stripe/stripe-js";

import { BaseService } from "./baseService";
import { LogService } from "./logService";

type ClientData = {
  secret: string;
  key: string;
};

type StripeClientData = {
  stripe: Stripe;
} & ClientData;

export class StripeService extends BaseService {
  private static instance: StripeService | null = null;

  private pledgeGuid = "";
  private clientData: ClientData | null = null;
  private stripe: Stripe | null = null;

  private constructor() {
    super("Stripe");
  }

  public static async tokenise(pledge: AustraliaPledge): Promise<Result<string>> {
    const instance = this.getInstance(pledge);
    return await instance.tokenise(pledge);
  }

  private static getInstance(pledge: AustraliaPledge): StripeService {
    if (this.instance === null || pledge.pledgeGuid !== this.instance.pledgeGuid) {
      return new StripeService();
    }

    return this.instance;
  }

  private async initialise(pledge: AustraliaPledge): Promise<Result<StripeClientData>> {
    const clientData = await this.getClientData(pledge);
    if (!clientData.success) return clientData;

    const stripe = await this.loadStripe(clientData.data);
    if (!stripe.success) return stripe;

    return Results.success({
      key: clientData.data.key,
      secret: clientData.data.secret,
      stripe: stripe.data,
    });
  }

  private async loadStripe(clientData: ClientData): Promise<Result<Stripe>> {
    try {
      if (this.stripe) return Results.success(this.stripe);

      this.stripe = await loadStripe(clientData.key);
      return this.stripe ? Results.success(this.stripe) : Results.failure("Could not initialise Stripe");
    } catch (err: unknown) {
      const error = err as AxiosError<never>;
      LogService.error(`Could not initialise Stripe with message: ${error}`);
      return Results.failure("Could not initialise Stripe");
    }
  }

  private async getClientData(pledge: AustraliaPledge): Promise<Result<ClientData>> {
    try {
      const axios = await this.getAxiosInstance();
      const response = await axios.get<ClientData>("/get-client-data", {
        params: {
          pledgeGuid: pledge.pledgeGuid,
          clientId: pledge.clientId,
        },
      });

      this.clientData = response.data;

      return Results.success(this.clientData);
    } catch (err: unknown) {
      const error = err as AxiosError<{ detail: string }>;
      LogService.pledgeLogError(`Error while trying to get Stripe client secret with message: ${error}`, pledge);
      return Results.failure(error.response?.data?.detail ?? "Unknown error");
    }
  }

  private async tokenise(pledge: AustraliaPledge): Promise<Result<string>> {
    try {
      const init = await this.initialise(pledge);
      if (!init.success) return init;

      const { secret, stripe } = init.data;

      const setupResult = await stripe.confirmAuBecsDebitSetup(secret, {
        payment_method: {
          au_becs_debit: {
            account_number: pledge.accountNumber,
            bsb_number: pledge.bsb,
          },
          billing_details: {
            name: pledge.accountName,
            phone: pledge.mobilePhone,
            email: pledge.email,
          },
        },
      });

      if (setupResult.error) {
        return Results.failure(`Stripe error: (${setupResult.error.code}) ${setupResult.error.message}`);
      }

      pledge.stripeToken = setupResult.setupIntent.id;
      return Results.success(setupResult.setupIntent.id);
    } catch (error: unknown) {
      LogService.pledgeLogError(`Error while trying to tokenize Stripe BECS DD with message: ${error}`, pledge);
      return Results.failure("Error while sending payment information to Stripe");
    }
  }
}
