
import { ComplianceService } from "@/services/complianceService";
import Vue from "vue";

export default Vue.extend({
  name: "ClientComplianceDocuments",
  components: {},
  props: {},
  computed: {
    online(): boolean {
      return this.$store.state.onlineIndicator;
    },
    fundraiserId(): number {
      return this.$store.getters.currentUserId;
    },
  },
  watch: {},
  data() {
    return {
      loadingClients: false,
      clientsAvailable: [] as Array<any>,
      selectedClient: null as any,
      clientDocuments: [] as Array<any>,
      loadingDocuments: false,
      downloadingDocument: false,
    };
  },
  mounted() {
    this.loadAvailableClients();
  },
  methods: {
    loadAvailableClients() {
      this.loadingClients = true;
      new ComplianceService()
        .getFundraiserClientsMissingCompliance()
        .then((response) => {
          this.clientsAvailable = response;
        })
        .finally(() => {
          this.loadingClients = false;
        });
    },
    onClientChanged() {
      this.loadingDocuments = true;
      new ComplianceService()
        .getClientDocuments((this.selectedClient as any).clientId)
        .then((response) => {
          this.clientDocuments = response;
          this.$emit("clientSelected", (this.selectedClient as any).clientId);
        })
        .finally(() => {
          this.loadingDocuments = false;
        });
    },
    downloadFile(documentId: number, documentName: string) {
      this.downloadingDocument = true;

      new ComplianceService()
        .getClientDocument(documentId, documentName)
        .then((response) => {
          console.log("file downloaded");
        })
        .finally(() => {
          this.downloadingDocument = false;
        });
    },
  },
});
