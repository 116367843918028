
import { AustraliaPledge } from "@/models/AustraliaPledge";
import Vue from "vue";

export default Vue.extend({
  name: "AddressAustraliaComponent",
  props: {
    pledge: {
      type: Object as () => AustraliaPledge,
    },
    validationErrors: {
      type: Object as () => Record<string, { message: string }>,
    },
  },
  computed: {
    online(): boolean {
      return this.$store.state.onlineIndicator;
    },
  },
  watch: {
    online: function () {
      console.log(`Detected online change`);
      if (this.online) {
        this.loadGoogleAutocomplete();
      }
    },
  },
  data() {
    return {
      states: ["QLD", "NSW", "VIC", "TAS", "SA", "NT", "WA", "ACT"],
      // eslint-disable-next-line no-undef
      autocomplete: {} as google.maps.places.Autocomplete,
    };
  },
  mounted() {
    this.loadGoogleAutocomplete();
  },
  methods: {
    clearError(field: string) {
      if (this.validationErrors && this.validationErrors[field]) {
        delete this.validationErrors[field];
      }
    },
    poBoxOnlyNumbers(e: Event) {
      (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value?.replace(/[^0-9]+/g, "");
      this.pledge.poBox = (e.target as HTMLInputElement).value;
    },
    loadGoogleAutocomplete() {
      if (!this.pledge.isPoBox) {
        let loadAttempts = 0;
        const interval = setInterval(() => {
          loadAttempts++;

          const input = document.getElementById("googleAutocompleteId") as HTMLInputElement;

          // eslint-disable-next-line no-undef
          if (!google.maps.places || !(input instanceof HTMLInputElement)) {
            if (loadAttempts >= 10) {
              console.log("google places cannot load, stopping attempts");
              clearInterval(interval);
            }

            console.log("no google yet");
            return;
          }

          clearInterval(interval);

          console.log("loaded google, loading autocomplete");

          const options = {
            //bounds: defaultBounds,
            componentRestrictions: { country: "au" },
            fields: ["address_components", "formatted_address"],
            //origin: center,
            //strictBounds: false,
            types: ["address"],
          };

          // eslint-disable-next-line no-undef
          this.autocomplete = new google.maps.places.Autocomplete(input, options);
          this.autocomplete.addListener("place_changed", this.placeSelected);
        }, 200);
      }
    },
    placeSelected() {
      console.log("place selected");

      this.pledge.clearAddressDetails();

      const place = this.autocomplete.getPlace();

      console.log(place);

      this.pledge.suggestedAddress = place.formatted_address || "";

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      // eslint-disable-next-line no-undef
      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const componentType = component.types[0];

        switch (componentType) {
          case "subpremise": {
            this.pledge.unit = component.long_name;
            break;
          }
          case "street_number": {
            this.pledge.streetNumber = component.long_name;
            break;
          }

          case "route": {
            this.pledge.street = component.long_name;
            break;
          }

          case "locality": {
            this.pledge.suburb = component.long_name;
            break;
          }

          case "administrative_area_level_1": {
            this.pledge.state = component.short_name;
            break;
          }

          case "postal_code": {
            this.pledge.postcode = component.long_name;
            break;
          }
        }
      }
    },
    poChecked() {
      if (!this.pledge.isPoBox) {
        this.loadGoogleAutocomplete();
      }
    },
  },
});
