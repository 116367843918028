import { LogService } from "@/services/logService";
import ccAmericanExpress from "../assets/ccsymbols/americanexpress.svg";
import ccDinersClub from "../assets/ccsymbols/diners-club.svg";
import ccDiners from "../assets/ccsymbols/diners.svg";
import ccMastercard from "../assets/ccsymbols/mastercard.svg";
import ccVisa from "../assets/ccsymbols/visa.svg";
import ausReg from "../assets/reg_img_aus.png";
import nzReg from "../assets/reg_img_nz.jpg";
import cornucopiaLogo from "../assets/cornucopia-logo.png";

export class ImagePreloader {
  private static preloadImg(src: string) {
    const img = new Image();
    img.onload = (event) => {
      LogService.debug(`Img preloaded, src: ${(event.target as HTMLImageElement).src}`);
    };
    img.src = src;
  }

  static preloadBackground(clientId: number) {
    this.preloadImg(`https://base.cornucopia.com.au/assets/clients/${clientId}/edc/background_picture.jpg`);
  }

  static preloadSmallLogo(clientId: number) {
    this.preloadImg(`https://base.cornucopia.com.au/assets/clients/${clientId}/edc/small_logo.png`);
  }

  static preloadCcFlagsIcons() {
    this.preloadImg(ccAmericanExpress);
    this.preloadImg(ccDinersClub);
    this.preloadImg(ccDiners);
    this.preloadImg(ccMastercard);
    this.preloadImg(ccVisa);
  }

  static preloadAusRegulatory() {
    this.preloadImg(ausReg);
  }

  static preloadNzRegulatory() {
    this.preloadImg(nzReg);
  }

  static getCornucopiaLogo() {
    return cornucopiaLogo;
  }
}
