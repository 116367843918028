
import Vue from "vue";
import { Pie } from "vue-chartjs/legacy";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, ChartDataLabels);

class PieChartData {
  labels: string[] = [];
  data: number[] = [];
  backgroundColor: string[] = [];
}

export default Vue.extend({
  name: "PledgesSubmittedPieChart",
  components: { Pie },
  props: {
    chartId: {
      type: String,
      default: "pledges-submitted-pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // plugins: {
    //   type: Array,
    //   default: () => [],
    // },
    pieChartData: {
      type: PieChartData,
      default: () => {
        return new PieChartData();
      },
    },
    pledgesToReport: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    pledgesToReport: function () {
      this.getPledgesData();
    },
  },
  data() {
    return {
      chartData: {
        labels: [""],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16", "#111111"],
            data: [40, 20, 80, 10],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      plugins: [
        {
          datalabels: {
            color: "#36A2EB",
          },
        },
      ],
    };
  },
  mounted() {
    this.getPledgesData();
  },
  methods: {
    getPledgesData() {
      console.log("pledges to report on:", this.pledgesToReport);

      const group = (this.pledgesToReport as Array<any>).reduce((result, curr) => {
        let status = curr.status;

        if (curr.status == "Hold" && curr.processPoint != "Research") {
          status = "Active";
        }

        (result[status] = result[status] || []).push(curr);
        return result;
      }, []);

      const labels = [] as string[];
      const data = [] as number[];

      console.log(group);

      for (var key in group) {
        labels.push(key);
        data.push(group[key].length);
      }

      console.log(labels);
      console.log(data);

      this.chartData = {
        labels: labels,
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16", "#111111"],
            data: data,
          },
        ],
      };
    },
  },
});
