
import { NzPostService } from "@/services/nzPostService";
import { NewZealandPledge } from "@/models/NewZealandPledge";
import Vue from "vue";
import jwtDecode from "jwt-decode";
import _ from "lodash";
import { LogService } from "@/services/logService";
import { ToastHelper } from "@/helpers/toastHelper";

export default Vue.extend({
  name: "AddressNewZealandComponent",
  props: {
    pledge: {
      type: Object as () => NewZealandPledge,
    },
    validationErrors: {
      type: Object as () => Record<string, { message: string }>,
    },
  },
  computed: {
    compOnline(): boolean {
      return this.$store.state.onlineIndicator;
    },
  },
  watch: {
    compOnline: function () {
      // only go offline, never online
      if (this.online == true) {
        this.online = this.compOnline;
      }
    },
  },
  data() {
    return {
      online: false,
      nzPostToken: "",
      suggestedAddresses: [] as string[] | null,
      isFetching: false,
      timeout: 0 as number,
      // poChecked: false as boolean,
    };
  },
  mounted() {
    this.getNzPostToken();

    this.online = this.$store.state.onlineIndicator;
  },
  methods: {
    clearError(field: string) {
      if (this.validationErrors && this.validationErrors[field]) {
        delete this.validationErrors[field];
      }
    },
    tooglePoBox() {
      console.log("PO BOX CHANGE --------------", this.pledge.isPoBox);
      const wasPoBox = this.pledge.isPoBox;
      this.pledge.clearAddressDetails();
      this.pledge.isPoBox = !wasPoBox;
      console.log("PO BOX CHANGED --------------", this.pledge.isPoBox);
    },
    async getNzPostToken() {
      const token = window.localStorage.getItem("nzPostToken");

      if (this.isNzTokenExpired(token)) {
        window.localStorage.removeItem("nzPostToken");
        const nzPostToken = await new NzPostService().getNzPostToken();
        window.localStorage.setItem("nzPostToken", nzPostToken);
      }

      this.nzPostToken = window.localStorage.getItem("nzPostToken") || "";
    },
    isNzTokenExpired(nzPostToken: string | null) {
      if (!nzPostToken) return true;

      const decoded: any = jwtDecode(nzPostToken);

      // expire every day
      // if today's day is the same as the token expiry day, it means that the tokne was created yesterday and should expire
      const currentDate = new Date();
      const expiry = new Date(decoded.exp * 1000);
      console.log("current day:", currentDate.getDate());
      console.log("expiry day:", expiry.getDate());
      if (currentDate >= expiry) {
        console.log("token expired");
        return true;
      }

      return false;
    },
    async getAsyncData(term: string) {
      console.log("term is:", term);

      if (!term.length) {
        this.suggestedAddresses = [];
        return;
      }

      try {
        this.isFetching = true;

        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
          var result = await new NzPostService().getAddressSuggestions(this.nzPostToken, term);

          this.suggestedAddresses = result;
        }, 300);
      } catch (error) {
        LogService.error("Error getting suggestion from Nz Post");
      } finally {
        this.isFetching = false;
      }
    },
    async addressSelected(selected: any) {
      if (!selected) return;

      const dpid = selected.DPID;

      console.log(`getting address details for dpid ${dpid}`);

      this.pledge.clearAddressDetails();

      this.pledge.suggestedAddress = selected.FullAddress; // NEW SUGGESTED ADDRESS

      var result = await new NzPostService().getAddressDetails(this.nzPostToken, dpid);

      console.log(`address details:`, result);

      if (result.length <= 0) {
        ToastHelper.error("Address details not found in NZ Post");
        return;
      }

      const details = result[0];

      this.pledge.nzPostAddressJson = details; // keeps the entire response

      if (details.Floor) this.pledge.floor = details.Floor.toString();
      if (details.UnitValue) this.pledge.unit = details.UnitValue.toString();
      if (details.StreetNumber) this.pledge.streetNumber = details.StreetNumber.toString();
      if (details.StreetAlpha) this.pledge.streetNumber += details.StreetAlpha.toString(); // append Street Alpha
      if (details.CityTown) this.pledge.townCity = details.CityTown;
      if (details.Postcode) this.pledge.postcode = details.Postcode.toString();
      if (details.DPID) this.pledge.nzPostDpid = details.DPID.toString();

      // suburb or rural area
      this.pledge.suburb = details.Suburb;
      if (details.RuralDelivery && !details.Suburb) {
        this.pledge.suburb = details.Suburb;
      }

      // street name
      this.pledge.street = details.RoadName;
      if (details.RoadTypeName) this.pledge.street += " " + details.RoadTypeName;
      if (details.RoadSuffixName) this.pledge.street += " " + details.RoadSuffixName;

      // set PO Box attributes
      if (details.BoxBagType && details.BoxBagType == "PO Box") {
        this.pledge.isPoBox = true;
        if (this.pledge.suggestedAddress) this.pledge.poBox = this.pledge.suggestedAddress;
      } else {
        this.pledge.isPoBox = false;
      }
    },
  },
});
