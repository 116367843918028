import axios from "axios";
import { BaseService } from "./baseService";
import { LogService } from "./logService";

export class NzPostService extends BaseService {
  constructor() {
    super("nzpost");
  }

  async getNzPostToken(): Promise<string> {
    try {
      LogService.info(`Fundraiser attempting to get nz post token`);

      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.get("token");

      LogService.info(`Attempting to get nz post token response . Response: ${response}`);

      if (response && response.data) return response.data.access_token;

      return "";
    } catch (error: any) {
      LogService.error({
        message: `Error getting nz post token. Error: ${error.response.data}`,
        error: error,
      });

      return "";
    }
  }

  async getAddressSuggestions(token: string, query: string): Promise<string[] | null> {
    try {
      console.log("getting suggestion");

      const response = await axios.get(`https://api.nzpost.co.nz/addresschecker/1.0/suggest?q=${query}&max=15`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      console.log("response is:", response);

      if (response && response.data && response.data.success == true) {
        return response.data.addresses;
      }

      return null;
    } catch (error: any) {
      LogService.error({
        message: `Error getting nz post suggestions.`,
        error: error,
      });

      return null;
    }
  }

  async getAddressDetails(token: string, dpid: string): Promise<any> {
    try {
      console.log("getting suggestion");

      const response = await axios.get(`https://api.nzpost.co.nz/addresschecker/1.0/details?dpid=${dpid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      console.log("response is:", response);

      if (response && response.data && response.data.success == true) {
        return response.data.details;
      }

      return null;
    } catch (error: any) {
      LogService.error({
        message: `Error getting nz post details for dpid ${dpid}.`,
        error: error,
      });

      return null;
    }
  }
}
