import { JSEncrypt } from "jsencrypt";
import CryptoJS from "crypto-js";

export class CryptHelper {
  static encryptRSA(payload: string, rsaPublicKey: string): string {
    const crypt = new JSEncrypt({});

    const key = rsaPublicKey;

    crypt.setPublicKey(key);

    const encryptedPayload = crypt.encrypt(payload);

    if (encryptedPayload == false) throw "Failed to encrypt";

    return encryptedPayload;
  }

  static encryptAES(payload: string): { key: string; iv: string; encryptedPayload: string } {
    const randomPassword = CryptoJS.lib.WordArray.random(128 / 8);
    const salt = CryptoJS.lib.WordArray.random(128 / 8);

    const randomKey = CryptoJS.PBKDF2(randomPassword, salt, { keySize: 256 / 32, iterations: 100 });
    // console.log("rkey:", randomKey.toString(CryptoJS.enc.Base64));

    const randomIv = CryptoJS.lib.WordArray.random(128 / 8);
    // console.log("riv:", randomIv.toString(CryptoJS.enc.Base64));

    const encryptedPayload = CryptoJS.AES.encrypt(payload, randomKey, {
      iv: randomIv,
      keySize: 256 / 8,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    // console.log("key:", encryptedPayload.key.toString(CryptoJS.enc.Base64));
    // console.log("iv:", encryptedPayload.iv.toString(CryptoJS.enc.Base64));
    // console.log("AES encrypted:", encryptedPayload.toString(CryptoJS.format.OpenSSL));

    /* -- to check if encryption works
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, randomKey, {
      iv: randomIv,
      keySize: 256 / 8,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    console.log("AES decrypted:", originalText);
    */

    const randomKeyString = randomKey.toString(CryptoJS.enc.Base64);
    const randomIvString = randomIv.toString(CryptoJS.enc.Base64);
    const encryptedString = encryptedPayload.toString();

    return { key: randomKeyString, iv: randomIvString, encryptedPayload: encryptedString };
  }
}
