
import Vue from "vue";
import PledgesSubmittedComponent from "@/components/Reports/PledgesSubmittedComponent.vue";
import DashboardComponent from "@/components/Reports/DashboardComponent.vue";
import TeamReportComponent from "@/components/Reports/TeamReportComponent.vue";
import { ClientStyleBuilder } from "@/models/ClientStyleBuilder";
import { IClient } from "@/interfaces/IClient";
import ActivityReportComponent from "@/components/Reports/ActivityReportComponent.vue";

export default Vue.extend({
  components: { PledgesSubmittedComponent, DashboardComponent, TeamReportComponent, ActivityReportComponent },
  name: "Reports",
  data() {
    return {
      currentReport: "submittedPledges",
    };
  },
  computed: {
    cssStyle(): ClientStyleBuilder {
      const client = this.$store.getters.selectedClient as IClient;
      return new ClientStyleBuilder(client?.cssDetails);
    },
    fundraiserId(): number {
      return this.$store.getters.currentUserId;
    },
    numPledgesToSync(): number {
      return this.$store.getters.numberOfPledgesToSync;
    },
  },
  watch: {},
  methods: {
    logout() {
      this.$buefy.dialog.confirm({
        title: "Logout from account",
        message: "Are you sure you want to <b>logout</b> from Catalyst?",
        confirmText: "Yes",
        cancelText: "No",
        hasIcon: true,
        icon: "logout",
        onConfirm: () => {
          this.$store.dispatch("logout");
          this.$router.push("/");
        },
      });
    },
    goHome() {
      this.$router.push("/home");
    },
    selectReport(item: string) {
      this.currentReport = item;
    },
  },
});
