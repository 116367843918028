import store from "@/store";
import axios, { AxiosInstance } from "axios";

export abstract class BaseService {
  private baseDomain: string | undefined;
  private baseUrl: string;

  constructor(basePath: string) {
    this.baseDomain = process.env.VUE_APP_EDC_API_BASE_DOMAIN;

    if (basePath) {
      if (basePath.length < 2) throw new Error("Base path not defined");

      // remove the first / if any
      if (basePath[0] === "/") basePath = basePath.substring(1);

      // add the last / if not there
      if (basePath[basePath.length - 1] !== "/") basePath = basePath + "/";
    }

    this.baseUrl = `${this.baseDomain}/${basePath}`;
  }

  protected async getAxiosInstance(): Promise<AxiosInstance> {
    let token = "";

    if (store?.state?.token) token = store.state.token;

    return axios.create({
      baseURL: this.baseUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
