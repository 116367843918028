import { BaseService } from "./baseService";
import { LogService } from "./logService";

export class StatusService extends BaseService {
  constructor() {
    super("");
  }

  async getStatus(): Promise<{ status: string; date: string }> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.get(`status`);

      return response.data;
    } catch (error: any) {
      LogService.debug(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }
}
