
import Vue from "vue";
import ClientComplianceDocuments from "@/components/Compliance/ClientComplianceDocuments.vue";
import ComplianceTextQuestions from "@/components/Compliance/ComplianceTextQuestions.vue";
import ComplianceCheckboxQuestions from "@/components/Compliance/ComplianceCheckboxQuestions.vue";
import ComplianceAdminQuestions from "@/components/Compliance/ComplianceAdminQuestions.vue";
import { ToastHelper } from "@/helpers/toastHelper";

export default Vue.extend({
  components: {
    ClientComplianceDocuments,
    ComplianceTextQuestions,
    ComplianceCheckboxQuestions,
    ComplianceAdminQuestions,
  },
  name: "ComplianceReport",
  data() {
    return {
      step: "" as "client-selection" | "text-question" | "checkbox-question" | "admin-question",
      selectedClientId: 0,
    };
  },
  computed: {
    fundraiserId(): number {
      return this.$store.getters.currentUserId;
    },
    numPledgesToSync(): number {
      return this.$store.getters.numberOfPledgesToSync;
    },
  },
  watch: {},
  mounted() {
    this.step = "client-selection";
  },
  methods: {
    clientSelected(value: number) {
      this.selectedClientId = value;
    },
    backToComplianceReport() {
      this.$router.push("/compliance/report");
    },
    complianceCompleted() {
      ToastHelper.success("Compliance completed");
      this.backToComplianceReport();
    },
  },
});
