
import Vue from "vue";

export default Vue.extend({
  name: "AverageAgeWidget",
  props: {
    pledgesToReport: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    pledgesToReport: function () {
      this.getPledgesData();
    },
  },
  data() {
    return {
      total: "0",
    };
  },
  mounted() {
    this.getPledgesData();
  },
  methods: {
    getPledgesData() {
      this.total = "0";

      if (this.pledgesToReport.length == 0) {
        return;
      }

      let ageSum = 0;

      for (var pledge of this.pledgesToReport) {
        ageSum += (pledge as any).ageAtSignUp;
      }
      console.log("AGE SUM", ageSum);
      console.log("ALL PLEDGES", this.pledgesToReport.length);

      const result = (ageSum || 0) / this.pledgesToReport.length;

      this.total = result.toFixed(2);
    },
  },
});
