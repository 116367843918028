
import Vue from "vue";
import { ReportService } from "@/services/reportService";

export default Vue.extend({
  name: "TeamReportComponent",
  components: {},
  props: {},
  computed: {
    online(): boolean {
      return this.$store.state.onlineIndicator;
    },
    fundraiserId(): number {
      return this.$store.getters.currentUserId;
    },
  },
  watch: {},
  data() {
    return {
      dateFrom: new Date(),
      dateTo: new Date(),
      pledgesToReport: [] as Array<any>,
      teamReport: [] as Array<any>,
      isLoading: false,
    };
  },
  beforeMount() {
    this.dateFrom = this.getPastMonday(new Date());
    this.dateTo = this.getNextSunday(new Date());

    this.loadReport();
  },
  methods: {
    loadReport() {
      this.isLoading = true;

      this.teamReport = [];

      const reportService = new ReportService();

      reportService.getSubmittedPledges(this.dateFrom, this.dateTo).then((response) => {
        this.pledgesToReport = response;

        const fundraisersArray = [];

        for (var pledge of this.pledgesToReport) {
          const fundraiserName = (pledge as any).campaignerName;

          if (!fundraisersArray[fundraiserName]) {
            fundraisersArray[fundraiserName] = {
              supplierId: pledge.supplierId,
              name: fundraiserName,
              submitted: 0,
              active: 0,
              avgAge: 0,
              avgGift: 0,
              units: 0,
            };
          }

          fundraisersArray[fundraiserName].submitted += 1;

          if (pledge.status == "Hold") {
            if (pledge.processPoint != "Research") {
              fundraisersArray[fundraiserName].active += 1;
            }
          } else if (pledge.status == "Active") {
            fundraisersArray[fundraiserName].active += 1;
          }

          fundraisersArray[fundraiserName].avgAge += pledge.ageAtSignUp || 0;
          fundraisersArray[fundraiserName].avgGift +=
            (pledge.annualPledgeAmount || 0) / pledge.frequencyAnnualDenominator;
          fundraisersArray[fundraiserName].units += (pledge.annualPledgeAmount || 0) / 12;
        }

        for (var key in fundraisersArray) {
          fundraisersArray[key].avgAge = fundraisersArray[key].avgAge / fundraisersArray[key].submitted || 0;
          fundraisersArray[key].avgGift = fundraisersArray[key].avgGift / fundraisersArray[key].submitted || 0;

          this.teamReport.push(fundraisersArray[key]);
        }

        console.log(this.teamReport);

        this.isLoading = false;
      });
    },
    onDatesChanges() {
      this.loadReport();
    },
    getPastMonday(d: Date) {
      d = new Date(d);
      var day = d.getDay();
      var diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    getNextSunday(d: Date) {
      d = new Date(d);
      var day = d.getDay();
      var diff = d.getDate() + (7 - (day == 0 ? 7 : day)); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
  },
});
