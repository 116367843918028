import { BaseService } from "./baseService";
import { LogService } from "./logService";

export class CryptoService extends BaseService {
  constructor() {
    super("cryptography");
  }

  async getPublicKey(): Promise<string> {
    try {
      LogService.info(`Fetching public RSA key from the API`);

      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.get<string>("publickey");

      LogService.info(`Successfully Public RSA key from the API`);

      return response.data;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }
}
