
import Vue from "vue";

export default Vue.extend({
  name: "ActiveUnitsWidget",
  props: {
    pledgesToReport: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    pledgesToReport: function () {
      this.getPledgesData();
    },
  },
  data() {
    return {
      total: "0",
    };
  },
  mounted() {
    this.getPledgesData();
  },
  methods: {
    getPledgesData() {
      this.total = "0";

      if (this.pledgesToReport.length == 0) {
        return;
      }

      let unitsSum = 0;

      for (var pledge of this.pledgesToReport) {
        let status = (pledge as any).status;

        if (status == "Hold" && status != "Research") {
          status = "Active";
        }

        if (status == "Active") {
          unitsSum += ((pledge as any).annualPledgeAmount || 0) / 12;
        }
      }

      this.total = unitsSum.toFixed(2);
    },
  },
});
