
import Vue from "vue";
import { Pie } from "vue-chartjs/legacy";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

class PieChartData {
  labels: string[] = [];
  data: number[] = [];
  backgroundColor: string[] = [];
}

export default Vue.extend({
  name: "PaymentTypePieChart",
  components: { Pie },
  props: {
    chartId: {
      type: String,
      default: "payment-type-pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    pieChartData: {
      type: PieChartData,
      default: () => {
        return new PieChartData();
      },
    },
    pledgesToReport: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    pledgesToReport: function () {
      this.getPledgesData();
    },
  },
  data() {
    return {
      chartData: {
        labels: [""],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16", "#111111"],
            data: [40, 20, 80, 10],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.getPledgesData();
  },
  methods: {
    getPledgesData() {
      console.log("pledges to report on:", this.pledgesToReport);

      const group = (this.pledgesToReport as Array<any>).reduce((result, curr) => {
        const key: string = curr.paymentType;

        if (result[key]) {
          result[key] = result[key] + 1;
        } else {
          result[key] = 1;
        }

        return result;
      }, {});

      const labels = [] as string[];
      const data = [] as number[];

      for (var key in group) {
        labels.push(key);
        data.push(group[key]);
      }

      console.log(labels);
      console.log(data);

      this.chartData = {
        labels: labels,
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16", "#111111"],
            data: data,
          },
        ],
      };
    },
  },
});
