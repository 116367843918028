
import Vue from "vue";
import { BasePledge } from "@/models/BasePledge";
import PledgeFlowTemplateComponent from "@/components/PledgeFlowTemplateComponent.vue";
import { PledgeService } from "@/services/pledgeService";
import { ClientStyleBuilder } from "@/models/ClientStyleBuilder";
import { IClient } from "@/interfaces/IClient";
import { LogService } from "@/services/logService";
import { ToastHelper } from "@/helpers/toastHelper";

export default Vue.extend({
  name: "Confirmation",
  components: { PledgeFlowTemplateComponent },
  data() {
    return {
      oldPledge: {} as BasePledge,
      pledge: {} as BasePledge,
      accepted: [],
      validationErrors: {} as Record<string, { message: string }>,
    };
  },
  computed: {
    client(): IClient {
      return this.$store.getters.selectedClient;
    },
    cssStyle(): ClientStyleBuilder {
      const client = this.client;
      return new ClientStyleBuilder(client?.cssDetails);
    },
    username() {
      return this.$store.getters.currentUserName;
    },
    amountCurrencyString() {
      let str_amount = "";

      if (this.pledge.country == "Australia") {
        str_amount = new Intl.NumberFormat("en-AU", { style: "currency", currency: "AUD" }).format(this.pledge.amount);
      } else if (this.pledge.country == "NewZealand") {
        str_amount = new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" }).format(this.pledge.amount);
      } else {
        str_amount = this.pledge.amount?.toString() ?? "";
      }

      return str_amount;
    },
  },
  beforeMount: function () {
    const currentPledge = this.$store.getters.currentPledge;
    if (currentPledge) this.pledge = currentPledge;

    this.oldPledge = Object.assign({}, this.pledge);

    console.log(this.pledge);
  },
  methods: {
    clearError(field: string) {
      console.log("cleaning", field);
      if (this.validationErrors && this.validationErrors[field]) {
        delete this.validationErrors[field];
      }
    },
    async submitButton() {
      try {
        this.$store.dispatch("setCurrentPledge", this.pledge);
      } catch {
        console.error("error");
      }

      await LogService.pledgeLog(`Pledge confirmed, about to submit pledge`, this.pledge);

      const pledgeService = new PledgeService();
      pledgeService
        .storePledge(this.$store.state.rsaPublicKey, this.pledge)
        .then(async (response) => {
          if (response.error) {
            this.$buefy.toast.open({
              type: "is-warning",
              message: response.error,
              duration: 5000,
            });
          }

          ToastHelper.success("Pledge stored in the device");

          await LogService.pledgeChangesLog(
            "Pledge stored in device. Attempt to sync via API",
            this.oldPledge,
            this.pledge
          );

          pledgeService.syncPledges();

          this.$router.push("/pledge/thankyou");
        })
        .catch((error) => {
          console.error(error);

          this.$buefy.toast.open({
            type: "is-danger",
            message: "Error storing the pledge in the device, contact IT support",
            duration: 15000,
          });
        });
    },
    previousButton: function () {
      // redirect to payment details
      this.$router.push("/callback/paymentdetails");
    },
    clearPledge() {
      this.$store.dispatch("clearCurrentPledge", this.pledge);
    },
  },
});
