
import Vue, { VueConstructor } from "vue";
import StatusBarComponent from "@/components/StatusBarComponent.vue";
import updateMixin from "./mixins/update";
import { ImagePreloader } from "./helpers/imagePreloader";
import { BankDetailsService } from "@/services/bankDetailsService";

// export default Vue
export default (Vue as VueConstructor<Vue & InstanceType<typeof updateMixin>>).extend({
  name: "App",
  components: { StatusBarComponent },
  data() {
    return {
      deferredPrompt: null as any,
      showInstallDiv: false,
      installer: null as any,
    };
  },
  mixins: [updateMixin],
  computed: {
    styleObject: function () {
      return {
        backgroundColor: this.$store.state.appBackgroundColor,
        minHeight: "100vh",
        backgroundImage: "url(" + this.$store.state.appBackgroundImageUrl + ")",
      };
    },
  },
  created() {
    let installPrompt: any;

    // listener (stops users from installing the app)
    window.addEventListener("beforeinstallprompt", (e: any) => {
      e.preventDefault();

      console.warn(`'beforeinstallprompt' event was fired.`);

      // installPrompt = e;

      // this.showInstallDiv = true;
    });

    // listener
    window.addEventListener("appinstalled", () => {
      console.log("application was installed");
    });

    // instalation
    this.installer = () => {
      this.showInstallDiv = false;

      installPrompt.prompt();

      installPrompt.userChoice.then((result: any) => {
        console.log(`User response to the install prompt: ${result}`);
      });

      this.showInstallDiv = false;
    };

    // set device Guid if necessary
    this.$store.dispatch("setDeviceGuid");

    // pre load images
    ImagePreloader.preloadCcFlagsIcons();
    ImagePreloader.preloadAusRegulatory();
    ImagePreloader.preloadNzRegulatory();

    // load bank details
    new BankDetailsService().getAustralianBsbs();
  },
});
