import { LogService } from "@/services/logService";
import { StatusService } from "@/services/statusService";
import store from "../store";

export class IsOnlineHelper {
  online = false;

  private _statusService: StatusService;

  private constructor() {
    this._statusService = new StatusService();

    // this.updateOnlineStatus();
    this.assignEvents();
  }

  // singleton
  private static instance: IsOnlineHelper;
  public static getInstance(): IsOnlineHelper {
    if (!this.instance) this.instance = new IsOnlineHelper();
    return this.instance;
  }

  private assignEvents() {
    const EVENTS = ["online", "offline", "load"];
    EVENTS.forEach((event) => window.addEventListener(event, () => this.updateOnlineStatus()));

    // set the online check interval
    const onlineCheckInterval = Number(process.env.VUE_APP_ONLINE_CHECK_INTERVAL);
    setInterval(() => {
      this.updateOnlineStatus();
    }, onlineCheckInterval);
  }

  private async updateOnlineStatus() {
    if (!window.navigator.onLine) {
      this.changeOnlineFlag(false);
      // this.online = false;
      // store.dispatch("setOnlineIndicator", this.online);
    } else {
      try {
        const response = await this._statusService.getStatus();

        if (response.status == "live") {
          this.changeOnlineFlag(true);
          // this.online = true;
        } else {
          this.changeOnlineFlag(false);
          // this.online = false;
        }
      } catch (error) {
        LogService.error({ message: "Error checking online status:", error: error });
        this.changeOnlineFlag(false);
      }
    }
  }

  // Check if the online status is about to change and log it accordingly
  private changeOnlineFlag(newStatus: boolean) {
    // log change
    if (this.online) {
      if (!newStatus) LogService.info("APP entering offline mode");
    } else {
      if (newStatus) LogService.info("APP entering online mode");
    }

    // set online flag
    this.online = newStatus;
    store.dispatch("setOnlineIndicator", this.online);
  }

  /** Function for checking if it is online with Promise (for processes that need to wait to execute)
   */
  public async isOnline(): Promise<boolean> {
    await this.updateOnlineStatus();
    return this.online;
  }
}
