import { ICallbacksReport } from "@/interfaces/Callback/ICallbacksReport";
import { IPledgesReport } from "@/interfaces/IPledgesReport";
import { BaseService } from "./baseService";
import { LogService } from "./logService";

export class FundraiserService extends BaseService {
  constructor() {
    super("fundraiser");
  }

  async getCampaignPartner(): Promise<any> {
    try {
      LogService.info(`Getting current fundraiser campaign partner from the API`);

      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.get("campaignpartner");

      LogService.info(`Successfully got the current fundraiser campaign partner from the API`);

      return response.data;
    } catch (error: any) {
      LogService.error({ message: `Error getting the current fundraiser campaign partner from the API`, error: error });
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async getCampaignPartnerRegions(): Promise<any> {
    try {
      LogService.info(`Getting current fundraiser campaign partner regions from the API`);

      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.get("campaignpartnerregions");

      LogService.info(`Successfully got the current fundraiser campaign partner regions from the API`);

      return response.data;
    } catch (error: any) {
      LogService.error({
        message: `Error getting the current fundraiser campaign partner region from the API`,
        error: error,
      });
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async getPastPledgesClients(): Promise<{ clientId: number; clientName: string }[]> {
    try {
      LogService.info(`Getting current fundraiser past clients for pledges`);

      const axiosInstance = await this.getAxiosInstance();
      const response = await axiosInstance.get("pastpledgesclients");

      LogService.info(`Successfully got current fundraiser past clients for pledges`);

      return response.data;
    } catch (error: any) {
      LogService.error({
        message: `Error getting current fundraiser past clients for pledges`,
        error: error,
      });
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async getPastCallbacksClients(): Promise<{ clientId: number; clientName: string }[]> {
    try {
      LogService.info(`Getting current fundraiser past clients for callbacks`);

      const axiosInstance = await this.getAxiosInstance();
      const response = await axiosInstance.get("pastcallbacksclients");

      LogService.info(`Successfully got current fundraiser past clients for callbacks`);

      return response.data;
    } catch (error: any) {
      LogService.error({
        message: `Error getting current fundraiser past clients for callbacks`,
        error: error,
      });
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async getSubmittedPledgesReport(
    dateFrom: Date,
    dateTo: Date,
    page = 0,
    perPage = 20,
    clientId: number | null = null
  ): Promise<IPledgesReport> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      let url = `/pledgehistory?page=${page}&perPage=${perPage}&dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`;
      if (clientId) url += `&clientId=${clientId}`;

      const response = await axiosInstance.get<IPledgesReport>(url);

      return response.data;
    } catch (error: any) {
      console.log(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async getSyncedCallbacksReport(
    dateFrom: Date,
    dateTo: Date,
    page = 0,
    perPage = 20,
    clientId: number | null = null
  ): Promise<ICallbacksReport> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      let url = `/callbackhistory?page=${page}&perPage=${perPage}&dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`;
      if (clientId) url += `&clientId=${clientId}`;

      const response = await axiosInstance.get<ICallbacksReport>(url);

      return response.data;
    } catch (error: any) {
      console.log(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async getFundraiserProfile(): Promise<any> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.get(`/profile`);

      console.log(response.data);

      return response.data;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async updateFundraiserProfile(mobileNumber: string, email: string): Promise<any> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.put(`/profile?mobileNumber=${mobileNumber}&email=${email}`);

      console.log(response.data);

      return response.data;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }
}
