
import Vue from "vue";

export default Vue.extend({
  name: "FundraisersWidget",
  props: {
    pledgesToReport: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    pledgesToReport: function () {
      this.getPledgesData();
    },
  },
  data() {
    return {
      total: "0",
    };
  },
  mounted() {
    this.getPledgesData();
  },
  methods: {
    getPledgesData() {
      this.total = "0";

      if (this.pledgesToReport.length == 0) {
        return;
      }

      const fundraisersArray = [];
      let fundraisersSum = 0;

      for (var pledge of this.pledgesToReport) {
        const fundraiserName = (pledge as any).campaignerName;

        if (!fundraisersArray[fundraiserName]) {
          fundraisersArray[fundraiserName] = fundraiserName;
          fundraisersSum++;
        }
      }

      this.total = fundraisersSum.toFixed(0);
    },
  },
});
