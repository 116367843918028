import { LogService } from "@/services/logService";

export class GeolocationHelper {
  private constructor() {
    this.handlePermission();
  }

  // singleton
  private static instance: GeolocationHelper;
  public static getInstance(): GeolocationHelper {
    if (!this.instance) this.instance = new GeolocationHelper();
    return this.instance;
  }

  handlePermission(): void {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: "geolocation" }).then(function (result) {
        if (result.state == "granted") {
          console.log("Permission ", result.state);
        } else if (result.state == "prompt") {
          console.log("Permission ", result.state);
          navigator.geolocation.getCurrentPosition(
            (position) => console.log(position),
            (denied) => console.log(denied),
            {
              enableHighAccuracy: false,
              maximumAge: 0,
              timeout: 1500,
            }
          );
        } else if (result.state == "denied") {
          LogService.warn(`Permission ${result.state}`);
        }
        result.onchange = function () {
          LogService.info(`Permission ${result.state}`);
        };
      });
    }
  }

  async getGeolocation(): Promise<GeolocationPosition> {
    return new Promise<GeolocationPosition>((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (denied) => {
          reject(denied);
        },
        {
          enableHighAccuracy: false,
          maximumAge: 0,
          timeout: 2000,
        }
      )
    );
  }
}
