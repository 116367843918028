
import Vue from "vue";
import { ReportService } from "@/services/reportService";

export default Vue.extend({
  name: "ActivityReportComponent",
  components: {},
  props: {},
  computed: {
    online(): boolean {
      return this.$store.state.onlineIndicator;
    },
    fundraiserId(): number {
      return this.$store.getters.currentUserId;
    },
  },
  watch: {},
  data() {
    return {
      dateFrom: new Date(),
      dateTo: new Date(),
      selectedFundraiser: { name: "-- All --", fundraiserId: 0 },
      fundraiserList: [{ name: "-- All --", fundraiserId: 0 }] as { name: string; fundraiserId: number }[],
      activityReport: [] as Array<any>,
      isLoading: false,
    };
  },
  beforeMount() {
    this.dateFrom = this.getPastMonday(new Date());
    this.dateTo = this.getNextSunday(new Date());

    const reportService = new ReportService();

    reportService.getManagedFundraisers().then((response) => {
      this.fundraiserList = this.fundraiserList.concat(
        response
          .map((o) => {
            return {
              name: o.participantName,
              fundraiserId: o.supplierId,
            };
          })
          .sort((a, b) => (a.name || "").localeCompare(b.name))
      );

      this.loadReport();
    });
  },
  methods: {
    onFundraiserChanged() {
      this.loadReport();
    },
    onDatesChanges() {
      this.loadReport();
    },
    loadReport() {
      this.isLoading = true;

      this.activityReport = [];

      const reportService = new ReportService();

      reportService
        .getEdcActivityReport(this.dateFrom, this.dateTo, this.selectedFundraiser.fundraiserId)
        .then((response) => {
          this.activityReport = response;
          // for (var item of this.activityReport) {
          //   const date = new Date(item.dateCreated);
          // }

          this.isLoading = false;
        });
    },
    getPastMonday(d: Date) {
      d = new Date(d);
      var day = d.getDay();
      var diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    getNextSunday(d: Date) {
      d = new Date(d);
      var day = d.getDay();
      var diff = d.getDate() + (7 - (day == 0 ? 7 : day)); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    sortDates(a: any, b: any, isAsc: boolean) {
      console.log("sorting");
    },
  },
});
