
import Vue from "vue";
import { BaseCallback } from "@/models/Callback/BaseCallback";
import CallbackFlowTemplateComponent from "@/components/Callback/CallbackFlowTemplateComponent.vue";

export default Vue.extend({
  name: "CallbackCreated",
  components: { CallbackFlowTemplateComponent },
  data() {
    return {
      callback: {} as BaseCallback,
    };
  },
  beforeMount: function () {
    this.callback = this.$store.getters.currentCallback;
  },
  computed: {
    formattedDate(): string {
      if (this.callback.agreedDate) {
        if (this.callback.country == "Australia") {
          return this.callback.agreedDate.toLocaleDateString("en-AU");
        } else {
          return this.callback.agreedDate.toLocaleDateString("en-NZ");
        }
      } else {
        return "";
      }
    },
    agreedTime(): string {
      return `${this.callback.agreedHour.toString()}:${
        this.callback.agreedMinute == 0 ? "00" : this.callback.agreedMinute?.toString()
      } ${this.callback.ampm}`;
    },
  },
  methods: {
    goToCallbacksPage() {
      this.$router.push("/callback/callbacks");
    },
    goToHomePage: function () {
      this.$router.push("/home");
    },
  },
});
