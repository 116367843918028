
import Vue from "vue";

export default Vue.extend({
  name: "DateSplitCompoment",
  props: {
    value: Date,
    label: String,
    validationErrorField: String,
    validationErrors: {
      type: Object as () => Record<string, { message: string }>,
    },
  },
  data() {
    return {
      day: null as string | null,
      month: null as string | null,
      year: null as string | null,
      invalidDate: false,
      errorMessage: "",
    };
  },
  beforeMount() {
    if (this.value) {
      const date = new Date(this.value);

      this.day = date.getDate().toString();
      this.month = (date.getMonth() + 1).toString();
      this.year = date.getFullYear().toString();

      if (this.day.length == 1) this.day = "0" + this.day;
      if (this.month.length == 1) this.month = "0" + this.month;
      if (this.year.length < 4) {
        var missingZeros = Math.abs(4 - this.year.length);
        for (let i = 0; i < missingZeros; i++) {
          this.year = "0" + this.year;
        }
      }
    }
  },
  methods: {
    clearError(field: string) {
      // console.log("cleaning");
      if (this.validationErrors && this.validationErrors[field]) {
        delete this.validationErrors[field];
      }
    },
    filterDays() {
      // numbers only
      let textValue = this.day?.replace(/[^0-9]+/g, "") || null;
      this.day = textValue;

      // return if empty
      if (!textValue) return;

      const numValue = Number(textValue);

      if (textValue.length == 1) {
        if (numValue > 3) {
          (this.$refs.month as HTMLInputElement).focus();
        }
      } else {
        // [1, 31]
        if (numValue < 1) textValue = "1";
        else if (numValue > 31) textValue = "31";

        this.day = textValue;

        (this.$refs.month as HTMLInputElement).focus();
      }
    },
    lostFocusDays() {
      let textValue = this.day;

      if (!textValue) {
        this.day = null;
        return;
      }

      if (textValue == "0") textValue = "1";

      if (textValue.length < 2) textValue = "0" + textValue;

      this.day = textValue;

      this.isDateValid();
    },
    filterMonths() {
      // numbers only
      let textValue = this.month?.replace(/[^0-9]+/g, "") || null;
      this.month = textValue;

      // return if empty
      if (!textValue) return;

      let numValue = Number(textValue);

      if (textValue.length == 1) {
        if (numValue > 1) {
          (this.$refs.year as HTMLInputElement).focus();
        }
      } else {
        // [1, 12]
        if (numValue < 1) textValue = "1";
        else if (numValue > 12) textValue = "12";

        this.month = textValue;
        (this.$refs.year as HTMLInputElement).focus();
      }
    },
    lostFocusMonths() {
      let textValue = this.month;

      if (!textValue) {
        this.month = null;
        return;
      }

      if (textValue == "0") textValue = "1";

      if (textValue.length < 2) textValue = "0" + textValue;

      this.month = textValue;

      this.isDateValid();
    },
    filterYears() {
      // numbers only
      let textValue = this.year?.replace(/[^0-9]+/g, "") || null;
      this.year = textValue;

      // return if empty
      if (!textValue) return;

      if (textValue.length == 4) {
        this.isDateValid();
      }
    },
    lostFocusYears() {
      let textValue = this.year;

      if (!textValue) {
        this.year = null;
        return;
      }

      if (textValue == "0") textValue = "1";

      if (textValue.length < 4) {
        var missingZeros = Math.abs(4 - textValue.length);
        for (let i = 0; i < missingZeros; i++) {
          textValue = "0" + textValue;
        }
      }

      this.year = textValue;

      // check if whole date is valid
      this.isDateValid();
    },
    isDateValid() {
      this.clearError(this.validationErrorField);

      this.invalidDate = false;
      this.errorMessage = "";

      const numDay = Number(this.day);
      const numMonth = Number(this.month) == 0 ? 0 : Number(this.month) - 1;
      const numYear = Number(this.year);

      // don't check if there is a field missing
      if (!this.day || !this.month || !this.year) {
        this.updateValue(null);
        return;
      }

      var dateCheck = new Date(Date.UTC(numYear, numMonth, numDay));

      // console.log("new date is: ", dateCheck.toISOString());

      if (dateCheck.getDate() == numDay && dateCheck.getMonth() == numMonth && dateCheck.getFullYear() == numYear) {
        this.updateValue(dateCheck);
        return;
      }

      this.invalidDate = true;
      this.errorMessage = "Invalid date";
      this.updateValue(null);
    },
    updateValue(date: Date | null) {
      this.$emit("input", date);
    },
  },
});
