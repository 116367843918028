
import Vue from "vue";
import { IClient } from "@/interfaces/IClient";
import { ComplianceService } from "@/services/complianceService";

export default Vue.extend({
  name: "ComplianceReport",
  data() {
    return {
      loadingExistingComplianceDocuments: false,
      existingComplianceDocuments: [] as Array<any>,
    };
  },
  computed: {
    fundraiserId(): number {
      return this.$store.getters.currentUserId;
    },
    numPledgesToSync(): number {
      return this.$store.getters.numberOfPledgesToSync;
    },
  },
  watch: {},
  mounted() {
    this.loadExistingCompliances();
  },
  methods: {
    loadExistingCompliances() {
      this.loadingExistingComplianceDocuments = true;
      new ComplianceService()
        .getFundraiserCompliances()
        .then((response) => {
          this.existingComplianceDocuments = response;
        })
        .finally(() => {
          this.loadingExistingComplianceDocuments = false;
        });
    },
    startNewCompliance: function () {
      // redirect to payment details
      this.$router.push("/compliance/new");
    },
    logout() {
      this.$buefy.dialog.confirm({
        title: "Logout from account",
        message: "Are you sure you want to <b>logout</b> from Catalyst?",
        confirmText: "Yes",
        cancelText: "No",
        hasIcon: true,
        icon: "logout",
        onConfirm: () => {
          this.$store.dispatch("logout");
          this.$router.push("/");
        },
      });
    },
    goHome() {
      this.$router.push("/home");
    },
  },
});
