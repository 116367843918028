
import Vue from "vue";

import { IsOnlineHelper } from "@/helpers/isOnlineHelper";
import { IClient } from "@/interfaces/IClient";
import { AustraliaPledge } from "@/models/AustraliaPledge";

export default Vue.extend({
  name: "DirectDebitStripeBecsComponent",
  props: {
    pledge: {
      type: Object as () => AustraliaPledge,
    },
    validationErrors: {
      type: Object as () => Record<string, { message: string }>,
    },
  },
  beforeMount() {
    if (!this.pledge.accountName) {
      this.pledge.accountName = (this.pledge.firstName + " " + this.pledge.lastName).trim();
    }
    if (this.pledge.accountName == (this.pledge.firstName + " " + this.pledge.lastName).trim()) {
      this.pledge.authorizedSignatorReason = null;
    }
  },
  data: function () {
    return {
      authorizedSignatoryReasons: [
        "Previous known name",
        "Authorised signatory of business card",
        "Authorised signatory of partners account",
        "Preferred name differs from given name",
      ],
      showDDR: false,
    };
  },
  async mounted() {
    if (!this.pledge.accountName) {
      this.pledge.accountName = (this.pledge.firstName + " " + this.pledge.lastName).trim();
    }

    if (this.pledge.accountName == (this.pledge.firstName + " " + this.pledge.lastName).trim()) {
      this.pledge.authorizedSignatorReason = null;
    }
  },
  methods: {
    clearError(field: string) {
      this.pledge.agreedToStripeDDR = false;

      if (this.validationErrors && this.validationErrors[field]) {
        delete this.validationErrors[field];
      }
    },
    accountNameChange() {
      this.clearError("accountName");

      if (this.pledge.accountName == (this.pledge.firstName + " " + this.pledge.lastName).trim()) {
        this.pledge.authorizedSignatorReason = null;
      }
    },
  },
  computed: {
    authorizedSignatory(): boolean {
      if (this.pledge.firstName || this.pledge.lastName)
        return this.pledge.accountName == (this.pledge.firstName + " " + this.pledge.lastName).trim();
      return true;
    },

    clientName(): string {
      const client = this.$store.getters.selectedClient as IClient;
      return client.clientName.replace("(F2F)", "").trim();
    },
    offline(): boolean {
      return this.$store.getters.getOnlineIndicator;
    },
  },
});
