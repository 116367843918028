import { v4 as uuidv4 } from "uuid";
import parsePhoneNumber from "libphonenumber-js/max";
import ccvalid from "card-validator";
import { BankDetailsService } from "@/services/bankDetailsService";
import { CryptHelper } from "@/helpers/cryptHelper";
import { Udf } from "@/interfaces/udf.interface";
import { PledgeLog } from "./PledgeLog";
import { EncryptedPledge } from "./EncryptedPledge";

export abstract class BasePledge {
  deviceMetadata: { language: string; platform: string; agent: string };
  appVersion: string | undefined;

  pledgeGuid: string;
  edcPledgeId: number;
  dateCreated: Date;
  longitude: number | null;
  latitude: number | null;

  clientId: number;
  locationId: number;
  bookingId: number;
  fundraiserId: number;
  callbackId: number | null;

  type: string;
  company: string;
  position: string;
  title: string;
  gender: string;
  firstName: string;
  lastName: string;
  occupation: string;
  dateOfBirth: Date | null;
  country: string;
  suggestedAddress: string;
  mobilePhone: string;
  homePhone: string;
  workPhone: string;
  verificationSmsResponse: string;
  email: string;
  frequency: string | null;
  amount: number;
  cpiIncrease: boolean;
  // minAmount: number;
  paymentMethod: string;
  authorizedSignatorReason: string | null;
  firstPaymentDate: string | null;
  creditCardType: string;
  creditCardName: string;
  creditCardNumber: string;
  creditCardExpiryDate: string;
  creditCardMask: string;

  payshieldRequestId: string; // this will be the payshield reference number if this pledge uses payshield tokenization
  payshieldApproved: boolean;
  payshieldResponse: string;

  firstSignature: string;
  secondSignature: string;
  thirdSignature: string;
  signature: string;

  pledgeLogs?: Array<PledgeLog>;
  pledgeUdfs: Array<Udf>;

  constructor(fundraiserId: number, clientId: number, locationId: number, bookingId: number) {
    this.appVersion = process.env.VUE_APP_EDC_VERSION;
    this.deviceMetadata = this.deviceData();

    this.pledgeGuid = uuidv4();
    this.dateCreated = new Date();
    this.edcPledgeId = 0;
    this.longitude = null;
    this.latitude = null;

    this.fundraiserId = fundraiserId;
    this.clientId = clientId;
    this.locationId = locationId;
    this.bookingId = bookingId;
    this.callbackId = null;

    this.type = "";
    this.company = "";
    this.position = "";
    this.title = "";
    this.gender = "";
    this.firstName = "";
    this.lastName = "";
    this.occupation = "";
    this.dateOfBirth = null;
    this.country = "";
    this.suggestedAddress = "";
    this.mobilePhone = "";
    this.homePhone = "";
    this.workPhone = "";
    this.verificationSmsResponse = "";
    this.email = "";
    this.frequency = null;
    this.amount = 0;
    this.cpiIncrease = false;
    // this.minAmount = 0;
    this.paymentMethod = "";
    this.authorizedSignatorReason = null;
    this.firstPaymentDate = null;
    this.creditCardType = "";
    this.creditCardName = "";
    this.creditCardNumber = "";
    this.creditCardExpiryDate = "";
    this.creditCardMask = "";

    this.firstSignature = "";
    this.secondSignature = "";
    this.thirdSignature = "";
    this.signature = "";

    this.payshieldRequestId = "";
    this.payshieldApproved = false;
    this.payshieldResponse = "";

    this.pledgeLogs = [];
    this.pledgeUdfs = [];
  }

  countryCode(): "AU" | "NZ" {
    return this.country == "Australia" ? "AU" : "NZ";
  }

  assignJson(jsonObject: BasePledge): BasePledge {
    const obj = Object.assign(this, jsonObject);

    // change the type of date of birth
    if (typeof obj.dateOfBirth == "string") {
      obj.dateOfBirth = new Date(obj.dateOfBirth);
    }

    return obj;
  }

  encrypted(rsaPublicKey: string): string {
    const enc = CryptHelper.encryptAES(JSON.stringify(this));
    const encKey = CryptHelper.encryptRSA(enc.key, rsaPublicKey);
    const encIv = CryptHelper.encryptRSA(enc.iv, rsaPublicKey);

    return `${encKey}\n${encIv}\n${enc.encryptedPayload}`;
  }

  toEncryptedPledge(rsaPublicKey: string): EncryptedPledge {
    const { pledgeGuid, edcPledgeId, fundraiserId, firstName, lastName, dateCreated } = this;
    const encryptedPledgeData = this.encrypted(rsaPublicKey);

    return {
      pledgeGuid,
      edcPledgeId,
      fundraiserId,
      firstName,
      lastName,
      dateCreated,
      encryptedPledgeData,
    };
  }

  clearCreditCardDetails(): void {
    this.creditCardNumber = "";
    this.creditCardName = "";
    this.creditCardMask = "";
    this.creditCardExpiryDate = "";
    this.payshieldApproved = false;
    this.payshieldRequestId = "";
  }

  abstract clearDirectDebitDetails(): void;

  /**
   * Validate pledge UDF's for a specific step.
   * @returns an object with this structure for each field that failed: { fieldName: { message: "" } }
   */
  validateUdfs(
    page: "personal-details" | "contact-details" | "contribution-details" | "payment-details"
  ): Record<string, { message: string }> {
    const validationErrors = {} as Record<string, { message: string }>;

    if (process.env.NODE_ENV == "development" && (window as any).klapaucius) {
      console.log("Skipping validation u cheater:", (window as any).klapaucius);
      return validationErrors;
    }

    console.log("validating udfs");

    for (let i = 0; i < this.pledgeUdfs.length; i++) {
      const udf = this.pledgeUdfs[i];

      if (udf.page != page) continue;
      if (!udf.mandatory) continue;
      if (!udf.value) {
        console.log(udf.label + " not valid " + udf.value);
        validationErrors[udf.id] = { message: `${udf.label} required` };
      }
    }

    return validationErrors;
  }

  /**
   * Validate pledge personal details.
   * @returns an object with this structure for each field that failed: { fieldName: { message: "" } }
   */
  validatePersonalDetails(minAge = 18, maxAge = 100): Record<string, { message: string }> {
    const validationErrors = {} as Record<string, { message: string }>;

    if (process.env.NODE_ENV == "development" && (window as any).klapaucius) {
      console.log("Skipping validation u cheater:", (window as any).klapaucius);
      return validationErrors;
    }

    //type
    if (!this.type) {
      validationErrors["type"] = { message: "Type required" };
    } else {
      if (this.type.length > 50) {
        validationErrors["type"] = { message: "Max type length is 50 characters" };
      }

      //company & position
      if (this.type.trim().toUpperCase() == "COMPANY") {
        if (!this.company) {
          validationErrors["company"] = { message: "Company required" };
        } else {
          if (this.company.length > 500) {
            validationErrors["company"] = { message: "Max company length is 500 characters" };
          }
        }
        if (!this.position) {
          validationErrors["position"] = { message: "Position required" };
        } else {
          if (this.position.length > 100) {
            validationErrors["position"] = { message: "Max position length is 500 characters" };
          }
        }
      }
    }

    //title
    if (!this.title) validationErrors["title"] = { message: "Title required" };
    else if (this.title.length > 10) validationErrors["title"] = { message: "Max title length is 10 characters" };

    //gender
    if (!this.gender) validationErrors["gender"] = { message: "Gender required" };
    else if (this.gender.length > 10) validationErrors["gender"] = { message: "Max gender length is 100 characters" };

    //first name
    if (!this.firstName) validationErrors["firstName"] = { message: "First Name required" };
    else if (this.firstName.length > 100)
      validationErrors["firstName"] = { message: "Max first name length is 100 characters" };

    //last name
    if (!this.lastName) validationErrors["lastName"] = { message: "Last Name required" };
    else if (this.lastName.length > 100)
      validationErrors["lastName"] = { message: "Max last name length is 100 characters" };

    //occupation
    if (!this.occupation) validationErrors["occupation"] = { message: "Occupation required" };
    else if (this.occupation.length > 100)
      validationErrors["occupation"] = { message: "Max occupation length is 100 characters" };

    //dob
    if (!this.dateOfBirth) {
      validationErrors["dateOfBirth"] = { message: "Date of birth required" };
    } else {
      const age = this.calculateAge(this.dateOfBirth);
      if (age < minAge || age > maxAge) {
        validationErrors["dateOfBirth"] = { message: `Age must be between ${minAge} and ${maxAge}` };
      }
    }

    return validationErrors;
  }

  /**
   * Calculate age using the date of birth, from: https://stackoverflow.com/a/21984136/4119092
   * @returns age
   */
  calculateAge(birthday: Date): number {
    // birthday is a date
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  /**
   * Validate pledge contact details. It's implementation depends on the country.
   * @returns True if pledge contact details are valid.
   */
  abstract validateContactDetails(): Record<string, { message: string }>;

  /**
   * Validate country.
   * @returns True if country is valid.
   */
  validateCountry(): Record<string, { message: string }> {
    const validationErrors = {} as Record<string, { message: string }>;

    if (!this.country) validationErrors["country"] = { message: "Country required" };
    else if (this.country.length > 20) validationErrors["country"] = { message: "Max country length is 20 characters" };

    return validationErrors;
  }

  /**
   * Validate phone numbers.
   * @returns True if at least one phone number exist and all phone numbers inserted are valid.
   */
  validatePhoneNumbers(): Record<string, { message: string }> {
    const validationErrors = {} as Record<string, { message: string }>;

    if (!this.mobilePhone && !this.homePhone && !this.workPhone) {
      validationErrors["mobilePhone"] = { message: "At least one phone number is required" };
      return validationErrors;
    }

    const countryAbv = this.countryCode();

    //mobile phone
    if (this.mobilePhone) {
      if (this.mobilePhone.charAt(0) != "0") {
        validationErrors["mobilePhone"] = { message: "Mobile phone must start with 0" };
      } else {
        const mobile = parsePhoneNumber(this.mobilePhone, countryAbv);
        if (!mobile?.isValid()) {
          validationErrors["mobilePhone"] = { message: "Mobile phone not valid" };
        } else if (mobile.getType() != "MOBILE") {
          validationErrors["mobilePhone"] = { message: "Not a mobile phone" };
        }
      }
    }

    //home phone
    if (this.homePhone) {
      if (this.homePhone.charAt(0) != "(" && this.homePhone.charAt(0) != "0") {
        validationErrors["homePhone"] = { message: "Home phone must start with 0" };
      } else {
        const home = parsePhoneNumber(this.homePhone, countryAbv);
        if (!home?.isValid() || home.getType() != "FIXED_LINE") {
          validationErrors["homePhone"] = { message: "Home phone not valid" };
        }
      }
    }

    //work phone
    if (this.workPhone) {
      if (this.workPhone.charAt(0) != "(" && this.workPhone.charAt(0) != "0") {
        validationErrors["workPhone"] = { message: "Work phone must start with 0" };
      } else {
        const work = parsePhoneNumber(this.workPhone, countryAbv);
        if (!work?.isValid() || work.getType() != "FIXED_LINE") {
          validationErrors["workPhone"] = { message: "Work phone not valid" };
        }
      }
    }

    return validationErrors;
  }

  /**
   * Validate email address.
   * @returns True if email is valid.
   */
  validateEmail(): Record<string, { message: string }> {
    const validationErrors = {} as Record<string, { message: string }>;

    /* Evandro: I am validating the email only if it is present for now, but since
     * this is a recurring subject, I will only comment the validation code.
     * In case we need this validation again, just uncomment the below piece of code.
     */

    // if (!this.email) validationErrors["email"] = { message: "Email required" };
    // else if (this.email.length > 100) validationErrors["email"] = { message: "Max email length is 100 characters" };
    // else {
    //   // verification using Regex
    //   const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    //   if (!regex.test(this.email)) validationErrors["email"] = { message: "Email not valid" };
    // }

    if (this.email) {
      if (this.email.length > 100) {
        validationErrors["email"] = { message: "Max email length is 100 characters" };
      } else {
        // verification using Regex
        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!regex.test(this.email)) validationErrors["email"] = { message: "Email not valid" };
      }
    }

    return validationErrors;
  }

  /**
   * Validate contribution details.
   * @returns True if pledge contribution details are valid.
   */
  validateContributionDetails(minAmount = 10): Record<string, { message: string }> {
    const validationErrors = {} as Record<string, { message: string }>;

    if (process.env.NODE_ENV == "development" && (window as any).klapaucius) {
      console.log("Skipping validation u cheater:", (window as any).klapaucius);
      return validationErrors;
    }

    //frequency
    if (!this.frequency) validationErrors["frequency"] = { message: "Frequency required" };
    else if (this.frequency.length > 50)
      validationErrors["frequency"] = { message: "Max frequency length is 50 characters" };

    //amount
    if (!this.amount) validationErrors["amount"] = { message: "Amount required" };

    //other amount
    if (this.amount < minAmount) validationErrors["otherAmount"] = { message: `Minimum amount is $${minAmount}` };
    else if (this.amount > 100000)
      validationErrors["otherAmount"] = { message: `Amount can't be higher than $100,000` };

    //payment method
    if (!this.paymentMethod) validationErrors["paymentMethod"] = { message: "Payment method required" };
    if (this.paymentMethod.length > 20)
      validationErrors["paymentMethod"] = { message: "Max payment method length is 20 characters" };

    //first payment date
    if (!this.firstPaymentDate) validationErrors["firstPaymentDate"] = { message: "Date of first payment required" };

    return validationErrors;
  }

  /**
   * Validate payment details. It's implementation depends on the country.
   * @returns True if pledge payment details are valid.
   */
  async validatePaymentDetails(
    rsaPublicKey: string | null = null,
    bankService: BankDetailsService
  ): Promise<Record<string, { message: string }>> {
    if (!rsaPublicKey) {
      throw "RSA public key not found";
    }

    if (this.paymentMethod && this.paymentMethod.trim().toUpperCase() == "CREDIT CARD") {
      return this.validateCreditCardDetails(rsaPublicKey);
    } else {
      return await this.validateDirectDebitDetails(bankService);
    }
  }

  validateCreditCardDetails(rsaPublicKey: string): Record<string, { message: string }> {
    const validationErrors = {} as Record<string, { message: string }>;

    if (process.env.NODE_ENV == "development" && (window as any).klapaucius) {
      console.log("Skipping validation u cheater:", (window as any).klapaucius);
      return validationErrors;
    }

    //credit card type
    if (!this.creditCardType) validationErrors["creditCardType"] = { message: "Credit card type required" };
    else if (this.creditCardType.length > 50)
      validationErrors["creditCardType"] = { message: "Max credit card type length is 50 characters" };

    //credit card name
    if (!this.creditCardName) validationErrors["creditCardName"] = { message: "Credit card name required" };
    else if (this.creditCardName.length > 50)
      validationErrors["creditCardName"] = { message: "Max credit card name length is 50 characters" };

    // authorized signator reason, now mandatory
    if (this.creditCardName) {
      if (this.creditCardName !== this.firstName + " " + this.lastName) {
        if (!this.authorizedSignatorReason || !this.authorizedSignatorReason.trim()) {
          validationErrors["authorizedSignatorReason"] = { message: "Reason required" };
        }
      } else {
        this.authorizedSignatorReason = null;
      }
    }

    //credit card expiry date
    if (!this.creditCardExpiryDate)
      validationErrors["creditCardExpiryDate"] = { message: "Credit card expiry date required" };
    else if (!this.firstPaymentDate) {
      validationErrors["creditCardExpiryDate"] = {
        message: "Date of first payment not found, go back to contribution details",
      };
    } else {
      const ccExpiryMonth: string = this.creditCardExpiryDate.slice(0, 2);
      const ccExpiryYear: string = this.creditCardExpiryDate.slice(2);

      const auxCreditCardExpiryDate = new Date(Number("20" + ccExpiryYear), Number(ccExpiryMonth), 1);

      const dt_firstPaymentDate = new Date(this.firstPaymentDate);

      if (auxCreditCardExpiryDate <= dt_firstPaymentDate)
        validationErrors["creditCardExpiryDate"] = { message: "Credit card expiry before the date of first payment" };
    }

    // means that cc was never assigned or it was cleared
    if (!this.creditCardMask) {
      const ccNumber = this.creditCardNumber.replace(/\s/g, "");
      //credit card number
      if (!ccNumber) {
        validationErrors["creditCardNumber"] = { message: "Credit card number required" };
      } else if (ccNumber.length > 32) {
        validationErrors["creditCardNumber"] = { message: "Max credit card number length is 32 characters" };
      } else {
        const ccValidator = ccvalid.number(ccNumber);
        if (!ccValidator.isValid) {
          validationErrors["creditCardNumber"] = { message: "Invalid credit card number" };
        }
      }

      // mask and encrypt the credit card if validation passes
      if (Object.keys(validationErrors).length == 0) {
        this.creditCardMask = this.maskCcNumber(ccNumber);
        this.creditCardNumber = CryptHelper.encryptRSA(ccNumber, rsaPublicKey);
      }
    } else {
      console.log("already encrypted and didn't change");
    }

    // if online: use Payshield to verify CC number

    return validationErrors;
  }

  maskCcNumber(ccnumber: string): string {
    let maskedNumber = "";

    for (let i = 0; i < ccnumber.length - 4; i++) {
      maskedNumber += "X";
    }

    maskedNumber += ccnumber.slice(ccnumber.length - 4);

    return maskedNumber;
  }

  /**
   * Validate direct debit details. It's implementation depends on the country.
   * @returns True if pledge direct debit details are valid.
   */
  abstract validateDirectDebitDetails(bankService: BankDetailsService): Promise<Record<string, { message: string }>>;

  /**
   * Validate confirmation page.
   * @returns True if all signatures are valid.
   */
  validateSignatures(): Record<string, { message: string }> {
    const validationErrors = {} as Record<string, { message: string }>;

    if (!this.firstSignature) validationErrors["firstSignature"] = { message: "Signature required" };

    if (!this.secondSignature) validationErrors["secondSignature"] = { message: "Signature required" };

    if (!this.thirdSignature) validationErrors["thirdSignature"] = { message: "Signature required" };

    if (!this.signature) validationErrors["signature"] = { message: "Signature required" };

    return validationErrors;
  }

  private browserName() {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(" ");
  }

  private deviceData() {
    return {
      language: navigator.language,
      platform: navigator.platform,
      agent: navigator.userAgent,
      browser: this.browserName(),
    };
  }
}
