import { Database } from "@/database";
import { ToastHelper } from "@/helpers/toastHelper";
import { BasePledge } from "@/models/BasePledge";
import store from "@/store";
import { parsePhoneNumber } from "libphonenumber-js/max";
import { BaseService } from "./baseService";
import { LogService } from "./logService";
import { EncryptedPledge } from "@/models/EncryptedPledge";

export class PledgeService extends BaseService {
  database: Database;

  constructor() {
    super("pledge");

    this.database = Database.getInstance();
  }

  async getPledgesToSync(fundraiserId: number): Promise<EncryptedPledge[]> {
    return await this.database.pledges.where({ fundraiserId: fundraiserId, edcPledgeId: 0 }).toArray();
  }

  async getNumberOfPledgesToSync(): Promise<number> {
    return await this.database.pledges.where("edcPledgeId").equals(0).count();
  }

  async updateNumberOfPledgesToSync(): Promise<void> {
    store.commit("setPledgesToSync", await this.getNumberOfPledgesToSync());
  }

  async storePledge(rsaPublicKey: string, pledge: BasePledge): Promise<{ error: string }> {
    try {
      // store
      const encryptedPledge = pledge.toEncryptedPledge(rsaPublicKey);
      await this.database.pledges.add(encryptedPledge, pledge.pledgeGuid);

      // update count
      await this.updateNumberOfPledgesToSync();

      ToastHelper.success("Pledge stored in the device");

      return { error: "" };
    } catch (error: any) {
      if (error.name == "ConstraintError" && error.inner.message == "Key already exists in the object store.") {
        ToastHelper.error("Pledge already stored in the device.");
        throw new Error("Pledge already stored in the device.");
      } else {
        ToastHelper.error("Error storing pledge in the device, contact IT support");
        throw new Error(error);
      }
    }
  }

  async syncPledges(): Promise<string> {
    store.commit("setPledgesSyncing", true); // setting syncing

    const pledgesToSync = await this.database.pledges.where("edcPledgeId").equals(0).toArray();

    let numPledgesSynced = 0;

    for (let i = 0; i < pledgesToSync.length; i++) {
      const pledge = pledgesToSync[i];
      let result = 0;

      try {
        result = await this.syncPledge(pledge.pledgeGuid);
      } catch (error) {
        console.log(error);
      }

      if (result > 0) {
        numPledgesSynced++;
      }
    }

    store.commit("setPledgesSyncing", false); // finished syncing

    return `${numPledgesSynced} of ${pledgesToSync.length} pledge(s) synced`;
  }

  async syncPledge(pledgeGuid: string): Promise<number> {
    try {
      const queryResult = await this.database.pledges.where({ pledgeGuid: pledgeGuid, edcPledgeId: 0 }).toArray();

      if (queryResult == null || queryResult.length == 0) {
        throw Error(`No pledge found with GUID: ${pledgeGuid} and no EdcPledgeId`);
      }

      if (queryResult.length > 1) {
        throw Error(`More than one pledge found with GUID: ${pledgeGuid} and no EdcPledgeId`);
      }

      const encryptedPledge = queryResult[0];

      const plainText = { type: "text/plain" };
      const encryptedPledgeDataBlob = new Blob([encryptedPledge.encryptedPledgeData], plainText);
      const encryptedPledgeDataFile = new File([encryptedPledgeDataBlob], encryptedPledge.pledgeGuid, plainText);
      const formData = new FormData();
      formData.append("file", encryptedPledgeDataFile, encryptedPledge.pledgeGuid);

      const axiosInstance = await this.getAxiosInstance();
      const response = await axiosInstance.post("submit-encrypted", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response);

      // verify edc pledge id
      const edcPledgeId = Number(response.data);
      if (isNaN(edcPledgeId) || edcPledgeId <= 0) {
        throw Error("EdcPledgeId not recognized");
      }

      // update the pledge in dexie with the hit outcome (EDC pledge id)
      await this.database.pledges.update(encryptedPledge.pledgeGuid, { edcPledgeId: response.data });

      LogService.info(`Pledge submitted. PledgeGuid: ${pledgeGuid}. EdcPledgeId: ${edcPledgeId}`);

      ToastHelper.success(`Pledge synced. EdcPledgeId: ${response.data}`);

      return response.data;
    } catch (error: any) {
      let errorMessage = "";

      // error handling
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // update the pledge in dexie if the outcome is duplicated pledge
        if (error.response.data.error == "Duplicated pledge found") {
          const edcPledgeId = Number(error.response.data.edcPledgeId);
          if (isNaN(edcPledgeId) || edcPledgeId <= 0) {
            throw Error("EdcPledgeId not recognized");
          } else {
            await this.database.pledges.update(pledgeGuid, { edcPledgeId: edcPledgeId });
            errorMessage = `Duplicated pledge found, Id: ${edcPledgeId}`;
          }
        } else {
          // any other outcome
          errorMessage = error.response.data;
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        errorMessage = "No response, please try again later";
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        errorMessage = error.message;
      }

      ToastHelper.error(`Error while syncing the pledge. ${errorMessage}. Please contact support`);

      throw Error(errorMessage);
    } finally {
      await this.removeSubmittedPledges();
      await this.updateNumberOfPledgesToSync();
    }
  }

  async removeSubmittedPledges(): Promise<void> {
    console.log("removing submitted pledges from device");

    const submittedPledges = await this.database.pledges.where("edcPledgeId").notEqual(0).toArray();

    for (let i = 0; i < submittedPledges.length; i++) {
      const pledge = submittedPledges[i];

      console.log("pledge to be deleted, edcPledgeId:", pledge.edcPledgeId, "pledgeGuid:", pledge.pledgeGuid);

      await this.database.pledges.delete(pledge.pledgeGuid);
    }
  }

  async tokenizePledgeCcNumberWithPayshield(pledge: BasePledge) {
    console.log("sending credit card for payshield tokenization");

    try {
      if (pledge.paymentMethod.trim().toUpperCase() != "CREDIT CARD")
        throw new Error("Pledge payment method is not credit card, can not tokenize");

      const axiosInstance = await this.getAxiosInstance();

      const payload = {
        clientId: pledge.clientId,
        payshieldReference: pledge.pledgeGuid,
        encryptedNumber: pledge.creditCardNumber,
        cardholderName: pledge.creditCardName,
        expiryMonth: pledge.creditCardExpiryDate.slice(0, 2),
        expiryYear: pledge.creditCardExpiryDate.slice(2),
        amount: pledge.amount,
      };

      console.log("payload is:", payload);

      const response = await axiosInstance.post("/payshieldtokenization", payload);

      pledge.payshieldRequestId = response.data.request_id;
      pledge.payshieldApproved = response.data.success;
      pledge.payshieldResponse = response.data.response;

      return response.data;
    } catch (error: any) {
      LogService.pledgeLogError(`Error while tryign to tokenize CC with message: ${error}`, pledge);

      pledge.payshieldRequestId = "";
      pledge.payshieldApproved = false;

      return error.response.data;
    }
  }

  async sendVerificationSms(pledge: BasePledge) {
    console.log("sending pledge for verification SMS");

    try {
      if (!pledge.mobilePhone) throw new Error("No mobile phone to send verification SMS");

      const mobile = parsePhoneNumber(pledge.mobilePhone, pledge.countryCode());

      if (!mobile?.isValid() || mobile.getType() != "MOBILE") {
        throw new Error("Mobile number not valid");
      }

      const payload = {
        pledgeGuid: pledge.pledgeGuid,
        mobilePhone: mobile.formatInternational(),
        country: pledge.country,
        clientId: pledge.clientId,
        donorFirstName: pledge.firstName,
        donorLastName: pledge.lastName,
      };

      console.log("payload is:", payload);

      const axiosInstance = await this.getAxiosInstance();
      const response = await axiosInstance.post("/verificationsms", payload);

      console.log(response);

      if (response.status == 200) {
        pledge.verificationSmsResponse = response.data.response;
        LogService.pledgeLog(`Verification SMS successful response: ${response.data.response}`, pledge);
      } else {
        LogService.pledgeLogError(`Verification SMS bad response: ${response.data.response}`, pledge);
      }

      return response.data;
    } catch (error: any) {
      let errorMessage = "";

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);

        errorMessage = error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        errorMessage = "Unable to send, make sure you are online to use this feature";
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error:", error.message);
        errorMessage = error.message;
      }

      console.log(error.config);

      throw errorMessage;
    }
  }

  async validateEmail(pledge: BasePledge) {
    try {
      if (!pledge.email) throw new Error("No email to validate");

      const axiosInstance = await this.getAxiosInstance();
      const response = await axiosInstance.post("/emailvalidation?email=" + pledge.email);

      console.log(response);

      return response.data;
    } catch (error: any) {
      let errorMessage = "";

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);

        errorMessage = error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        errorMessage = "Unable to send, make sure you are online to use this feature";
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error:", error.message);
        errorMessage = error.message;
      }

      console.log(error.config);

      throw errorMessage;
    }
  }

  async recover(file: any): Promise<number> {
    try {
      console.log(file);

      const formData = new FormData();
      formData.append("file", file);

      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.post("/recover", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // verify edc pledge id
      const edcPledgeId = Number(response.data);
      if (isNaN(edcPledgeId) || edcPledgeId <= 0) {
        throw Error("EdcPledgeId not recognized");
      }

      LogService.info(`Pledge recovered. EdcPledgeId: ${edcPledgeId}`);
      return response.data;
    } catch (error: any) {
      let errorMessage = "";

      // error handling
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // update the pledge in dexie if the outcome is duplicated pledge
        if (error.response.data.error == "Duplicated pledge found") {
          const edcPledgeId = Number(error.response.data.edcPledgeId);
          if (isNaN(edcPledgeId) || edcPledgeId <= 0) {
            throw Error("EdcPledgeId not recognized");
          } else {
            errorMessage = `Duplicated pledge found, Id: ${edcPledgeId}`;
          }
        } else {
          // any other outcome
          errorMessage = error.response.data;
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        errorMessage = "No response, please try again later";
      } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = error.message;
      }

      throw Error(errorMessage);
    }
  }
}
