
import Vue from "vue";
import { BasePledge } from "@/models/BasePledge";
import FileSaver from "file-saver";
import PledgeFlowTemplateComponent from "@/components/PledgeFlowTemplateComponent.vue";

export default Vue.extend({
  name: "ThankYou",
  components: { PledgeFlowTemplateComponent },
  props: {},
  data() {
    return {
      pledge: {} as BasePledge,
      downloadUrl: "",
    };
  },
  beforeMount: function () {
    const currentPledge = this.$store.getters.currentPledge;
    if (currentPledge) this.pledge = currentPledge;
  },
  methods: {
    downloadPledgeFile() {
      const currentDate = new Date();
      const dateStr = `${currentDate.getFullYear()}-${
        currentDate.getMonth() + 1
      }-${currentDate.getDate()}-${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}`;

      FileSaver.saveAs(
        new Blob([this.pledge.encrypted(this.$store.state.rsaPublicKey)], {
          type: "text/plain;charset=utf-8",
        }),
        `${dateStr}_${this.pledge.pledgeGuid}.edc`
      );
    },
    goHome: function () {
      this.$store.dispatch("removeCurrentPledge");
      this.$router.push("/home");
    },
  },
});
