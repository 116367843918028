
import Vue from "vue";
import VersionComponent from "@/components/VersionComponent.vue";
import { ImagePreloader } from "@/helpers/imagePreloader";

export default Vue.extend({
  name: "CallbackFlowTemplateComponent",
  components: { VersionComponent },
  props: {},
  data() {
    return {
      cornucopiaLogo: "",
      year: new Date().getFullYear(),
      smallLogoUrl: "",
      stepNumber: 0,
      steps: [
        { id: 0, url: "/callback/contributiondetails", icon: "mdi-cash-multiple" },
        { id: 1, url: "/callback/paymentdetails", icon: "mdi-credit-card" },
        { id: 2, url: "/callback/confirmation", icon: "mdi-check" },
      ],
      hideSteps: false,
    };
  },
  beforeMount() {
    // find step
    const step = this.steps.find((o) => o.url == this.$router.currentRoute.path);

    if (step) {
      this.stepNumber = step.id;
    } else {
      this.hideSteps = true;
    }

    this.cornucopiaLogo = ImagePreloader.getCornucopiaLogo();

    const client = this.$store.getters.selectedClient;
    if (client) {
      this.smallLogoUrl = `https://base.cornucopia.com.au/assets/clients/${client.clientId}/edc/small_logo.png`;
      this.$store.dispatch(
        "setBackgroundImageUrl",
        `https://base.cornucopia.com.au/assets/clients/${client.clientId}/edc/background_picture.jpg`
      );
    }
  },
  methods: {
    changeStep(id: number) {
      if (id >= this.stepNumber) return; // return because we can't click on the current or next steps

      // find url
      this.stepNumber = id;
      const step = this.steps.find((o) => o.id == this.stepNumber);
      if (step) this.$router.push(step.url);
    },
  },
});
