
import { ToastHelper } from "@/helpers/toastHelper";
import { IClient } from "@/interfaces/IClient";
import { PledgeService } from "@/services/pledgeService";
import Vue from "vue";

export default Vue.extend({
  name: "StatusBarComponent",
  props: {},
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    online(): boolean {
      return this.$store.state.onlineIndicator;
    },
    fundraiserName(): string {
      return this.$store.getters.currentUserName;
    },
    selectedClient(): IClient {
      return this.$store.getters.selectedClient;
    },
    numPledgesToSync(): number {
      return this.$store.getters.numberOfPledgesToSync;
    },
    isSyncing(): boolean {
      return this.$store.getters.isSyncingPledges;
    },
  },
  methods: {
    async syncPledges() {
      const service = new PledgeService();

      if (!this.online) {
        ToastHelper.error("Offline Mode - Connect to the Internet to Sync your Pledges");
      } else {
        service
          .syncPledges()
          .then((result) => {
            ToastHelper.success(result);
          })
          .catch((error) => {
            ToastHelper.error(error.toString());
          });
      }
    },
  },
});
