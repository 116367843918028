
import Vue from "vue";

import { DialogProgrammatic as Dialog } from "buefy";

import PledgeFlowTemplateComponent from "@/components/PledgeFlowTemplateComponent.vue";
import UdfsComponent from "@/components/UdfsComponent.vue";
import { IClient } from "@/interfaces/IClient";
import { BasePledge } from "@/models/BasePledge";
import { ClientStyleBuilder } from "@/models/ClientStyleBuilder";
import router from "@/router";
import { LogService } from "@/services/logService";

export default Vue.extend({
  name: "ContributionDetails",
  components: { PledgeFlowTemplateComponent, UdfsComponent },
  data() {
    return {
      oldPledge: {} as BasePledge,
      pledge: {} as BasePledge,
      currentClient: {} as IClient,
      validationErrors: {} as Record<string, { message: string }>,
      frequencies: [] as Array<string>,
      values: [] as Array<string>,
      selectedAmount: "",
      otherAmount: 10,
      minPayment: 0,
      paymentMethods: ["Credit Card", "Direct Debit"],
      datesFirstPayment: [] as Array<{ name: string; value: string }>,
    };
  },
  computed: {
    cssStyle(): ClientStyleBuilder {
      return new ClientStyleBuilder(this.currentClient?.cssDetails);
    },
  },
  beforeMount: function () {
    this.currentClient = this.$store.state.selectedClient;

    if (this.currentClient.contributionAmounts && this.currentClient.contributionAmounts.length > 0) {
      this.values = this.currentClient.contributionAmounts.map((amount: number) => amount.toString());
      this.values.push("Other");
    } else {
      this.values = ["35", "40", "50", "Other"];
    }

    if (this.currentClient.frequencies && this.currentClient.frequencies.length > 0) {
      this.frequencies = this.currentClient.frequencies;
    } else {
      this.frequencies = ["Monthly"];
    }

    if (this.currentClient.dateOfFirstPayments && this.currentClient.dateOfFirstPayments.length > 0) {
      this.datesFirstPayment = this.currentClient.dateOfFirstPayments.map((o) => {
        return { name: new Date(o).toLocaleDateString(), value: o };
      });
    }

    this.minPayment = this.currentClient.minPayment || 10;
    this.otherAmount = this.minPayment > 60 ? this.minPayment : 60;

    const currentPledge = this.$store.getters.currentPledge;
    if (currentPledge) this.pledge = currentPledge;

    this.oldPledge = Object.assign({}, this.pledge);

    // default pledge to Monthly if pledge is not already set
    if (!this.pledge.frequency && this.frequencies.filter((v) => v == "Monthly")?.length > 0) {
      const frequencyFound = this.frequencies.find((value) => value == "Monthly");
      this.pledge.frequency = frequencyFound || null;
    }

    // set current pledge amount
    if (this.pledge.amount) {
      const amountFound = this.values.find((value) => value == this.pledge.amount.toString());
      if (amountFound) {
        this.selectedAmount = amountFound;
      } else {
        this.selectedAmount = "Other";
        this.otherAmount = this.pledge.amount;
      }
    }
  },
  methods: {
    clearError(field: string) {
      if (this.validationErrors && this.validationErrors[field]) {
        delete this.validationErrors[field];
      }
    },
    filterOnlyNumbers(e: Event) {
      (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value?.replace(/[^0-9]+/g, "");
    },
    setPledgeAmount() {
      if (this.selectedAmount == "Other") {
        this.pledge.amount = this.otherAmount;
      } else {
        this.pledge.amount = Number(this.selectedAmount);
      }
    },
    async validate() {
      this.validationErrors = this.pledge.validateContributionDetails(this.minPayment);
      // validate udfs
      const udfsValidationErrors = this.pledge.validateUdfs("contribution-details");
      this.validationErrors = { ...this.validationErrors, ...udfsValidationErrors };
    },
    savePledgeInStoreAndLocalStorage() {
      try {
        this.$store.dispatch("setCurrentPledge", this.pledge);
      } catch {
        console.error("error");
      }
    },
    async nextButton() {
      this.setPledgeAmount();

      this.validate();
      if (this.validationErrors && Object.keys(this.validationErrors).length > 0) return;

      await LogService.pledgeChangesLog(
        `Contribution details fulfilled, going to payment details`,
        this.oldPledge,
        this.pledge
      );

      this.savePledgeInStoreAndLocalStorage();

      this.$router.push("/pledge/paymentdetails");
    },
    previousButton: function () {
      this.$router.push("/pledge/contactdetails");
    },
    clearPledge() {
      this.$store.dispatch("clearCurrentPledge", this.pledge);
    },
    async createCallback() {
      this.setPledgeAmount();

      await this.validate();
      if (this.validationErrors && Object.keys(this.validationErrors).length > 0) return;

      await LogService.pledgeChangesLog(
        `Contribution details fulfilled, going to create callback page`,
        this.oldPledge,
        this.pledge
      );

      this.savePledgeInStoreAndLocalStorage();

      Dialog.confirm({
        title: "Save as Callback",
        message:
          "This pledge will become a callback for finalisation within <strong>4 weeks</strong>. Are you sure you want to save this pledge as a callback?",
        confirmText: "Yes",
        cancelText: "No",
        hasIcon: true,
        icon: "phone",
        onConfirm: () => {
          router.push("/callback/createcallback");
        },
      });
    },
    showCpiInfo() {
      Dialog.alert({
        title: "Increase with CPI",
        message:
          '<div style="text-align: center">This will increase your contribution yearly with the Consumer Price Index, as published by the <span style="display: inline-block;white-space: nowrap;"><strong>Australian Bureau of Statistics</strong>.</span></div>',
        size: "is-medium",
      });
    },
  },
});
