
import Vue from "vue";
import { BasePledge } from "@/models/BasePledge";
import CreditCardComponent from "@/components/Payment/CreditCardComponent.vue";
import DirectDebitAustraliaComponent from "@/components/Payment/DirectDebitAustraliaComponent.vue";
import DirectDebitNewZealandComponent from "@/components/Payment/DirectDebitNewZealandComponent.vue";
import CallbackFlowTemplateComponent from "@/components/CallbackFlowTemplateComponent.vue";
import UdfsComponent from "@/components/UdfsComponent.vue";
import { ClientStyleBuilder } from "@/models/ClientStyleBuilder";
import { IClient } from "@/interfaces/IClient";
import { PledgeService } from "@/services/pledgeService";
import { LogService } from "@/services/logService";
import { BankDetailsService } from "@/services/bankDetailsService";
import { ToastHelper } from "@/helpers/toastHelper";

export default Vue.extend({
  name: "CallbackPaymentDetails",
  components: {
    CreditCardComponent,
    DirectDebitAustraliaComponent,
    DirectDebitNewZealandComponent,
    CallbackFlowTemplateComponent,
    UdfsComponent,
  },
  data() {
    return {
      isLoading: false,
      oldPledge: {} as BasePledge,
      pledge: {} as BasePledge,
      validationErrors: {} as Record<string, { message: string }>,
    };
  },
  computed: {
    cssStyle(): ClientStyleBuilder {
      const client = this.$store.getters.selectedClient as IClient;
      return new ClientStyleBuilder(client?.cssDetails);
    },
  },
  beforeMount: function () {
    const currentPledge = this.$store.getters.currentPledge;
    if (currentPledge) this.pledge = currentPledge;

    this.oldPledge = Object.assign({}, this.pledge);

    console.log("method:", this.pledge.paymentMethod);

    if (this.pledge.paymentMethod.trim().toUpperCase() == "CREDIT CARD") {
      this.pledge.clearDirectDebitDetails();
    } else {
      this.pledge.clearCreditCardDetails();
    }
  },
  methods: {
    async nextButton() {
      // validate payment details
      this.validationErrors = await this.pledge.validatePaymentDetails(
        this.$store.state.rsaPublicKey,
        new BankDetailsService()
      );

      // validate udfs
      const udfsValidationErrors = this.pledge.validateUdfs("payment-details");
      this.validationErrors = { ...this.validationErrors, ...udfsValidationErrors };

      // try to tokenize with payshield if online and payment method is credit card
      if (
        this.$store.state.onlineIndicator &&
        this.pledge.paymentMethod.trim().toUpperCase() == "CREDIT CARD" &&
        this.pledge.payshieldApproved == false
      ) {
        LogService.pledgeLog("Attempting to tokenize with payshield", this.pledge);

        this.isLoading = true;
        const response = await new PledgeService().tokenizePledgeCcNumberWithPayshield(this.pledge);
        this.isLoading = false;

        LogService.pledgeLog(`Payshield response: ${JSON.stringify(response)}`, this.pledge);

        if (response.success == false) {
          if (response.message == "Failed to reach PaymentsAPI") {
            // problem reaching the paymentsAPI, allow to continue
            ToastHelper.warn("Error communicating with the payment services, tokenization will happen at a later time");
          } else if (response.message == "Problems communicating with payment provider") {
            // problem reaching the payment provider, allow to continue
            ToastHelper.warn(
              "There were problems communicating with payment provider, tokenization will happen at a later time"
            );
          } else if (response.message == "Credit card rejected by SequenceShift") {
            // payshield rejected the CC, block flow
            this.validationErrors = {
              ...this.validationErrors,
              creditCardNumber: { message: "Credit card rejected" },
            };
          } else if (response.message) {
            // any error came from payment provider, block flow
            this.validationErrors = {
              ...this.validationErrors,
              creditCardNumber: { message: response.message },
            };
          } else {
            // unknown error, allow to continue
            LogService.pledgeLogError(`Unknown error while tokenizing cc`, this.pledge);
          }
        }
      }

      // check for any errors
      if (this.validationErrors && Object.keys(this.validationErrors).length > 0) {
        LogService.error({
          message: "Erros validating callback payment details",
          validationErrors: this.validationErrors,
        });
        return;
      }

      await LogService.pledgeChangesLog(
        `Callback payment details fulfilled, going to confimation page`,
        this.oldPledge,
        this.pledge
      );

      // save pledge
      try {
        this.$store.dispatch("setCurrentPledge", this.pledge);
      } catch {
        console.error("error");
      }

      // redirect to the next page
      this.$router.push("/callback/confirmation");
    },
    previousButton: function () {
      // redirect to contribution details
      this.$router.push("/callback/contributiondetails");
    },
    clearPledge() {
      this.$store.dispatch("clearCurrentPledge", this.pledge);
    },
  },
});
