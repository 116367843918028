
import Vue from "vue";

export default Vue.extend({
  name: "SignatureComponent",
  props: {
    value: String,
    height: Number,
    hideUndo: Boolean,
    errorMessage: Object as () => { message: string },
  },
  data() {
    return {
      data: "teste",
      signaturePath: "",
      lastCommand: "",
      previousPaths: [] as Array<string>,
      isDown: false,
      svg: {} as Element,
      rect: {} as Element,
      line: {} as Element,
      p: {} as Element,
      multiTouchMessageReady: true,
      outerDivHeight: "150px",
      outerDivWidth: "100%",
    };
  },
  beforeMount() {
    // const outerDiv = this.$refs.signatureDiv as HTMLDivElement;
    // console.log("width:", this.$refs.sign);
  },
  mounted() {
    const outerDiv = this.$refs.sign as HTMLDivElement;
    // console.log("element:", outerDiv.parentElement?.parentElement);

    let width = outerDiv.parentElement?.parentElement?.offsetWidth || 0;

    // rouding to the nearest lower integer
    if (width > 100) {
      width = Math.floor(width / 100) * 100;
    } else {
      width = 100;
    }

    // console.log("rounded width:", width);

    this.outerDivWidth = `${width}px`;
    this.outerDivHeight = `${this.height}px`;

    const s = outerDiv.querySelector("#svgImg");
    const r = outerDiv.querySelector("#svgImg rect");
    const l = outerDiv.querySelector("#svgImg line");
    const p = outerDiv.querySelector("#svgImg path");

    if (!s || !r || !l || !p) throw "Signture panel is broken";

    this.svg = s;
    this.rect = r;
    this.line = l;
    this.p = p;

    this.svg.setAttribute("viewBox", `0 0 ${width} ${this.height}`);
    this.rect.setAttribute("width", `${width}`);
    this.rect.setAttribute("height", `${this.height}`);
    this.line.setAttribute("x1", `${width * 0.05}`);
    this.line.setAttribute("x2", `${width * 0.95}`);
    this.line.setAttribute("y1", `${this.height * 0.8}`);
    this.line.setAttribute("y2", `${this.height * 0.8}`);

    this.rect.addEventListener("mousedown", (e) => this.down(e as MouseEvent), false);
    this.rect.addEventListener("mousemove", (e) => this.move(e as MouseEvent), false);
    this.rect.addEventListener("mouseup", (e) => this.up(e as MouseEvent), false);
    this.rect.addEventListener("touchstart", (e) => this.down(e as TouchEvent), false);
    this.rect.addEventListener("touchmove", (e) => this.move(e as TouchEvent), false);
    this.rect.addEventListener("touchend", (e) => this.up(e as TouchEvent), false);
    this.rect.addEventListener("mouseout", (e) => this.up(e as MouseEvent), false);

    if (this.value) this.signaturePath = this.value;
  },
  methods: {
    getCoords(e: MouseEvent | TouchEvent): string {
      const targetRect = (e.target as HTMLElement).getBoundingClientRect();

      let x = 0;
      let y = 0;

      // Mouse or finger position
      if (e instanceof MouseEvent) {
        e = e as MouseEvent;

        x = e.clientX - targetRect.left;
        y = e.clientY - targetRect.top;
      } else if (e instanceof TouchEvent) {
        e = e as TouchEvent;

        // returns empty is multitouch event
        if (e.targetTouches.length > 1 && this.multiTouchMessageReady) {
          this.multiTouchMessageReady = false;
          setTimeout(() => (this.multiTouchMessageReady = true), 5500);

          this.$buefy.toast.open({
            type: "is-warning",
            message:
              "A second touch has been detected on the signature panel, please ensure your signature is captured correctly",
            duration: 5000,
          });
        }

        x = e.targetTouches[0].clientX - targetRect.left;
        y = e.targetTouches[0].clientY - targetRect.top;
      }

      x = Math.round(x * 100) / 100;
      y = Math.round(y * 100) / 100;

      // console.log("point is:", x + "," + y);

      return x + "," + y;
    },
    down(e: MouseEvent | TouchEvent) {
      this.previousPaths.push(this.signaturePath);

      this.lastCommand = "M" + this.getCoords(e);
      this.signaturePath += this.lastCommand + " ";

      this.isDown = true;

      if (e instanceof TouchEvent) e.preventDefault();
    },
    move(e: MouseEvent | TouchEvent) {
      if (this.isDown) {
        this.lastCommand = "L" + this.getCoords(e);
        this.signaturePath += this.lastCommand + " ";
      }

      if (e instanceof TouchEvent) e.preventDefault();
    },
    up(e: MouseEvent | TouchEvent) {
      // detects if last command is a move and removes it from the signature path
      if (this.lastCommand && this.lastCommand.startsWith("M")) {
        var regex = new RegExp(this.lastCommand + "\\s*$", "gim");

        this.signaturePath = this.signaturePath.replace(regex, "");

        this.previousPaths.pop(); // removes the last data from stack
      }

      if (this.isDown) {
        // console.log("up");
        this.emitValue();
      }

      this.isDown = false;

      if (e instanceof TouchEvent) e.preventDefault();
    },
    clearSignature() {
      this.signaturePath = "";
      this.previousPaths = [];

      // console.log("clearSignature");
      this.emitValue();
    },
    undo() {
      this.signaturePath = this.previousPaths.pop() || "";

      // console.log("undo");
      this.emitValue();
      // console.log("previous path length:", this.previousPaths.length);
    },
    logPath() {
      console.log("PATH:", this.signaturePath);
    },
    emitValue() {
      // console.log("emmiting");
      this.$emit("input", this.signaturePath);
    },
  },
});
