import Vue from "vue";
import Vuex from "vuex";
import router from "@/router/index";
import { DialogProgrammatic as Dialog } from "buefy";
import jwtDecode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import { LoginService } from "@/services/loginService";
import { IsOnlineHelper } from "@/helpers/isOnlineHelper";
import { CryptoService } from "@/services/cryptoService";
import { BasePledge } from "@/models/BasePledge";
import { AustraliaPledge } from "@/models/AustraliaPledge";
import { NewZealandPledge } from "@/models/NewZealandPledge";
import { IClient } from "@/interfaces/IClient";
import { ImagePreloader } from "@/helpers/imagePreloader";
import { BaseCallback } from "@/models/Callback/BaseCallback";
import { AustraliaCallback } from "@/models/Callback/AustraliaCallback";
import { NewZealandCallback } from "@/models/Callback/NewZealandCallback";
import { LogSyncHelper } from "@/helpers/logSyncHelper";
import { LogService } from "@/services/logService";

Vue.use(Vuex);

const data = {
  set: function (key: string, value: unknown) {
    if (!key || !value) {
      return;
    }

    if (typeof value === "object") {
      value = JSON.stringify(value);
    }
    window.localStorage.setItem(key, value as string);
  },
  get: function (key: string) {
    const jsonItem = window.localStorage.getItem(key);

    if (!jsonItem) {
      return;
    }

    let value = null;

    // assume it is an object that has been stringified
    if (jsonItem[0] === "{") {
      value = JSON.parse(jsonItem);
    }

    return value;
  },
};

export default new Vuex.Store({
  state: {
    deviceGuid: window.localStorage.getItem("deviceGuid") || null,
    token: window.localStorage.getItem("token") || null,
    selectedClient: data.get("selectedClient") || null,
    selectedLocation: data.get("selectedLocation") || null,
    currentPledge: data.get("currentPledge") || null,
    currentCallback: data.get("currentCallback") || null,
    appBackgroundColor: "#203c8b",
    appBackgroundImageUrl: "",
    onlineIndicator: IsOnlineHelper.getInstance().online,
    rsaPublicKey: window.localStorage.getItem("rsaPublicKey") || null,
    pledgesSyncing: false,
    pledgesToSync: 0,
    callbacksSyncing: false,
    pendingCallbacks: 0,
    logSyncHelper: LogSyncHelper.getInstance().syncingLogs,
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    isTokenExpired: (state) => {
      if (!state.token) return true;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const decoded = jwtDecode<any>(state.token);

      // expire every midnight
      const currentDate = new Date();
      const issued = new Date(decoded.iat * 1000);
      if (currentDate > issued && currentDate.getDate() != issued.getDate()) return true;

      // expire if expiration date
      const expDate = new Date(decoded.exp * 1000);
      if (expDate < new Date()) return true;

      return false;
    },

    selectedClient: (state) => {
      if (state.selectedClient && (state.selectedClient as IClient).cssDetails?.buttonBackgroundColour) {
        const root = document.documentElement;
        root.style.setProperty("--primary", (state.selectedClient as IClient).cssDetails?.buttonBackgroundColour);
      }

      return state.selectedClient;
    },
    isClientSelected: (state) => !!state.selectedClient,
    currentDeviceId: (state) => state.deviceGuid,
    currentUserName: (state) => {
      if (!state.token) return null;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const decoded = jwtDecode<any>(state.token);
      return decoded.nameid;
    },
    currentUserId: (state) => {
      if (!state.token) return null;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const decoded = jwtDecode<any>(state.token);
      return Number(decoded.fundraiserId);
    },
    currentLocation: (state) => state.selectedLocation,
    numberOfPledgesToSync: (state) => state.pledgesToSync,
    isSyncingPledges: (state) => state.pledgesSyncing,
    currentPledge: (state) => {
      if (state.currentPledge) {
        let pledge = state.currentPledge as unknown as BasePledge;

        if (pledge && pledge.country == "Australia") {
          pledge = new AustraliaPledge(0, 0, 0, 0).assignJson(pledge);
        } else if (pledge && pledge.country == "NewZealand") {
          pledge = new NewZealandPledge(0, 0, 0, 0).assignJson(pledge);
        } else {
          throw "Pledge not recognized";
        }

        return pledge;
      } else {
        return null;
      }
    },
    numberOfPendingCallbacks: (state) => state.pendingCallbacks,
    isSyncingCallbacks: (state) => state.callbacksSyncing,
    currentCallback: (state) => {
      if (state.currentPledge && state.currentCallback) {
        const pledge = state.currentPledge as unknown as BasePledge;
        let callback = state.currentCallback as unknown as BaseCallback;

        if (callback && callback.country == "Australia") {
          callback = new AustraliaCallback(pledge as AustraliaPledge).assignJson(callback);
        } else if (callback && callback.country == "NewZealand") {
          callback = new NewZealandCallback(pledge as NewZealandPledge).assignJson(callback);
        } else {
          throw "Callback country not recognized";
        }

        return callback;
      } else {
        return null;
      }
    },
    getBackgroundColor: (state) => state.appBackgroundColor,
    getOnlineIndicator: (state) => state.onlineIndicator,
  },
  mutations: {
    // token
    setToken(state, token) {
      window.localStorage.setItem("token", token);
      state.token = token;
    },
    removeToken(state) {
      window.localStorage.removeItem("token");
      state.token = null;
    },
    // client
    setClient(state, client) {
      data.set("selectedClient", client);
      state.selectedClient = client;
    },
    removeClient(state) {
      window.localStorage.removeItem("selectedClient");
      state.selectedClient = null;
    },
    // location
    setLocation(state, location) {
      data.set("selectedLocation", location);
      state.selectedLocation = location;
    },
    removeLocation(state) {
      window.localStorage.removeItem("selectedLocation");
      state.selectedLocation = null;
    },
    // pledge
    setCurrentPledge(state, pledge) {
      data.set("currentPledge", pledge);
      state.currentPledge = pledge;
    },
    removeCurrentPledge(state) {
      window.localStorage.removeItem("currentPledge");
      state.currentPledge = null;
    },
    setPledgesSyncing(state, syncing) {
      state.pledgesSyncing = syncing;
    },
    setPledgesToSync(state, pledgeNumber) {
      state.pledgesToSync = pledgeNumber;
    },
    // callback
    setCurrentCallback(state, callback) {
      data.set("currentCallback", callback);
      state.currentCallback = callback;
    },
    removeCurrentCallback(state) {
      window.localStorage.removeItem("currentCallback");
      state.currentCallback = null;
    },
    setCallbacksSyncing(state, syncing) {
      state.callbacksSyncing = syncing;
    },
    setPendingCallbacks(state, callbackNumber) {
      state.pendingCallbacks = callbackNumber;
    },
    // app style
    setBackgroundColor(state, color) {
      state.appBackgroundColor = color;
    },
    setBackgroundImageUrl(state, url) {
      state.appBackgroundImageUrl = url;
    },
    // online
    setOnlineIndicator(state, online) {
      state.onlineIndicator = online;
    },
    // rsaPublicKey
    setRsaPublicKey(state, key) {
      if (key) {
        window.localStorage.setItem("rsaPublicKey", key);
        state.rsaPublicKey = key;
      } else {
        window.localStorage.removeItem("rsaPublicKey");
        state.rsaPublicKey = null;
      }
    },
    // device guid
    setDeviceGuid(state) {
      if (!state.deviceGuid) {
        const newDeviceGuid = uuidv4();
        window.localStorage.setItem("deviceGuid", newDeviceGuid);
        state.deviceGuid = newDeviceGuid;
      }
    },
  },
  actions: {
    // token
    login({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        const loginService = new LoginService();
        loginService
          .getToken(credentials.fundraiserId, credentials.pin)
          .then(async (response) => {
            commit("setToken", response);

            const key = await new CryptoService().getPublicKey();
            commit("setRsaPublicKey", key);

            resolve(response);
          })
          .catch((error) => {
            commit("removeToken");
            reject(error);
          });
      });
    },
    logout({ commit }) {
      commit("setBackgroundImageUrl", "");
      commit("removeClient");
      commit("removeToken");
      commit("setRsaPublicKey", null);
      commit("removeLocation");

      const root = document.documentElement;
      root.style.setProperty("--primary", "#203c8b");
    },
    // client
    setCurrentClient({ commit }, payload) {
      // preload client images
      ImagePreloader.preloadBackground(payload.client.clientId);
      ImagePreloader.preloadSmallLogo(payload.client.clientId);

      commit("setClient", payload.client);
    },
    removeCurrentClient({ commit }) {
      commit("removeClient");

      const root = document.documentElement;
      root.style.setProperty("--primary", "#203c8b");
    },
    // location
    setCurrentLocation({ commit }, payload) {
      commit("setLocation", payload.location);
    },
    removeCurrentLocation({ commit }) {
      commit("removeLocation");
    },
    // pledge
    setCurrentPledge({ commit }, pledge) {
      commit("setCurrentPledge", pledge);
    },
    removeCurrentPledge({ commit }, pledge) {
      commit("removeCurrentPledge", pledge);
    },
    clearCurrentPledge({ commit }, pledge) {
      Dialog.confirm({
        title: "Clear pledge",
        message: "Are you sure you want to <b>clear the data</b> of this pledge?",
        confirmText: "Yes",
        cancelText: "No",
        hasIcon: true,
        icon: "delete",
        onConfirm: () => {
          LogService.info(`Pledge cleared by fundraiser. PledgeGuid: ${pledge.pledgeGuid}`);
          // remove current pledge from vuex
          commit("removeCurrentPledge", pledge);
          // redirect to the home page
          router.push("/home");
        },
      });
    },
    setPledgesSyncing({ commit }, isSyncing) {
      commit("setPledgesSyncing", isSyncing);
    },
    setPledgesToSync({ commit }, numberOfPledges) {
      commit("setPledgesToSync", numberOfPledges);
    },
    // callback
    setCurrentCallback({ commit }, callback) {
      commit("setCurrentCallback", callback);
    },
    removeCurrentCallback({ commit }, callback) {
      commit("removeCurrentCallback", callback);
    },
    setCallbacksSyncing({ commit }, isSyncing) {
      commit("setCallbacksSyncing", isSyncing);
    },
    setPendingCallbacks({ commit }, numberOfCallbacks) {
      commit("setPendingCallbacks", numberOfCallbacks);
    },
    // app style
    setBackgroundColor({ commit }, color) {
      commit("setBackgroundColor", color);
    },
    setBackgroundImageUrl({ commit }, url) {
      commit("setBackgroundImageUrl", url);
    },
    // online
    setOnlineIndicator({ commit }, online) {
      commit("setOnlineIndicator", online);
    },
    setDeviceGuid({ commit }) {
      commit("setDeviceGuid");
    },
  },
  modules: {},
});
