
import Vue from "vue";
import { IClient } from "@/interfaces/IClient";
import { ClientStyleBuilder } from "@/models/ClientStyleBuilder";
import { LogService } from "@/services/logService";
import SignatureComponent from "@/components/SignatureComponent.vue";
import CallbackFlowTemplateComponent from "@/components/Callback/CallbackFlowTemplateComponent.vue";
import { BasePledge } from "@/models/BasePledge";
import { AustraliaPledge } from "@/models/AustraliaPledge";
import { NewZealandPledge } from "@/models/NewZealandPledge";
import { BaseCallback } from "@/models/Callback/BaseCallback";
import { AustraliaCallback } from "@/models/Callback/AustraliaCallback";
import { NewZealandCallback } from "@/models/Callback/NewZealandCallback";
import { CallbackService } from "@/services/callbackService";
import { AsYouType } from "libphonenumber-js/min";

export default Vue.extend({
  name: "CreateCallback",
  components: { SignatureComponent, CallbackFlowTemplateComponent },
  props: {},
  data() {
    return {
      hours: [
        { id: 0, name: 1, value: 1 },
        { id: 1, name: 2, value: 2 },
        { id: 2, name: 3, value: 3 },
        { id: 3, name: 4, value: 4 },
        { id: 4, name: 5, value: 5 },
        { id: 5, name: 6, value: 6 },
        { id: 6, name: 7, value: 7 },
        { id: 7, name: 8, value: 8 },
        { id: 8, name: 9, value: 9 },
        { id: 9, name: 10, value: 10 },
        { id: 10, name: 11, value: 11 },
        { id: 11, name: 12, value: 12 },
      ],
      minutes: [
        { id: 0, name: 0, value: 0 },
        { id: 1, name: 15, value: 15 },
        { id: 2, name: 30, value: 30 },
        { id: 3, name: 45, value: 45 },
      ],
      ampm: [
        { id: 0, name: "AM", value: "AM" },
        { id: 1, name: "PM", value: "PM" },
      ],
      accepted: [],
      oldPledge: {} as BasePledge,
      pledge: {} as BasePledge,
      callback: {} as BaseCallback,
      validationErrors: {} as Record<string, { message: string }>,
    };
  },
  computed: {
    online(): boolean {
      return this.$store.state.onlineIndicator;
    },
    client(): IClient {
      return this.$store.getters.selectedClient;
    },
    cssStyle(): ClientStyleBuilder {
      return new ClientStyleBuilder(this.client?.cssDetails);
    },
    username() {
      return this.$store.getters.currentUserName;
    },
    amountCurrencyString() {
      let strAmount = "";

      if (this.callback.country.trim().toLowerCase() == "australia") {
        strAmount = new Intl.NumberFormat("en-AU", { style: "currency", currency: "AUD" }).format(this.callback.amount);
      } else if (this.callback.country.trim().toLowerCase() == "newzealand") {
        strAmount = new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" }).format(this.callback.amount);
      } else {
        strAmount = this.callback.amount?.toString() ?? "";
      }

      return strAmount;
    },
    minAgreedDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
      return today;
    },
    maxAgreedDate() {
      const now = new Date();
      const maxAgreedDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
      const numberOfWeeks = 4;
      maxAgreedDate.setDate(maxAgreedDate.getDate() + numberOfWeeks * 7);
      return maxAgreedDate;
    },
  },
  beforeMount: function () {
    this.pledge = this.$store.getters.currentPledge;
    this.buildCallback();
  },
  methods: {
    buildCallback() {
      if (this.pledge.country == "Australia") {
        this.callback = new AustraliaCallback(this.pledge as AustraliaPledge);
      } else {
        this.callback = new NewZealandCallback(this.pledge as NewZealandPledge);
      }

      if (this.pledge.mobilePhone) {
        this.callback.phoneToCall = this.pledge.mobilePhone;
      } else if (this.pledge.homePhone) {
        this.callback.phoneToCall = this.pledge.homePhone;
      } else if (this.pledge.workPhone) {
        this.callback.phoneToCall = this.pledge.workPhone;
      }
    },
    clearError(field: string) {
      if (this.validationErrors && this.validationErrors[field]) {
        delete this.validationErrors[field];
      }
    },
    clearPledge() {
      this.$store.dispatch("clearCurrentPledge", this.pledge);
    },
    returnToContributionDetails() {
      this.$router.push("/pledge/contributiondetails");
    },
    async createCallback() {
      await LogService.callbackLog("Callback creation process started", this.callback);

      this.callback.dateCreated = new Date();

      // format agreed date and hour
      const agreedHour = this.getHourFormattedIn24(this.callback.agreedHour, this.callback.ampm);
      const agreedMinute = this.callback.agreedMinute || 0;
      if (this.callback.agreedDate) this.callback.agreedDate?.setHours(agreedHour, agreedMinute, 0);

      this.callback.clientEdcName = this.$store.state.selectedClient.clientEdcName;

      this.validationErrors = this.validateCallbackCreation();
      if (this.validationErrors && Object.keys(this.validationErrors).length > 0) return;

      this.saveCallbackInStoreAndLocalStorage();

      // save callback in indexedDB
      const callbackService = new CallbackService();
      callbackService
        .storeCallback(this.callback)
        .then(async (response) => {
          if (response.error) {
            this.$buefy.toast.open({
              type: "is-warning",
              message: response.error,
              duration: 5000,
            });
          }

          await LogService.callbackLog("Callback stored in device. Attempt to sync via API", this.callback);

          if (this.online) {
            callbackService.syncCallback(this.callback.callbackGuid);
          } else {
            LogService.callbackLog(
              `Unable to syng callback ${this.callback.callbackGuid} after submit because the app is offline.`,
              this.callback
            );
          }

          this.$router.push("/callback/callbackcreated");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    validateCallbackCreation() {
      let validationErrors: Record<string, { message: string }>;

      validationErrors = this.callback.validateCallbackCreation();

      // validate I Accept button
      if (this.accepted.length <= 0 || !this.accepted.find((value) => value == "accepted")) {
        validationErrors["accepted"] = { message: "Please accept to proceed" };
      }

      this.logIfAnyErrors(validationErrors);

      return validationErrors;
    },
    logIfAnyErrors(validationErrors: Record<string, { message: string }>) {
      if (validationErrors && Object.keys(validationErrors).length > 0) {
        console.log("Errors:", Object.keys(validationErrors).length);
        console.log(validationErrors);
      }
    },
    saveCallbackInStoreAndLocalStorage() {
      try {
        this.$store.dispatch("setCurrentCallback", this.callback);
      } catch {
        console.error("Error occurred when saving callback in the store and local storage");
      }
    },
    formatPhoneToCall(e: any) {
      this.clearError("phoneToCall");

      const phone = this.callback.phoneToCall?.replace(/[^0-9^+]+/g, "");

      const countryAbv = this.callback.getCountryCode();
      const asYouType = new AsYouType(countryAbv);

      const phoneFormatted = asYouType.input(phone);
      this.callback.phoneToCall = phoneFormatted;
    },
    getHourFormattedIn24(hour: number, ampm: string): number {
      let formattedHour = 0;

      switch (ampm.trim().toLocaleUpperCase()) {
        case "AM":
          if (hour == 12) formattedHour = 0;
          else formattedHour = hour;
          break;

        case "PM":
          if (hour == 12) formattedHour = hour;
          else formattedHour = hour + 12;
          break;
      }

      return formattedHour;
    },
    getSmallSignatureText3() {
      if (this.client.clientId == 427015)
        return `I look forward to supporting ${this.client.clientEdcName} for as many years possible.`;
      else return `I look forward to supporting ${this.client.clientEdcName} for at least 2 years and beyond.`;
    },
  },
});
