import Dexie from "dexie";

import { IClient } from "./interfaces/IClient";
import { ILocation } from "./interfaces/ILocation";
import { BasePledge } from "./models/BasePledge";
import { Bsb } from "./models/Bsb";
import { BaseCallback } from "./models/Callback/BaseCallback";
import { EncryptedPledge } from "./models/EncryptedPledge";
import { Log } from "./models/Log";
import { AustraliaPledge } from "./models/AustraliaPledge";
import { NewZealandPledge } from "./models/NewZealandPledge";

export class Database extends Dexie {
  clients: Dexie.Table<IClient, number>;
  locations: Dexie.Table<ILocation, number>;
  pledges: Dexie.Table<EncryptedPledge, string>;
  logs: Dexie.Table<Log, string>;
  bsbs: Dexie.Table<Bsb, string>;
  callbacks: Dexie.Table<BaseCallback, string>;

  private constructor() {
    super("EdcDatabase");

    this.version(1).stores({
      clients: "id, name, minAge",
      locations: "id, name",
      pledges: "pledgeGuid, edcPledgeId, firstName, lastName, clientId, locationId, fundraiserId",
      logs: "logGuid, type, datetime, fundraiserId",
    });

    this.version(2).stores({
      bsb: "bsb",
    });

    this.version(3).stores({
      logs: "logGuid, type, datetime, fundraiserId, edcLogId",
    });

    this.version(4).stores({
      clients: null,
    });

    this.version(5).stores({
      clients: "clientId, clientName, minAge, nationality",
    });

    this.version(6).stores({
      callbacks: "callbackGuid, edcPledgeId, firstName, lastName, clientId, locationId, fundraiserId",
    });

    this.version(7).stores({
      callbacks: "callbackGuid, edcPledgeId, firstName, lastName, clientId, locationId, fundraiserId, callbackId",
    });

    this.version(8)
      .stores({
        pledges: "pledgeGuid, edcPledgeId, fundraiserId, firstName, lastName, dateCreated",
      })
      .upgrade((tx) => {
        return tx
          .table("pledges")
          .toCollection()
          .modify((pledge: BasePledge) => {
            const rsaPublicKey = window.localStorage.getItem("rsaPublicKey");
            if (!rsaPublicKey) throw new Error("Could not get RSA public key to migrate existing pledges");

            const emptyPledge =
              pledge.country === "Australia" ? new AustraliaPledge(0, 0, 0, 0) : new NewZealandPledge(0, 0, 0, 0);

            const basePledge = emptyPledge.assignJson(pledge);
            const encryptedPledge = basePledge.toEncryptedPledge(rsaPublicKey);

            // Delete all keys
            Object.keys(pledge).forEach((key) => delete pledge[key as keyof BasePledge]);
            Object.assign(pledge, encryptedPledge);
          });
      });

    this.clients = this.table("clients");
    this.locations = this.table("locations");
    this.pledges = this.table("pledges");
    this.logs = this.table("logs");
    this.bsbs = this.table("bsb");
    this.callbacks = this.table("callbacks");
  }

  // singleton
  private static instance: Database;
  static getInstance(): Database {
    if (!Database.instance) Database.instance = new Database();

    return Database.instance;
  }
}
