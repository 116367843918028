import { render, staticRenderFns } from "./DirectDebitStripeBecsComponent.vue?vue&type=template&id=16350f57&scoped=true"
import script from "./DirectDebitStripeBecsComponent.vue?vue&type=script&lang=ts"
export * from "./DirectDebitStripeBecsComponent.vue?vue&type=script&lang=ts"
import style0 from "./DirectDebitStripeBecsComponent.vue?vue&type=style&index=0&id=16350f57&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16350f57",
  null
  
)

export default component.exports