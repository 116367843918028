
import { AustraliaPledge } from "@/models/AustraliaPledge";
import Vue from "vue";

export default Vue.extend({
  name: "DirectDebitAustraliaComponent",
  props: {
    pledge: {
      type: Object as () => AustraliaPledge,
    },
    validationErrors: {
      type: Object as () => Record<string, { message: string }>,
    },
  },
  data() {
    return {
      authorizedSignatorReasons: [
        "Previous known name",
        "Authorised signator of business card",
        "Authorised signator of partners account",
        "Preferred name differs from given name",
      ],
    };
  },
  computed: {
    authorizedSignator(): boolean {
      if (this.pledge.firstName || this.pledge.lastName)
        return this.pledge.accountName == (this.pledge.firstName + " " + this.pledge.lastName).trim();
      return true;
    },
  },
  beforeMount() {
    if (!this.pledge.accountName) {
      this.pledge.accountName = (this.pledge.firstName + " " + this.pledge.lastName).trim();
    }

    if (this.pledge.accountName == (this.pledge.firstName + " " + this.pledge.lastName).trim()) {
      this.pledge.authorizedSignatorReason = null;
    }
  },
  methods: {
    filterOnlyNumbers(e: any) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
    },
    clearError(field: string) {
      if (this.validationErrors && this.validationErrors[field]) {
        delete this.validationErrors[field];
      }
    },
    accountNameChange() {
      this.clearError("accountName");

      if (this.pledge.accountName == (this.pledge.firstName + " " + this.pledge.lastName).trim()) {
        this.pledge.authorizedSignatorReason = null;
      }
    },
  },
});
