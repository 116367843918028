
import { BasePledge } from "@/models/BasePledge";
import Vue from "vue";
import ccvalid from "card-validator";
import { IClient } from "@/interfaces/IClient";
import { LogService } from "@/services/logService";

export default Vue.extend({
  name: "CreditCardComponent",
  // directives: { cleave },
  props: {
    pledge: {
      type: Object as () => BasePledge,
    },
    validationErrors: {
      type: Object as () => Record<string, { message: string }>,
    },
  },
  data() {
    return {
      ccNumber: "",
      ccType: "" as string | undefined,
      // creditCardName: "",
      expireMonths: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      expireMonth: 0,
      expireYears: [] as Array<number>,
      expireYear: 0,
      // authorizedSignator: true,
      authorizedSignatorReasons: [
        "Previous known name",
        "Authorised signator of business card",
        "Authorised signator of partners account",
        "Preferred name differs from given name",
      ],
      acceptedCards: [] as Array<string>,
      masks: {
        creditCard: { creditCard: true },
      },
    };
  },
  computed: {
    acceptsVisa(): boolean {
      return this.acceptedCards.includes("Visa");
    },
    acceptsMasterCard(): boolean {
      return this.acceptedCards.includes("Mastercard");
    },
    acceptsAmericanExpress(): boolean {
      return this.acceptedCards.includes("American Express");
    },
    acceptsDiners(): boolean {
      return this.acceptedCards.includes("Diners Club");
    },
    authorizedSignator(): boolean {
      if (this.pledge.firstName || this.pledge.lastName)
        return this.pledge.creditCardName == this.pledge.firstName + " " + this.pledge.lastName;
      return true;
    },
    ccMaxLenght(): number {
      switch (this.ccType?.toLowerCase()) {
        case "American Express":
          return 15 + 2;
        case "Visa":
        case "Mastercard":
          return 16 + 3;
        case "Maestro":
        case "Diners Club":
        case "not-accepted":
        case "invalid":
        default:
          return 19;
      }
    },
    // formattedCcNumber: {
    //   get: function (): string {
    //     return this.ccNumber;
    //   },
    //   set: function (newValue: string) {
    //     this.ccNumber = newValue;
    //   },
    // },
  },
  beforeMount() {
    const currYear = new Date().getFullYear();
    this.expireYears.push(currYear);
    for (let i = currYear + 1; i <= currYear + 20; i++) this.expireYears.push(i);

    const currentClient: IClient = this.$store.state.selectedClient;
    this.acceptedCards = currentClient.creditCardTypes;

    if (!this.pledge.creditCardName) {
      this.pledge.creditCardName = this.pledge.firstName + " " + this.pledge.lastName;
    }

    if (this.pledge.creditCardName == this.pledge.firstName + " " + this.pledge.lastName) {
      this.pledge.authorizedSignatorReason = null;
    }
    if (this.pledge.creditCardExpiryDate) {
      this.expireMonth = Number(this.pledge.creditCardExpiryDate.slice(0, 2));
      this.expireYear = Number("20" + this.pledge.creditCardExpiryDate.slice(2));
    }

    if (this.pledge.creditCardMask) {
      this.ccNumber = this.formatCcNumber(this.pledge.creditCardMask, this.pledge.creditCardType);
      this.ccType = this.pledge.creditCardType;
    }
  },
  methods: {
    clearError(field: string) {
      if (this.validationErrors && this.validationErrors[field]) {
        delete this.validationErrors[field];
      }
    },
    creditCardNumberChanged() {
      try {
        LogService.info("Credit card number changed");

        this.clearError("creditCardNumber");

        // if the credit card is being changed, clear all current CC details
        this.pledge.creditCardType = "";
        this.pledge.creditCardNumber = "";
        this.pledge.creditCardMask = "";
        this.pledge.payshieldRequestId = "";
        this.pledge.payshieldApproved = false;
        this.pledge.payshieldResponse = "";

        // get only the cc numbers
        const ccNumberPlain = this.ccNumber.replace(/\D/g, "");

        // not recognized
        if (!ccNumberPlain) {
          this.ccType = "";
          return;
        }

        const validation = ccvalid.number(ccNumberPlain);

        // if it is not potentially valid, it is invalid
        if (!validation.isPotentiallyValid) {
          LogService.info("CC is invalid");
          this.ccType = "invalid";
          return;
        }

        const prettyType = validation.card?.niceType;

        if (prettyType) {
          if (!this.acceptedCards.includes(prettyType)) {
            this.ccType = "not-accepted";
          } else {
            this.ccType = prettyType;
            this.pledge.creditCardNumber = ccNumberPlain;
            this.pledge.creditCardType = prettyType;

            this.ccNumber = this.formatCcNumber(this.ccNumber, prettyType);
          }
        }
      } catch (error) {
        LogService.error({ message: "Error formatting cc numbers", pledge: this.pledge, error: error });
      }
    },
    formatCcNumber(cc: string, type: string): string {
      cc = cc.replace(/\W/g, ""); // remove every dash or non word

      let match: RegExpMatchArray | null = null;
      switch (type) {
        case "American Express":
          match = cc.match(new RegExp(/([0-9]{4})([0-9]{6})?([0-9]*)?/));
          break;
        case "Visa":
        case "Mastercard":
        case "Maestro":
        case "Diners Club":
        case "not-accepted":
        case "invalid":
        default:
          match = cc.match(new RegExp(/([0-9]{4}|X{4})([0-9]{4}|X{4})?([0-9]{4}|X{4})?([0-9]*|X*)?/));
          break;
      }

      let formattedCcNumber = "";

      if (match != null) {
        for (let i = 1; i <= match.length; i++) {
          if (match[i]) {
            if (formattedCcNumber) formattedCcNumber += "-" + match[i];
            else formattedCcNumber += match[i];
          }
        }
      } else {
        formattedCcNumber = this.ccNumber;
      }

      return formattedCcNumber;
    },
    creditCardNameChanged() {
      this.clearError("creditCardName");

      if (this.pledge.creditCardName == this.pledge.firstName + " " + this.pledge.lastName) {
        this.pledge.authorizedSignatorReason = null;
      }
    },
    buildExpirationDate() {
      this.clearError("creditCardExpiryDate");

      let expireDate = "";

      if (this.expireMonth && this.expireYear) {
        let month = this.expireMonth.toString();
        let year = this.expireYear.toString();

        if (month.length < 2) month = "0" + month;

        if (year.length < 4) {
          var missingZeros = Math.abs(4 - year.length);
          for (let i = 0; i < missingZeros; i++) {
            year = "0" + year;
          }
        }

        expireDate = month + year.slice(2);
      }

      this.pledge.creditCardExpiryDate = expireDate;
    },
  },
});
