import { BaseService } from "./baseService";
import { LogService } from "./logService";

export class LoginService extends BaseService {
  constructor() {
    super("token");
  }

  async getToken(id: number, pin: number): Promise<string> {
    try {
      LogService.info(`Fundraiser attempting to login with id: ${id} and pin: ${pin}`);

      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.post(`?fundraiserId=${id}&pin=${pin}`);

      LogService.info(`Login response for fundraiser with id: ${id} and pin: ${pin}. Response: ${response}`);

      return response.data;
    } catch (error: any) {
      LogService.error({
        message: `Error logging in with  ${id} and pin: ${pin}. Error: ${error.response.data}`,
        error: error,
      });

      if (error.response) {
        throw { data: error.response.data, status: error.response.status };
      } else {
        throw { data: error, status: error.status };
      }
    }
  }
}
