
import Vue from "vue";
import { BasePledge } from "@/models/BasePledge";
import PledgeFlowTemplateComponent from "@/components/PledgeFlowTemplateComponent.vue";
import DateSplitCompoment from "@/components/DateSplitCompoment.vue";
import UdfsComponent from "@/components/UdfsComponent.vue";
import { IClient } from "@/interfaces/IClient";
import { ClientStyleBuilder } from "@/models/ClientStyleBuilder";
import { LogService } from "@/services/logService";

export default Vue.extend({
  name: "PersonalDetails",
  components: { PledgeFlowTemplateComponent, DateSplitCompoment, UdfsComponent },
  props: {},
  data() {
    return {
      oldPledge: {} as BasePledge,
      pledge: {} as BasePledge,
      types: [
        { id: 0, name: "Individual", value: "Individual" },
        { id: 1, name: "Company", value: "Company" },
      ],
      titles: [
        { id: 0, name: "Dr", value: "Dr" },
        { id: 1, name: "Mr", value: "Mr" },
        { id: 2, name: "Mrs", value: "Mrs" },
        { id: 3, name: "Ms", value: "Ms" },
        { id: 4, name: "Miss", value: "Miss" },
        { id: 5, name: "Mx", value: "Mx" },
        { id: 6, name: "Prof", value: "Professor" },
        { id: 7, name: "Reverend", value: "Rev" },
      ],
      genders: [
        { id: 0, name: "Male", value: "Male" },
        { id: 1, name: "Female", value: "Female" },
        { id: 2, name: "Other", value: "Unknown" },
      ],
      occupations: [] as Array<{ id: number; name: string; value: string }>,
      client: {} as IClient,
      validationErrors: {} as Record<string, { message: string }>,
    };
  },
  computed: {
    cssStyle(): ClientStyleBuilder {
      return new ClientStyleBuilder(this.client?.cssDetails);
    },
  },
  beforeMount: function () {
    const currentPledge = this.$store.getters.currentPledge;
    if (currentPledge) {
      this.pledge = currentPledge;
    }

    this.oldPledge = Object.assign({}, this.pledge);

    console.log(this.oldPledge);

    this.client = this.$store.getters.selectedClient;

    this.occupations = this.client.occupations.map((el, index) => {
      return { id: index, name: el, value: el };
    });

    if (!this.pledge.type) {
      this.pledge.type = "Individual";
    }

    // console.log(this.pledge);
  },
  methods: {
    clearError(field: string) {
      if (this.validationErrors && this.validationErrors[field]) {
        delete this.validationErrors[field];
      }
    },
    titleSelected() {
      this.clearError("title");

      switch (this.pledge.title) {
        case "Mr":
          this.pledge.gender = "Male";
          break;
        case "Mrs":
        case "Ms":
        case "Miss":
          this.pledge.gender = "Female";
          break;
        case "Mx":
          this.pledge.gender = "Unknown";
          break;
      }

      console.log("gender is:", this.pledge.gender);
    },
    previousButton: function () {
      this.$router.push("/home");
    },
    async nextButton() {
      const minAge = this.client.minAge;
      const maxAge = 100;

      // validate pledge personal details
      this.validationErrors = this.pledge.validatePersonalDetails(minAge, maxAge);

      // validate udfs
      const udfsValidationErrors = this.pledge.validateUdfs("personal-details");
      this.validationErrors = { ...this.validationErrors, ...udfsValidationErrors };

      if (this.validationErrors && Object.keys(this.validationErrors).length > 0) {
        console.log("erros:", Object.keys(this.validationErrors).length);
        console.log(this.validationErrors);
        return;
      }

      this.pledge.firstName = this.pledge.firstName.trim();
      this.pledge.lastName = this.pledge.lastName.trim();

      await LogService.pledgeChangesLog(
        `Pledge personal details fulfilled, going to contact details`,
        this.oldPledge,
        this.pledge
      );

      // save pledge
      try {
        this.$store.dispatch("setCurrentPledge", this.pledge);
      } catch {
        console.error("error");
      }

      // redirect to the next page
      this.$router.push("/pledge/contactdetails");
    },
    clearPledge() {
      this.$store.dispatch("clearCurrentPledge", this.pledge);
    },
  },
});
