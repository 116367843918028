import { BankDetailsService } from "@/services/bankDetailsService";
import { BasePledge } from "./BasePledge";

export class NewZealandPledge extends BasePledge {
  isPoBox: boolean;
  poBox: string;
  floor: string;
  unit: string;
  streetNumber: string;
  street: string;
  suburb: string;
  postcode: string;
  townCity: string;
  nzPostDpid: string;
  nzPostAddressJson: string;
  bankAndBranch: string;
  accountNumber: string;
  accountSuffix: string;
  accountName: string;

  constructor(fundraiserId: number, clientId: number, locationId: number, bookingId: number) {
    super(fundraiserId, clientId, locationId, bookingId);

    this.country = "NewZealand";
    this.isPoBox = false;
    this.poBox = "";
    this.floor = "";
    this.unit = "";
    this.streetNumber = "";
    this.street = "";
    this.suburb = "";
    this.postcode = "";
    this.townCity = "";
    this.nzPostDpid = "";
    this.nzPostAddressJson = "";
    this.bankAndBranch = "";
    this.accountNumber = "";
    this.accountSuffix = "";
    this.accountName = "";
  }

  get formattedStreet() {
    let result = "";

    if (this.isPoBox) {
      result = this.poBox;
    } else {
      if (this.unit && this.streetNumber) {
        result = `${this.unit}/${this.streetNumber} ${this.street}`;
      } else if (this.streetNumber) {
        result = `${this.streetNumber} ${this.street}`;
      } else {
        result = this.street;
      }
    }

    return result;
  }

  clearDirectDebitDetails(): void {
    this.bankAndBranch = "";
    this.accountNumber = "";
    this.accountSuffix = "";
    this.accountName = "";
  }

  /**
   * Validate New Zealand pledge contact details.
   * @returns True if pledge contact details are valid.
   */
  validateContactDetails(): Record<string, { message: string }> {
    let validationErros = {} as Record<string, { message: string }>;

    if (process.env.NODE_ENV == "development" && (window as any).klapaucius) {
      console.log("Skipping validation u cheater:", (window as any).klapaucius);
      return validationErros;
    }

    //pobox
    if (this.isPoBox && !this.poBox) validationErros["poBox"] = { message: "PO Box required" };
    else if (this.isPoBox && this.poBox.length > 250)
      validationErros["poBox"] = { message: "Max PO Box length is 500 characters" };

    //unit
    if (!this.isPoBox && this.unit) {
      if (this.unit.length > 10) {
        validationErros["unit"] = { message: "Max unit length is 10 characters" };
      }
    }

    //streetNumber
    if (!this.isPoBox && !this.streetNumber) validationErros["streetNumber"] = { message: "Street Number required" };
    else if (!this.isPoBox && this.streetNumber.length > 10)
      validationErros["streetNumber"] = { message: "Max street number lenght is 10 characters" };

    //street
    if (!this.isPoBox && !this.street) validationErros["street"] = { message: "Street required" };
    else if (!this.isPoBox && this.street.length > 250)
      validationErros["street"] = { message: "Max street lenght is 250 characters" };

    //suburb -- I think NZ doesn't always have a suburb
    // if (!this.suburb) validationErros["suburb"] = { message: "Suburb required" };
    // else if (this.suburb.length > 100) validationErros["suburb"] = { message: "Max suburb lenght is 100 characters" };

    //postcode
    if (!this.postcode) validationErros["postcode"] = { message: "Postcode required" };
    // if (this.postcode < 1000 || this.postcode > 9999) return false; // -- think better about the postcode

    //state
    if (!this.townCity) validationErros["townCity"] = { message: "Town/City required" };
    else if (this.townCity.length > 40)
      validationErros["townCity"] = { message: "Max Town/City lenght is 40 characters" };

    //country
    const countryValidationErros = this.validateCountry();

    validationErros = { ...validationErros, ...countryValidationErros };

    //phone numbers
    const phoneValidationErros = this.validatePhoneNumbers();

    validationErros = { ...validationErros, ...phoneValidationErros };

    //email
    const emailValidationErros = this.validateEmail();

    validationErros = { ...validationErros, ...emailValidationErros };

    return validationErros;
  }

  /**
   * Validate New Zealand direct debit details.
   * @returns True if pledge direct debit details are valid.
   */
  async validateDirectDebitDetails(bankService: BankDetailsService): Promise<Record<string, { message: string }>> {
    const validationErros = {} as Record<string, { message: string }>;

    if (process.env.NODE_ENV == "development" && (window as any).klapaucius) {
      console.log("Skipping validation u cheater:", (window as any).klapaucius);
      return validationErros;
    }

    //bank and branch
    if (!this.bankAndBranch) validationErros["bankAndBranch"] = { message: "Bank and branch required" };
    else if (this.bankAndBranch && this.bankAndBranch.length != 6)
      validationErros["bankAndBranch"] = { message: "Bank and Branch must have 6 digits" };

    //account number
    if (!this.accountNumber) validationErros["accountNumber"] = { message: "Account number required" };
    else if (this.accountNumber && this.accountNumber.length != 7)
      validationErros["accountNumber"] = { message: "Account number must have is 7 digits" };

    //account suffix
    if (!this.accountSuffix) validationErros["accountSuffix"] = { message: "Account suffix required" };
    else if (this.accountSuffix && (this.accountSuffix.length < 2 || this.accountSuffix.length > 3))
      validationErros["accountSuffix"] = { message: "Account suffix must have 2 or 3 digits" };

    //account name
    if (!this.accountName) validationErros["accountName"] = { message: "Account name required" };
    else if (this.accountName && this.accountName.length > 50)
      validationErros["accountName"] = { message: "Max account name lenght is 50 characters" };

    // authorized signator reason
    if (
      this.accountName &&
      this.accountName != (this.firstName + " " + this.lastName).trim() &&
      !this.authorizedSignatorReason
    )
      validationErros["authorizedSignatorReason"] = { message: "Reason required" };

    // validate using pluto api. If not empty and not valid, give an error message
    const validationResult = await bankService.validateNewZealandBankAccount(
      this.bankAndBranch,
      this.accountNumber,
      this.accountSuffix
    );
    if (validationResult && validationResult.trim().toUpperCase() != "VALID") {
      validationErros["bankAndBranch"] = { message: validationResult };
    }

    return validationErros;
  }

  clearAddressDetails() {
    this.poBox = "";
    this.floor = "";
    this.unit = "";
    this.streetNumber = "";
    this.street = "";
    this.suburb = "";
    this.postcode = "";
    this.townCity = "";
    this.nzPostDpid = "";
    this.isPoBox = false;
  }
}
