import { BaseService } from "./baseService";
import { LogService } from "./logService";

export class ComplianceService extends BaseService {
  constructor() {
    super("compliance");
  }

  async getFundraiserCompliances(): Promise<[any]> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.get(`/fundraisercompliances`);

      console.log(response.data);

      return response.data;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async getFundraiserClientsMissingCompliance(): Promise<[any]> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.get(`/clientsmissingcompliance`);

      console.log(response.data);

      return response.data;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async getClientDocuments(clientId: number): Promise<[any]> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.get(`/clientcompliancedocuments?clientId=${clientId}`);

      console.log(response.data);

      return response.data;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async getClientDocument(documentId: number, documentName: string): Promise<any> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.get(`/clientcompliancedocument?documentId=${documentId}`, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = documentName;
      link.click();
      URL.revokeObjectURL(link.href);

      console.log(response);

      return response;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async getTextQuestionsWithAnswers(clientId: number): Promise<[any]> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.get(`/textquestionswithanswers?clientId=${clientId}`);

      console.log(response.data);

      return response.data;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async saveTextAnswers(questions: Array<any>): Promise<any> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.post(`/savetextanswers`, questions);

      console.log(response.data);

      return response.data;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async getCheckboxQuestionsWithAnswers(clientId: number): Promise<[any]> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.get(`/checkboxquestionswithanswers?clientId=${clientId}`);

      console.log(response.data);

      return response.data;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async saveCheckboxAnswers(questions: Array<any>): Promise<any> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.post(`/savecheckboxanswers`, questions);

      console.log(response.data);

      return response.data;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async getAdminQuestionsWithAnswers(): Promise<[any]> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.get(`/adminquestionswithanswers`);

      console.log(response.data);

      return response.data;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }

  async saveAdminAnswers(
    answers: Array<any>,
    clientId: number,
    agreeToTerms: boolean,
    signature: string
  ): Promise<any> {
    try {
      const axiosInstance = await this.getAxiosInstance();

      const response = await axiosInstance.post(`/savesadminanswers`, {
        answers,
        agreeToTerms,
        signature,
        clientId,
      });

      console.log(response.data);

      return response.data;
    } catch (error: any) {
      LogService.error(error);
      throw { data: error.response.data, status: error.response.status };
    }
  }
}
