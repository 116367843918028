
import Vue from "vue";

export default Vue.extend({
  name: "Under35Widget",
  props: {
    pledgesToReport: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    pledgesToReport: function () {
      this.getPledgesData();
    },
  },
  data() {
    return {
      total: 0,
    };
  },
  mounted() {
    this.getPledgesData();
  },
  methods: {
    getPledgesData() {
      this.total = 0;

      for (var pledge of this.pledgesToReport) {
        if ((pledge as any).ageAtSignUp < 35) {
          this.total++;
        }
      }
    },
  },
});
