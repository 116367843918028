
import { IClient } from "@/interfaces/IClient";
import { AustraliaPledge } from "@/models/AustraliaPledge";
import { BasePledge } from "@/models/BasePledge";
import { NewZealandPledge } from "@/models/NewZealandPledge";
import Vue from "vue";
import VersionComponent from "@/components/VersionComponent.vue";
import { PledgeService } from "@/services/pledgeService";
import { ILocation } from "@/interfaces/ILocation";
import { ClientStyleBuilder } from "@/models/ClientStyleBuilder";
import { LogService } from "@/services/logService";
import { GeolocationHelper } from "@/helpers/geolocationHelper";
import { ImagePreloader } from "@/helpers/imagePreloader";
import { ReportService } from "@/services/reportService";
import { IKpis } from "@/interfaces/IKpis";
// import modal from "@/components/ClientSelection.vue";

export default Vue.extend({
  name: "Home",
  components: { VersionComponent },
  data() {
    return {
      open: false,
      currentClient: {} as IClient,
      currentLocation: {} as ILocation,
      fundraiserId: 0,
      cssStyle: {} as ClientStyleBuilder,
      smallLogoUrl: "",
      cornucopiaLogo: "",
      kpis: undefined as IKpis | null | undefined,
    };
  },
  computed: {
    fundraiserName(): string {
      return this.$store.getters.currentUserName;
    },
    selectedClient(): IClient {
      return this.$store.getters.selectedClient;
    },
  },
  beforeMount() {
    // clear current pledge and callback if any
    this.$store.dispatch("removeCurrentPledge");
    this.$store.dispatch("removeCurrentCallback");

    this.fundraiserId = this.$store.getters.currentUserId;

    if (this.$store.state.selectedClient) this.currentClient = this.$store.state.selectedClient;
    if (this.$store.state.selectedLocation) this.currentLocation = this.$store.state.selectedLocation;

    if (this.currentClient) this.cssStyle = new ClientStyleBuilder(this.currentClient.cssDetails);

    this.cornucopiaLogo = ImagePreloader.getCornucopiaLogo();

    new PledgeService().updateNumberOfPledgesToSync();

    if (this.currentClient.clientId) {
      this.smallLogoUrl = `https://base.cornucopia.com.au/assets/clients/${this.currentClient.clientId}/edc/small_logo.png`;
      this.$store.dispatch(
        "setBackgroundImageUrl",
        `https://base.cornucopia.com.au/assets/clients/${this.currentClient.clientId}/edc/background_picture.jpg`
      );
    }
  },
  mounted() {
    const reportService = new ReportService();
    reportService.getKpis().then((kpi) => (this.kpis = kpi));
  },
  methods: {
    logout() {
      this.$buefy.dialog.confirm({
        title: "Logout from account",
        message: "Are you sure you want to <b>logout</b> from Catalyst?",
        confirmText: "Yes",
        cancelText: "No",
        hasIcon: true,
        icon: "logout",
        onConfirm: () => {
          this.$store.dispatch("logout");
          this.$router.push("/");
        },
      });
    },
    async newPledge() {
      let newPledge: BasePledge;

      if (this.currentClient && this.currentClient.clientId) {
        // if (this.currentClient.clientId == 426999) {
        //   newPledge = new AustraliaPledge(
        //     this.fundraiserId,
        //     this.currentClient.clientId,
        //     this.currentLocation.locationId
        //   );
        // } else {
        //   newPledge = new NewZealandPledge(
        //     this.fundraiserId,
        //     this.currentClient.clientId,
        //     this.currentLocation.locationId
        //   );
        // }

        if (this.currentClient.nationality == "Australia") {
          newPledge = new AustraliaPledge(
            this.fundraiserId,
            this.currentClient.clientId,
            this.currentLocation.locationId,
            this.currentLocation.bookingId
          );
        } else {
          newPledge = new NewZealandPledge(
            this.fundraiserId,
            this.currentClient.clientId,
            this.currentLocation.locationId,
            this.currentLocation.bookingId
          );
        }

        // copy the client's UDFs to the pledge
        if (this.currentClient.udfs && this.currentClient.udfs.length > 0) {
          this.currentClient.udfs.forEach((udf) => {
            newPledge.pledgeUdfs.push(Object.assign({}, udf));
          });
        }

        // trying to get geolocation
        GeolocationHelper.getInstance()
          .getGeolocation()
          .then((geo) => {
            newPledge.longitude = geo.coords.longitude;
            newPledge.latitude = geo.coords.latitude;
          })
          .catch((error) => {
            console.error("Unable to get geolocation for pledge: ", error);
          });

        await LogService.pledgeChangesLog(`New pledge created`, null, newPledge);

        this.$store.dispatch("setCurrentPledge", newPledge);
      }

      this.$router.push("/pledge/personaldetails");
    },
  },
});
