
import Vue from "vue";
import { ClientStyleBuilder } from "@/models/ClientStyleBuilder";
import { IClient } from "@/interfaces/IClient";
import { FundraiserService } from "@/services/fundraiserService";
import { ToastHelper } from "@/helpers/toastHelper";
import { PledgeService } from "@/services/pledgeService";

export default Vue.extend({
  components: {},
  name: "RecoverPledge",
  computed: {
    cssStyle(): ClientStyleBuilder {
      const client = this.$store.getters.selectedClient as IClient;
      return new ClientStyleBuilder(client?.cssDetails);
    },
    fundraiserId(): number {
      return this.$store.getters.currentUserId;
    },
  },
  data() {
    return {
      file: null as any,
    };
  },
  methods: {
    recoverPledge() {
      new PledgeService()
        .recover(this.file)
        .then((response) => {
          ToastHelper.success(`Pledge recovered. EdcPledgeId: ${response}`);
        })
        .catch((error) => {
          ToastHelper.error(`Error while recovering the pledge. Message: ${error}.`);
        })
        .finally(() => {
          this.file = null;
        });
    },
    logout() {
      this.$buefy.dialog.confirm({
        title: "Logout from account",
        message: "Are you sure you want to <b>logout</b> from Catalyst?",
        confirmText: "Yes",
        cancelText: "No",
        hasIcon: true,
        icon: "logout",
        onConfirm: () => {
          this.$store.dispatch("logout");
          this.$router.push("/");
        },
      });
    },
    goHome() {
      this.$router.push("/home");
    },
  },
});
