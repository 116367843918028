
import { IClient } from "@/interfaces/IClient";
import { ILocation } from "@/interfaces/ILocation";
import { LocationsService } from "@/services/locationsService";
import { FundraiserService } from "@/services/fundraiserService";
import Vue from "vue";
import { ClientService } from "@/services/clientService";
import VersionComponent from "@/components/VersionComponent.vue";
import { ToastHelper } from "@/helpers/toastHelper";

export default Vue.extend({
  name: "Setup",
  components: { VersionComponent },
  props: {},
  data() {
    return {
      year: new Date().getFullYear(),
      // fundraiser info
      campaignPartnerName: "",
      companyGroupName: "",
      fundraiserInfoLoading: true,
      // locations
      locations: [] as Array<ILocation>,
      selectedLocation: {} as ILocation,
      locationsLoading: true,
      locationSelected: false,
      // cp region
      campaignPartnerRegions: [],
      selectedCampaignPartnerRegion: undefined,
      // clients
      clients: [] as IClient[],
      selectedClient: {} as IClient | undefined,
    };
  },
  computed: {
    fundraiserName: function (): string {
      return this.$store.getters.currentUserName;
    },
  },
  async beforeMount() {
    this.$store.dispatch("setBackgroundImageUrl", "");
    this.$store.dispatch("removeCurrentClient");
    this.$store.dispatch("removeCurrentLocation");

    // I need the clients pre-loaded here
    this.clients = await new ClientService().getAvailableClients();

    console.log(this.clients);

    this.campaignPartnerRegions = await new FundraiserService().getCampaignPartnerRegions();

    console.log(this.campaignPartnerRegions);

    new FundraiserService()
      .getCampaignPartner()
      .then((result) => {
        this.campaignPartnerName = result.campaignPartner;
        this.companyGroupName = result.companyGroup;
      })
      .finally(() => {
        this.fundraiserInfoLoading = false;
      });

    new LocationsService()
      .getAvailableLocations()
      .then((result) => {
        this.locations = result;

        for (const loc of this.locations) {
          const clientFound = this.clients.find((cli) => cli.clientId == loc.clientId);

          if (clientFound) {
            loc.clientName = clientFound.clientEdcName;
          }
        }

        if (!this.locations || this.locations.length == 0) {
          ToastHelper.warn("No locations found, contact your team leader");
        }
      })
      .catch(() => {
        ToastHelper.error(
          "Error fetching bookings. Please refresh the page to try again. If this problem persists, contact your Team Leader for support"
        );
      })
      .finally(() => {
        this.locationsLoading = false;
      });
  },
  methods: {
    async locationSelection() {
      this.locationSelected = false;

      // check if location is selected
      if (!this.selectedLocation || !this.selectedLocation.locationId) return;

      this.selectedClient = this.clients.find((cli) => cli.clientId == this.selectedLocation.clientId);

      this.selectedCampaignPartnerRegion = this.campaignPartnerRegions.find(
        (cpr: any) => cpr.campaignPartnerRegionPk == this.selectedLocation.campaignPartnerRegionId
      );

      // console.log(this.selectedCampaignPartnerRegion);

      if (!this.selectedCampaignPartnerRegion) {
        ToastHelper.error("Unable to identify Campaign Partner region, please escalate to your Team Leader");
        this.selectedLocation = {} as ILocation;
        return;
      }

      // check if client was found
      if (!this.selectedClient) {
        ToastHelper.error(
          "Unable to allocate charity client, check if the compliance is fulfilled. If problem persists, please escalate to your Team Leader"
        );
        this.selectedLocation = {} as ILocation;
        return;
      }

      this.locationSelected = true;
    },
    proceed() {
      if (!this.selectedClient || !this.selectedClient.clientId) {
        alert("Select a client");
        return;
      }

      const client = this.selectedClient;
      const location = this.selectedLocation;

      this.$store
        .dispatch("setCurrentLocation", { location })
        .then(() => {
          this.$store
            .dispatch("setCurrentClient", { client })
            .then(() => {
              // redirect to the home page
              this.$router.push("/home");
            })
            .catch(() => alert("client selection failed"));
        })
        .catch(() => alert("location selection failed"));
    },
    cancel() {
      this.$router.back();
    },
    logout() {
      this.$buefy.dialog.confirm({
        title: "Logout from account",
        message: "Are you sure you want to <b>logout</b> from Catalyst?",
        confirmText: "Yes",
        cancelText: "No",
        hasIcon: true,
        icon: "logout",
        onConfirm: () => {
          this.$store.dispatch("logout");
          this.$router.push("/");
        },
      });
    },
  },
});
