
import Vue from "vue";
import { BasePledge } from "@/models/BasePledge";
import AddressAustraliaComponent from "@/components/Address/AddressAustraliaComponent.vue";
import AddressNewZealandComponent from "@/components/Address/AddressNewZealandComponent.vue";
import PledgeFlowTemplateComponent from "@/components/PledgeFlowTemplateComponent.vue";
import UdfsComponent from "@/components/UdfsComponent.vue";
import { AsYouType } from "libphonenumber-js";
import { ClientStyleBuilder } from "@/models/ClientStyleBuilder";
import { IClient } from "@/interfaces/IClient";
import { LogService } from "@/services/logService";
import { PledgeService } from "@/services/pledgeService";

export default Vue.extend({
  name: "ContactDetails",
  components: { AddressAustraliaComponent, AddressNewZealandComponent, PledgeFlowTemplateComponent, UdfsComponent },
  data() {
    return {
      oldPledge: {} as BasePledge,
      pledge: {} as BasePledge,
      isAus: true,
      verifyingSms: false,
      validationErrors: {} as Record<string, { message: string }>,
      isLoading: false,
    };
  },
  computed: {
    cssStyle(): ClientStyleBuilder {
      const client = this.$store.getters.selectedClient as IClient;
      return new ClientStyleBuilder(client?.cssDetails);
    },
    showSmsVerificationButton(): boolean {
      return this.$store.state.onlineIndicator;
    },
    online(): boolean {
      return this.$store.state.onlineIndicator;
    },
    mobilePhoneMaxLength() {
      if (this.isAus) return 12;
      else return 13;
    },
  },
  beforeMount: function () {
    const currentPledge = this.$store.getters.currentPledge;
    if (currentPledge) {
      this.pledge = currentPledge;
    }

    this.oldPledge = Object.assign({}, this.pledge);

    this.isAus = this.pledge.country == "Australia";

    this.validationErrors = {} as Record<string, { message: string }>;
  },
  methods: {
    clearError(field: string) {
      if (this.validationErrors && this.validationErrors[field]) {
        delete this.validationErrors[field];
      }
    },
    async nextButton() {
      // validate pledge contact details
      this.validationErrors = this.pledge.validateContactDetails();

      // validate email using ZeroBounce
      if (this.online && this.pledge.email) {
        this.isLoading = true;

        try {
          const data = await new PledgeService().validateEmail(this.pledge);

          console.log(data);

          if (data.emailStatus.trim().toUpperCase() == "INVALID") {
            this.validationErrors = { ...this.validationErrors, email: { message: "Email not valid" } };
          }
        } finally {
          this.isLoading = false;
        }
      }

      // validate udfs
      const udfsValidationErrors = this.pledge.validateUdfs("contact-details");
      this.validationErrors = { ...this.validationErrors, ...udfsValidationErrors };

      console.log(this.validationErrors);

      if (this.validationErrors && Object.keys(this.validationErrors).length > 0) {
        console.log("errors:", Object.keys(this.validationErrors).length);
        return;
      }

      await LogService.pledgeChangesLog(
        `Contact details fulfilled, going to contribution details`,
        this.oldPledge,
        this.pledge
      );

      // save pledge
      try {
        this.$store.dispatch("setCurrentPledge", this.pledge);
      } catch {
        console.error("error");
      }

      // redirect to the next page
      this.$router.push("/pledge/contributiondetails");
    },
    clearPledge() {
      this.$store.dispatch("clearCurrentPledge", this.pledge);
    },
    previousButton: function () {
      // redirect to personal details page
      this.$router.push("/pledge/personaldetails");
    },
    formatMobilePhone(e: any) {
      this.clearError("mobilePhone");

      const phone = this.pledge.mobilePhone?.replace(/[^0-9^+]+/g, "");

      const countryAbv = this.pledge.countryCode();
      const asYouType = new AsYouType(countryAbv);

      const phoneFormatted = asYouType.input(phone);
      this.pledge.mobilePhone = phoneFormatted;

      // clear verification
      this.pledge.verificationSmsResponse = "";
    },
    formatHomePhone() {
      this.clearError("homePhone");

      const phone = this.pledge.homePhone?.replace(/[^0-9^+]+/g, "");

      const countryAbv = this.pledge.country == "Australia" ? "AU" : "NZ";
      const asYouType = new AsYouType(countryAbv);

      const phoneFormatted = asYouType.input(phone);
      this.pledge.homePhone = phoneFormatted;
    },
    formatWorkPhone() {
      this.clearError("workPhone");

      const phone = this.pledge.workPhone?.replace(/[^0-9^+]+/g, "");

      const countryAbv = this.pledge.country == "Australia" ? "AU" : "NZ";
      const asYouType = new AsYouType(countryAbv);

      const phoneFormatted = asYouType.input(phone);
      this.pledge.workPhone = phoneFormatted;
    },
    async sendVerificationSms() {
      if (!this.pledge.mobilePhone || this.pledge.verificationSmsResponse || this.verifyingSms) return;

      try {
        this.verifyingSms = true;
        await new PledgeService().sendVerificationSms(this.pledge);
      } catch (error: any) {
        this.validationErrors = { ...this.validationErrors, mobilePhone: { message: error } };
      } finally {
        this.verifyingSms = false;
      }
    },
  },
});
