
import Vue from "vue";
import { BasePledge } from "@/models/BasePledge";
import CallbackFlowTemplateComponent from "@/components/CallbackFlowTemplateComponent.vue";
import UdfsComponent from "@/components/UdfsComponent.vue";
import { IClient } from "@/interfaces/IClient";
import { ClientStyleBuilder } from "@/models/ClientStyleBuilder";
import { BaseCallback } from "@/models/Callback/BaseCallback";
import { LogService } from "@/services/logService";

export default Vue.extend({
  name: "ContributionDetails",
  components: { CallbackFlowTemplateComponent, UdfsComponent },
  data() {
    return {
      callback: {} as BaseCallback,
      oldPledge: {} as BasePledge,
      pledge: {} as BasePledge,
      currentClient: {} as IClient,
      validationErrors: {} as Record<string, { message: string }>,
      frequencies: [] as Array<string>,
      values: [] as Array<string>,
      selectedAmount: "",
      otherAmount: 10,
      minPayment: 0,
      paymentMethods: ["Credit Card", "Direct Debit"],
      datesFirstPayment: [] as Array<{ name: string; value: string }>,
    };
  },
  computed: {
    cssStyle(): ClientStyleBuilder {
      return new ClientStyleBuilder(this.currentClient?.cssDetails);
    },
    amountCurrencyString() {
      let str_amount = "";

      if (this.pledge.country == "Australia") {
        str_amount = new Intl.NumberFormat("en-AU", { style: "currency", currency: "AUD" }).format(this.pledge.amount);
      } else if (this.pledge.country == "NewZealand") {
        str_amount = new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" }).format(this.pledge.amount);
      } else {
        str_amount = this.pledge.amount?.toString() ?? "";
      }

      return str_amount;
    },
    otherNumbers() {
      return [this.callback.mobilePhone, this.callback.homePhone, this.callback.workPhone].filter(Boolean);
    },
  },
  beforeMount: async function () {
    this.currentClient = this.$store.state.selectedClient;
    this.callback = this.$store.state.currentCallback;

    // client contribution amounts
    if (this.currentClient.contributionAmounts && this.currentClient.contributionAmounts.length > 0) {
      this.values = this.currentClient.contributionAmounts.map((amount: number) => amount.toString());
      this.values.push("Other");
    } else {
      this.values = ["35", "40", "50", "Other"];
    }

    // client frequencies
    if (this.currentClient.frequencies && this.currentClient.frequencies.length > 0) {
      this.frequencies = this.currentClient.frequencies;
    } else {
      this.frequencies = ["Monthly"];
    }

    // client date of first payment
    if (this.currentClient.dateOfFirstPayments && this.currentClient.dateOfFirstPayments.length > 0) {
      this.datesFirstPayment = this.currentClient.dateOfFirstPayments.map((o) => {
        return { name: new Date(o).toLocaleDateString(), value: o };
      });
    }

    // client min payment
    this.minPayment = this.currentClient.minPayment || 10;
    this.otherAmount = this.minPayment > 60 ? this.minPayment : 60;

    const currentPledge = this.$store.getters.currentPledge;
    if (currentPledge) this.pledge = currentPledge;

    // default pledge to Monthly if pledge is not already set
    if (!this.pledge.frequency && this.frequencies.filter((v) => v == "Monthly")?.length > 0) {
      const frequencyFound = this.frequencies.find((value) => value == "Monthly");
      this.pledge.frequency = frequencyFound || null;
    }

    // set current pledge amount
    if (this.pledge.amount) {
      const amountFound = this.values.find((value) => value == this.pledge.amount.toString());
      if (amountFound) {
        this.selectedAmount = amountFound;
      } else {
        this.selectedAmount = "Other";
        this.otherAmount = this.pledge.amount;
      }
    }

    this.oldPledge = Object.assign({}, this.pledge);

    console.log("udfs");
    console.log(this.callback);
    console.log(this.pledge);
  },
  methods: {
    clearError(field: string) {
      if (this.validationErrors && this.validationErrors[field]) {
        delete this.validationErrors[field];
      }
    },
    filterOnlyNumbers(e: Event) {
      (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value?.replace(/[^0-9]+/g, "");
    },
    setPledgeAmount() {
      if (this.selectedAmount == "Other") {
        this.pledge.amount = this.otherAmount;
      } else {
        this.pledge.amount = Number(this.selectedAmount);
      }
    },
    async validate() {
      this.validationErrors = this.pledge.validateContributionDetails(this.minPayment);
      // validate udfs
      const udfsValidationErrors = this.pledge.validateUdfs("contribution-details");
      this.validationErrors = { ...this.validationErrors, ...udfsValidationErrors };
    },
    savePledgeInStoreAndLocalStorage() {
      try {
        this.$store.dispatch("setCurrentPledge", this.pledge);
      } catch {
        console.error("error");
      }
    },
    async previousButton() {
      this.$store.dispatch("removeCurrentCallback");
      this.$store.dispatch("removeCurrentPledge");
      this.$router.push("/callback/callbacks");
    },
    async nextButton() {
      this.setPledgeAmount();

      this.validate();
      if (this.validationErrors && Object.keys(this.validationErrors).length > 0) return;

      await LogService.pledgeChangesLog(
        `Callback contribution details fulfilled, going to payment details`,
        this.oldPledge,
        this.pledge
      );

      this.savePledgeInStoreAndLocalStorage();

      this.$router.push("/callback/paymentdetails");
    },
    clearPledge() {
      this.$store.dispatch("clearCurrentPledge", this.pledge);
    },
  },
});
