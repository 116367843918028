import { Database } from "@/database";
import { IsOnlineHelper } from "@/helpers/isOnlineHelper";
import { Bsb } from "@/models/Bsb";
import { BaseService } from "./baseService";
import { LogService } from "./logService";

export class BankDetailsService extends BaseService {
  database: Database;
  onlineHelper: IsOnlineHelper;

  constructor() {
    super("bank");
    this.database = Database.getInstance();
    this.onlineHelper = IsOnlineHelper.getInstance();
  }

  async getAustralianBsbs(searchOnline = true): Promise<void> {
    try {
      LogService.info("About to request Australians BSB from the API");

      const isOnline = await this.onlineHelper.isOnline();
      if (isOnline && searchOnline) {
        const axiosInstance = await this.getAxiosInstance();
        const response = await axiosInstance.get<Bsb[]>("/allbsbdetails");

        // store bsbs
        if (response && response.data) {
          LogService.info(`Received ${response.data.length} BSBs from the API. Storing it in the IndexedDB`);
          await this.database.bsbs.bulkPut(response.data);
        } else {
          LogService.info(`No response received while fetching BSBs from the API.`);
        }
      } else {
        LogService.info("Couldn't reach API beacause the app is offline");
      }
    } catch (error: any) {
      LogService.error({
        message: `Error fetching BSBs from the API. Message: ${error.response.data}`,
        error: error,
      });
    }
  }

  async validateAustralianBsb(bsb: string): Promise<boolean> {
    try {
      LogService.info(`About ot validate the BSB ${bsb} against Australian BSBs`);

      const foundBsbs = await this.database.bsbs.where("bsb").equals(bsb).toArray();

      if (foundBsbs.length > 0) {
        LogService.info(`BSB ${bsb} found and validated against Australian BSBs`);
        return true;
      } else {
        LogService.info(`BSB ${bsb} not found and therefore not valid against Australian BSBs`);
        return false;
      }
    } catch (error: any) {
      LogService.error({
        message: `Error validating BSB ${bsb} against Australian BSBs`,
        error: error,
      });

      return false;
    }
  }

  /**
   * Returns a string response from Pluto API to validate NZ Account number details.
   * @return Empty string = no response
   * @return String message = able to reach the API, or there was an exception in the code
   */
  async validateNewZealandBankAccount(bankAndBranch: string, accountNumber: string, suffix = ""): Promise<string> {
    try {
      LogService.info(
        `About to validate the NZ bank and branch: ${bankAndBranch}, account: ${accountNumber}, suffix: ${suffix}`
      );

      const isOnline = await this.onlineHelper.isOnline();

      // try to validate only if online
      if (!isOnline) {
        return "";
      } else {
        let url = `/validatenzaccountdetails?bankAndBranch=${bankAndBranch}&accountNumber=${accountNumber}&suffix=${suffix}`;

        if (suffix) url += `&${suffix}`;

        const axiosInstance = await this.getAxiosInstance();
        const response = await axiosInstance.get<string>(url);

        if (!response || !response.data) {
          return "";
        }

        LogService.info(
          `Validation of the NZ bank and branch: ${bankAndBranch}, account: ${accountNumber}, suffix: ${suffix}, result: ${response.data}`
        );

        return response.data;
      }
    } catch (error: any) {
      let errorMessage = "";

      // error handling
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        errorMessage = "No response, please try again later";
      } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = error.message;
      }

      LogService.error({
        message: `Error validating the NZ bank and branch: ${bankAndBranch}, account: ${accountNumber}, suffix: ${suffix}. WIth message: ${errorMessage}`,
        error: error,
      });

      return `Error validating NZ Account Number: ${errorMessage}`;
    }
  }
}
