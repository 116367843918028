
import Vue from "vue";
import { BaseCallback } from "@/models/Callback/BaseCallback";
import { CallbackService } from "@/services/callbackService";
import { ICallbackReportItem } from "@/interfaces/Callback/ICallbackReportItem";
import { ToastHelper } from "@/helpers/toastHelper";
import { FundraiserService } from "@/services/fundraiserService";
import { ClientService } from "@/services/clientService";
import { IsOnlineHelper } from "@/helpers/isOnlineHelper";
import { DialogProgrammatic as Dialog } from "buefy";
import { LogService } from "@/services/logService";

export default Vue.extend({
  name: "Reports",
  data() {
    return {
      callbacksSynced: [] as ICallbackReportItem[],
      totalSyncedCallbacks: 0,
      perPageSyncedCallbacks: 10,
      currentPageSyncedCallbacks: 1,
      pendingCallbacks: [] as { callback: BaseCallback; syncing: boolean }[],
      reasonPendingCallbacksEmpty: "No records",
      reasonSyncedCallbacksEmpty: "No records",
      syncedCallbacksLoading: false,
      pendingCallbacksLoading: false,
      syncingCallback: false,
      dateFrom: new Date(),
      dateTo: new Date(),
      loadingClients: true,
      allClients: [] as { clientId: number; clientName: string }[],
      selectedClient: {} as { clientId: number; clientName: string },
    };
  },
  computed: {
    fundraiserId: function () {
      return this.$store.getters.currentUserId;
    },
    numberOfPendingCallbacks(): number {
      return this.$store.getters.numberOfPendingCallbacks;
    },
  },
  watch: {
    // if someone sync callbacks while in this page, it needs to refresh the data
    numPledgesToSync: function () {
      this.firstLoad();
    },
    numberOfPendingCallbacks: function () {
      this.firstLoad();
    },
  },
  mounted() {
    const oneWeek = 1000 * 60 * 60 * 24 * 7; // one week in miliseconds

    this.dateFrom = new Date(new Date().getTime() - oneWeek * 6);
    this.dateTo = new Date();

    this.allClients.push({ clientId: 0, clientName: "All" });
    this.selectedClient = this.allClients[0];

    const promisePastClients = new FundraiserService().getPastCallbacksClients();
    const promiseCurrentClients = new ClientService().getAvailableClients();

    Promise.all([promisePastClients, promiseCurrentClients])
      .then(([pastClients, availableClients]) => {
        for (const cli of pastClients) {
          if (!this.allClients.find((o) => o.clientId == cli.clientId)) {
            this.allClients.push(cli);
          }
        }
        for (const cli of availableClients) {
          if (!this.allClients.find((o) => o.clientId == cli.clientId)) {
            this.allClients.push({ clientId: cli.clientId, clientName: cli.clientName });
          }
        }

        this.allClients.sort((a, b) => (a.clientName < b.clientName ? -1 : 1));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.loadingClients = false;
      });

    this.firstLoad();
  },
  methods: {
    firstLoad() {
      this.loadPendingCallbacks();
      this.loadSyncedCallbacks();
    },
    loadPendingCallbacks() {
      const callbackService = new CallbackService();

      this.pendingCallbacksLoading = true;
      callbackService
        .getPendingCallbacks(this.fundraiserId)
        .then((response) => {
          this.pendingCallbacks = response.map((o) => {
            return { callback: o, syncing: false };
          });
        })
        .catch((error) => {
          this.reasonPendingCallbacksEmpty = "Error getting pending callbacks, please try again later";
          console.warn("Failed to get pending callbacks with message:", error);
        })
        .finally(() => {
          this.pendingCallbacksLoading = false;
        });
    },
    loadSyncedCallbacks() {
      console.warn("loading reports client: ", this.selectedClient.clientId);

      const onlineCheckHelper = IsOnlineHelper.getInstance();

      onlineCheckHelper
        .isOnline()
        .then((result) => {
          console.log("is online result:", result);

          if (!result) {
            this.reasonSyncedCallbacksEmpty = "Unable to load synced callbacks when offline";
          } else {
            const fundraiserService = new FundraiserService();

            let clientId = this.selectedClient?.clientId;

            this.syncedCallbacksLoading = true;
            fundraiserService
              .getSyncedCallbacksReport(
                this.dateFrom,
                this.dateTo,
                this.currentPageSyncedCallbacks,
                this.perPageSyncedCallbacks,
                clientId
              )
              .then((response) => {
                console.log("callbacks synced");
                console.log(response);
                this.totalSyncedCallbacks = response.total;
                this.perPageSyncedCallbacks = response.perPage;
                this.callbacksSynced = response.callbacks;
                console.log("callbacks synced");
                console.log(response.callbacks);
                if (!this.callbacksSynced || this.callbacksSynced.length <= 0) {
                  this.reasonSyncedCallbacksEmpty = "No callbacks to show";
                }
              })
              .catch((error) => {
                this.callbacksSynced = [];
                this.reasonSyncedCallbacksEmpty = "Error getting synced callbacks, please try again later";
                console.warn("Failed to get synced callbacks with message:", error);
              })
              .finally(() => {
                this.syncedCallbacksLoading = false;
              });
          }
        })
        .catch((error) => {
          console.error(error);
          ToastHelper.error("Error validating if app is online");
        });
    },
    onClientChanged() {
      this.currentPageSyncedCallbacks = 1;
      this.totalSyncedCallbacks = 0;
      this.loadSyncedCallbacks();
    },
    onPageChange(page: number) {
      console.warn("page changed: ", page);
      if (page != this.currentPageSyncedCallbacks) {
        console.warn("page changed reloading: ", page);
        this.currentPageSyncedCallbacks = page;
        this.loadSyncedCallbacks();
      }
    },
    onDatesChanges() {
      console.log("date changed", this.dateFrom.toISOString(), this.dateTo.toISOString());
      this.currentPageSyncedCallbacks = 1;
      this.totalSyncedCallbacks = 0;
      this.loadSyncedCallbacks();
    },
    async syncCallback(callbackGuid: string) {
      console.log(callbackGuid);

      try {
        const isOnline = await IsOnlineHelper.getInstance().isOnline();

        if (!isOnline) {
          ToastHelper.error("Offline Mode - Connect to the Internet to sync your callbacks");
        } else {
          const callbackService = new CallbackService();
          callbackService.syncCallback(callbackGuid).finally(() => {
            this.loadPendingCallbacks();
            this.loadSyncedCallbacks();
          });
        }
      } catch (error) {
        console.error(error);
        ToastHelper.error("Error validating if app is online");
      }
    },
    async syncCallbacks() {
      try {
        const isOnline = await IsOnlineHelper.getInstance().isOnline();

        if (!isOnline) {
          ToastHelper.error("Offline Mode - Connect to the Internet to sync your callbacks");
        } else {
          for (let i = 0; i < this.pendingCallbacks.length; i++) {
            this.syncCallback(this.pendingCallbacks[i].callback.callbackGuid);
          }
        }
      } catch (error) {
        console.error(error);
        ToastHelper.error("Error validating if app is online");
      }
    },
    async openCallbackContributionDetails(callbackId: number) {
      const onlineCheckHelper = IsOnlineHelper.getInstance();

      try {
        // check if online
        const online = await onlineCheckHelper.isOnline();
        if (!online) {
          ToastHelper.error("App is not online");
          return;
        }

        // get callback from the database
        const callbackService = new CallbackService();
        const callback = await callbackService.getCallback(callbackId);
        if (!callback) {
          ToastHelper.error("Callback not found");
          return;
        }

        // create pledge from callback
        const pledge = callback.createPledge();
        if (!pledge) {
          ToastHelper.error("Couldn't create pledge from the callback");
          return;
        }

        // find pledge client
        const clientService = new ClientService();
        const allClients = await clientService.getAvailableClients(false);
        const pledgeClient = allClients.find((o) => o.clientId == pledge.clientId);
        if (!pledgeClient) {
          ToastHelper.error("Couldn't find the pledge client");
          return;
        }

        // set current callback, pledge and client in the store
        this.$store.dispatch("setCurrentCallback", callback);
        this.$store.dispatch("setCurrentPledge", pledge);
        this.$store.dispatch("setCurrentClient", { client: pledgeClient });

        // go to callback completion flow
        this.$router.push("/callback/contributiondetails/");
      } catch (error: any) {
        LogService.error(error);
        ToastHelper.error("Error getting callback from database");
      }
    },
    deleteCallback(callbackId: number) {
      const onlineCheckHelper = IsOnlineHelper.getInstance();

      onlineCheckHelper
        .isOnline()
        .then((result) => {
          console.log("is online result:", result);

          Dialog.confirm({
            title: "Delete Callback",
            message: "Are you sure you want to <b>delete</b> this callback?",
            confirmText: "Yes",
            cancelText: "No",
            hasIcon: true,
            icon: "delete",
            onConfirm: () => {
              const callbackService = new CallbackService();
              callbackService
                .deleteCallback(callbackId)
                .then((result: string) => {
                  ToastHelper.success(result);
                })
                .catch((error: string) => {
                  ToastHelper.error(error);
                })
                .finally(() => {
                  this.loadSyncedCallbacks();
                });
            },
          });
        })
        .catch((error) => {
          console.error(error);
          ToastHelper.error("Error validating if app is online");
        });
    },
    logout() {
      this.$buefy.dialog.confirm({
        title: "Logout from account",
        message: "Are you sure you want to <b>logout</b> from Catalyst?",
        confirmText: "Yes",
        cancelText: "No",
        hasIcon: true,
        icon: "logout",
        onConfirm: () => {
          this.$store.dispatch("logout");
          this.$router.push("/");
        },
      });
    },
    goHome() {
      this.$router.push("/home");
    },
  },
});
