
import Vue from "vue";
import { PledgeService } from "@/services/pledgeService";
import { BasePledge } from "@/models/BasePledge";
import { IPledgeReportItem } from "@/interfaces/IPledgeReportItem";
import { ToastHelper } from "@/helpers/toastHelper";
import { FundraiserService } from "@/services/fundraiserService";
import { ClientService } from "@/services/clientService";
import { IsOnlineHelper } from "@/helpers/isOnlineHelper";
import FileSaver from "file-saver";
import { AustraliaPledge } from "@/models/AustraliaPledge";
import { NewZealandPledge } from "@/models/NewZealandPledge";
import { EncryptedPledge } from "@/models/EncryptedPledge";

export default Vue.extend({
  name: "PledgesSubmittedComponent",
  data() {
    return {
      pledgesSynced: [] as IPledgeReportItem[],
      totalSyncedPledge: 0,
      perPageSyncedPledge: 10,
      currentPageSyncedPledge: 1,
      pledgesToSync: [] as { pledge: EncryptedPledge; syncing: boolean }[],
      reasonNotSyncedPledgesEmpty: "No records",
      reasonSyncedPledgesEmpty: "No records",
      syncedPledgesLoading: false,
      notSyncedPledgesLoading: false,
      syncingPledge: false,
      dateFrom: new Date(),
      dateTo: new Date(),
      loadingClients: true,
      allClients: [] as { clientId: number; clientName: string }[],
      selectedClient: {} as { clientId: number; clientName: string },
    };
  },
  computed: {
    fundraiserId: function () {
      return this.$store.getters.currentUserId;
    },
    numPledgesToSync(): number {
      return this.$store.getters.numberOfPledgesToSync;
    },
  },
  watch: {
    // if someone sync pledges while in this page, it needs to refresh the data
    numPledgesToSync: function () {
      this.firstLoad();
    },
  },
  mounted() {
    const oneWeek = 1000 * 60 * 60 * 24 * 7; // one week in miliseconds

    this.dateFrom = new Date(new Date().getTime() - oneWeek * 6);
    this.dateTo = new Date();

    this.allClients.push({ clientId: 0, clientName: "All" });
    this.selectedClient = this.allClients[0];

    const promisePastClients = new FundraiserService().getPastPledgesClients();
    const promiseCurrentClients = new ClientService().getAvailableClients();

    Promise.all([promisePastClients, promiseCurrentClients])
      .then(([pastClients, availableClients]) => {
        for (const cli of pastClients) {
          if (!this.allClients.find((o) => o.clientId == cli.clientId)) {
            this.allClients.push(cli);
          }
        }
        for (const cli of availableClients) {
          if (!this.allClients.find((o) => o.clientId == cli.clientId)) {
            this.allClients.push({ clientId: cli.clientId, clientName: cli.clientName });
          }
        }

        this.allClients.sort((a, b) => (a.clientName < b.clientName ? -1 : 1));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.loadingClients = false;
      });

    this.firstLoad();
  },
  methods: {
    firstLoad() {
      this.loadSyncedPledges();
      this.loadPendingPledges();
    },
    loadPendingPledges() {
      const pledgeService = new PledgeService();

      this.notSyncedPledgesLoading = true;
      pledgeService
        .getPledgesToSync(this.fundraiserId)
        .then((response) => {
          this.pledgesToSync = response.map((o) => {
            return { pledge: o, syncing: false };
          });
        })
        .catch((error) => {
          this.reasonNotSyncedPledgesEmpty = "Error getting unsynced pledges, please try again later";
          console.warn("Failed to get pledges to submit with message:", error);
        })
        .finally(() => {
          this.notSyncedPledgesLoading = false;
        });
    },
    loadSyncedPledges() {
      console.warn("loading reports client: ", this.selectedClient.clientId);

      const onlineCheckHelper = IsOnlineHelper.getInstance();

      onlineCheckHelper
        .isOnline()
        .then((result) => {
          console.log("is online result:", result);

          if (!result) {
            this.reasonSyncedPledgesEmpty = "Unable to load synced pledges when offline";
          } else {
            const fundraiserService = new FundraiserService();

            let clientId = this.selectedClient?.clientId;

            this.syncedPledgesLoading = true;
            fundraiserService
              .getSubmittedPledgesReport(
                this.dateFrom,
                this.dateTo,
                this.currentPageSyncedPledge,
                this.perPageSyncedPledge,
                clientId
              )
              .then((response) => {
                this.totalSyncedPledge = response.total;
                this.perPageSyncedPledge = response.perPage;
                this.pledgesSynced = response.pledges;
                if (!this.pledgesSynced || this.pledgesSynced.length <= 0) {
                  this.reasonSyncedPledgesEmpty = "No pledges to show";
                }
              })
              .catch((error) => {
                this.pledgesSynced = [];
                this.reasonSyncedPledgesEmpty = "Error getting synced pledges, please try again later";
                console.warn("Failed to get pledges submitted with message:", error);
              })
              .finally(() => {
                this.syncedPledgesLoading = false;
              });
          }
        })
        .catch((error) => {
          console.error(error);
          ToastHelper.error("Error validating if app is online");
        });
    },
    onClientChanged() {
      this.currentPageSyncedPledge = 1;
      this.totalSyncedPledge = 0;
      this.loadSyncedPledges();
    },
    onPageChange(page: number) {
      console.warn("page changed: ", page);
      if (page != this.currentPageSyncedPledge) {
        console.warn("page changed reloading: ", page);
        this.currentPageSyncedPledge = page;
        this.loadSyncedPledges();
      }
    },
    onDatesChanges() {
      console.log("date changed", this.dateFrom.toISOString(), this.dateTo.toISOString());
      this.currentPageSyncedPledge = 1;
      this.totalSyncedPledge = 0;
      this.loadSyncedPledges();
    },
    async syncPledge(pledgeGuid: string) {
      console.log(pledgeGuid);

      try {
        const isOnline = await IsOnlineHelper.getInstance().isOnline();

        if (!isOnline) {
          ToastHelper.error("Offline Mode - Connect to the Internet to Sync your Pledges");
        } else {
          const service = new PledgeService();
          service.syncPledge(pledgeGuid).finally(() => {
            this.loadSyncedPledges();
            this.loadPendingPledges();
          });
        }
      } catch (error) {
        console.error(error);
        ToastHelper.error("Error validating if app is online");
      }
    },
    async syncPledges() {
      try {
        const isOnline = await IsOnlineHelper.getInstance().isOnline();

        if (!isOnline) {
          ToastHelper.error("Offline Mode - Connect to the Internet to sync your pledges");
        } else {
          for (let i = 0; i < this.pledgesToSync.length; i++) {
            this.syncPledge(this.pledgesToSync[i].pledge.pledgeGuid);
          }
        }
      } catch (error) {
        console.error(error);
        ToastHelper.error("Error validating if app is online");
      }
    },
    downloadPledgeFile(pledge: EncryptedPledge) {
      const currentDate = new Date();
      const dateStr = `${currentDate.getFullYear()}-${
        currentDate.getMonth() + 1
      }-${currentDate.getDate()}-${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}`;

      FileSaver.saveAs(
        new Blob([pledge.encryptedPledgeData], {
          type: "text/plain;charset=utf-8",
        }),
        `${dateStr}_${pledge.pledgeGuid}.edc`
      );
    },
    logout() {
      this.$buefy.dialog.confirm({
        title: "Logout from account",
        message: "Are you sure you want to <b>logout</b> from Catalyst?",
        confirmText: "Yes",
        cancelText: "No",
        hasIcon: true,
        icon: "logout",
        onConfirm: () => {
          this.$store.dispatch("logout");
          this.$router.push("/");
        },
      });
    },
    goHome() {
      this.$router.push("/home");
    },
  },
});
