
import Vue from "vue";
import { ClientStyleBuilder } from "@/models/ClientStyleBuilder";
import { IClient } from "@/interfaces/IClient";
import { FundraiserService } from "@/services/fundraiserService";
import { ToastHelper } from "@/helpers/toastHelper";

export default Vue.extend({
  components: {},
  name: "Profile",
  computed: {
    cssStyle(): ClientStyleBuilder {
      const client = this.$store.getters.selectedClient as IClient;
      return new ClientStyleBuilder(client?.cssDetails);
    },
    fundraiserId(): number {
      return this.$store.getters.currentUserId;
    },
  },
  data() {
    return {
      fundraiserProfile: {} as any,
    };
  },
  mounted() {
    this.getProfileData();
  },
  methods: {
    getProfileData() {
      new FundraiserService().getFundraiserProfile().then((response) => {
        this.fundraiserProfile = response;
      });
    },
    updateProfileData() {
      new FundraiserService()
        .updateFundraiserProfile(this.fundraiserProfile.mobileNumber, this.fundraiserProfile.email)
        .then(() => {
          ToastHelper.success("Profile data updated");
        })
        .catch(() => {
          ToastHelper.error("There was an error updating your profile data. Plaese contact IT Support");
        });
    },
    resetPin() {
      alert("need to reset PIN");
    },
    logout() {
      this.$buefy.dialog.confirm({
        title: "Logout from account",
        message: "Are you sure you want to <b>logout</b> from Catalyst?",
        confirmText: "Yes",
        cancelText: "No",
        hasIcon: true,
        icon: "logout",
        onConfirm: () => {
          this.$store.dispatch("logout");
          this.$router.push("/");
        },
      });
    },
    goHome() {
      this.$router.push("/home");
    },
  },
});
