
import Vue from "vue";
import { BasePledge } from "@/models/BasePledge";
import PledgeFlowTemplateComponent from "@/components/PledgeFlowTemplateComponent.vue";
import SignatureComponent from "@/components/SignatureComponent.vue";
import { PledgeService } from "@/services/pledgeService";
import { ClientStyleBuilder } from "@/models/ClientStyleBuilder";
import { IClient } from "@/interfaces/IClient";
import { LogService } from "@/services/logService";

export default Vue.extend({
  name: "Confirmation",
  components: { PledgeFlowTemplateComponent, SignatureComponent },
  data() {
    return {
      oldPledge: {} as BasePledge,
      pledge: {} as BasePledge,
      accepted: [],
      validationErrors: {} as Record<string, { message: string }>,
    };
  },
  computed: {
    online(): boolean {
      return this.$store.state.onlineIndicator;
    },
    client(): IClient {
      return this.$store.getters.selectedClient;
    },
    cssStyle(): ClientStyleBuilder {
      const client = this.client;
      return new ClientStyleBuilder(client?.cssDetails);
    },
    username() {
      return this.$store.getters.currentUserName;
    },
    amountCurrencyString() {
      let str_amount = "";

      if (this.pledge.country == "Australia") {
        str_amount = new Intl.NumberFormat("en-AU", { style: "currency", currency: "AUD" }).format(this.pledge.amount);
      } else if (this.pledge.country == "NewZealand") {
        str_amount = new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" }).format(this.pledge.amount);
      } else {
        str_amount = this.pledge.amount?.toString() ?? "";
      }

      return str_amount;
    },
  },
  beforeMount: function () {
    const currentPledge = this.$store.getters.currentPledge;
    if (currentPledge) this.pledge = currentPledge;

    this.oldPledge = Object.assign({}, this.pledge);

    console.log(this.pledge);
  },
  methods: {
    clearError(field: string) {
      console.log("cleaning", field);
      if (this.validationErrors && this.validationErrors[field]) {
        delete this.validationErrors[field];
      }
    },
    async submitButton() {
      // validate pledge signature
      this.validationErrors = this.pledge.validateSignatures();

      // validate I Accept button
      if (this.accepted.length <= 0 || !this.accepted.find((value) => value == "accepted")) {
        this.validationErrors["accepted"] = { message: "Please accept to proceed" };
      }

      if (this.validationErrors && Object.keys(this.validationErrors).length > 0) {
        console.log("erros:", Object.keys(this.validationErrors).length);
        console.log(this.validationErrors);
        return;
      }

      try {
        this.$store.dispatch("setCurrentPledge", this.pledge);
      } catch {
        console.error("error");
      }

      await LogService.pledgeLog(`Pledge confirmed, about to submit pledge`, this.pledge);

      const pledgeService = new PledgeService();
      pledgeService
        .storePledge(this.$store.state.rsaPublicKey, this.pledge)
        .then(async (response) => {
          if (response.error) {
            this.$buefy.toast.open({
              type: "is-warning",
              message: response.error,
              duration: 5000,
            });
          }

          await LogService.pledgeChangesLog(
            "Pledge stored in device. Attempt to sync via API",
            this.oldPledge,
            this.pledge
          );

          if (this.online) {
            pledgeService.syncPledges();
          } else {
            LogService.pledgeLog(
              `Unable to syng pledge ${this.pledge.pledgeGuid} after submit because the app is offline.`,
              this.pledge
            );
          }

          this.$router.push("/pledge/thankyou");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    previousButton: function () {
      // redirect to payment details
      this.$router.push("/pledge/paymentdetails");
    },
    clearPledge() {
      this.$store.dispatch("clearCurrentPledge", this.pledge);
    },
    getSmallSignatureText3() {
      if (this.client.clientId == 427015)
        return `I look forward to supporting ${this.client.clientEdcName} for as many years possible.`;
      else return `I look forward to supporting ${this.client.clientEdcName} for at least 2 years and beyond.`;
    },
  },
});
