
import Vue from "vue";
import PledgesSubmittedPieChart from "./Charts/PledgesSubmittedPieChart.vue";
import { ReportService } from "@/services/reportService";
import AgeBreakdownPieChart from "./Charts/AgeBreakdownPieChart.vue";
import PaymentTypesPieChart from "./Charts/PaymentTypesPieChart.vue";
import GenderPieChart from "./Charts/GenderPieChart.vue";
import UnitsPieChart from "./Charts/UnitsPieChart.vue";
import Under35Widget from "./Charts/Under35Widget.vue";
import Over35Widget from "./Charts/Over35Widget.vue";
import AverageAgeWidget from "./Charts/AverageAgeWidget.vue";
import ActiveUnitsWidget from "./Charts/ActiveUnitsWidget.vue";
import FundraisersWidget from "./Charts/FundraisersWidget.vue";
import WelcomeCallContactWidget from "./Charts/WelcomeCallContactWidget.vue";
import AverageGiftWidget from "./Charts/AverageGiftWidget.vue";

export default Vue.extend({
  name: "DashboardComponent",
  components: {
    PledgesSubmittedPieChart,
    AgeBreakdownPieChart,
    PaymentTypesPieChart,
    GenderPieChart,
    UnitsPieChart,
    Under35Widget,
    Over35Widget,
    AverageAgeWidget,
    ActiveUnitsWidget,
    FundraisersWidget,
    WelcomeCallContactWidget,
    AverageGiftWidget,
  },
  props: {},
  computed: {
    online(): boolean {
      return this.$store.state.onlineIndicator;
    },
    fundraiserId(): number {
      return this.$store.getters.currentUserId;
    },
  },
  watch: {},
  data() {
    return {
      dateFrom: new Date(),
      dateTo: new Date(),
      selectedFundraiser: { name: "-- All --", fundraiserId: 0 },
      fundraiserList: [{ name: "-- All --", fundraiserId: 0 }] as { name: string; fundraiserId: number }[],
      pledgesToReport: [] as Array<any>,
    };
  },
  beforeMount() {
    this.dateFrom = this.getPastMonday(new Date());
    this.dateTo = this.getNextSunday(new Date());

    const reportService = new ReportService();

    reportService.getManagedFundraisers().then((response) => {
      this.fundraiserList = this.fundraiserList.concat(
        response
          .map((o) => {
            return {
              name: o.participantName,
              fundraiserId: o.supplierId,
            };
          })
          .sort((a, b) => (a.name || "").localeCompare(b.name))
      );

      this.loadData();
    });
  },
  methods: {
    onFundraiserChanged() {
      this.loadData();
    },
    onDatesChanges() {
      this.loadData();
    },
    loadData() {
      const reportService = new ReportService();

      reportService
        .getSubmittedPledges(this.dateFrom, this.dateTo, this.selectedFundraiser.fundraiserId)
        .then((response) => {
          this.pledgesToReport = response;
        });
    },
    getPastMonday(d: Date) {
      d = new Date(d);
      var day = d.getDay();
      var diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    getNextSunday(d: Date) {
      d = new Date(d);
      var day = d.getDay();
      var diff = d.getDate() + (7 - (day == 0 ? 7 : day)); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
  },
});
