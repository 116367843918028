<style lang="scss" scoped>
.cornucopia-logo {
  max-width: 400px;
  margin-bottom: 40px;
  text-align: center;
}
.container {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.version {
  font-size: 0.9em;
  margin-top: 2em;
  align-self: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-page {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  div,
  label,
  /deep/ label.label,
  .checkbox:hover,
  h1 {
    color: #ffffff !important;
  }

  .covid-safety-section {
    flex: 1;
    padding: 0 20px 0 10px;

    :not(:first-child) {
      margin-top: 1em;
    }
  }
  .credentials-section {
    flex: 1;
    padding: 0 10px 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .login-btn,
    .login-btn:hover {
      font-weight: bold;
      background-color: #e4b163;
      border-color: #e4b163;
    }
  }
}
</style>

<template>
  <div class="container">
    <img class="cornucopia-logo" src="@/assets/cornucopia-logo.png" />
    <div class="login-page">
      <div class="credentials-section">
        <h1 class="title">Hello there!</h1>
        <div class="mb-4">Please enter your supplier ID & Access Pin to continue</div>
        <b-field label="Supplier ID">
          <b-input
            v-model="fundraiserId"
            type="text"
            :maxlength="5"
            :has-counter="false"
            @input.native="filterOnlyNumbers"
            style="width: 200px"
            :disabled="loading"
            inputmode="numeric"
          ></b-input>
        </b-field>
        <b-field label="PIN">
          <b-input
            v-model="pin"
            type="password"
            :maxlength="4"
            :has-counter="false"
            @input.native="filterOnlyNumbers"
            password-reveal
            style="width: 200px"
            :disabled="loading"
            inputmode="numeric"
          >
          </b-input>
        </b-field>
        <div class="mt-2">
          <button class="button login-btn" v-bind:class="{ 'is-loading': loading }" v-on:click="doLogin()">
            Login
          </button>
        </div>
      </div>
    </div>
    <version-component></version-component>
  </div>
</template>

<script>
import Vue from "vue";
import VersionComponent from "@/components/VersionComponent.vue";

export default Vue.extend({
  name: "Login",
  components: { VersionComponent },
  data() {
    return {
      fundraiserId: "",
      pin: "",
      loading: false,
    };
  },
  methods: {
    filterOnlyNumbers(e) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
    },
    doLogin: function () {
      const fundraiserId = this.fundraiserId;
      const pin = this.pin;
      this.loading = true;

      this.$store
        .dispatch("login", { fundraiserId, pin })
        .then(() => {
          //this.$router.push(this.$route.query.redirect || "/");
          this.$router.push("/setup");
        })
        .catch((err) => {
          this.$buefy.toast.open({
            duration: 4000,
            message: "Login failed",
            type: "is-danger",
          });
          console.error("Login failed error:", err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
</script>
