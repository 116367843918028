import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      // refresh variables
      refreshing: false,
      registration: null as any,
      updateExists: false,
    };
  },

  created() {
    console.warn("registering listener for swUpdated");

    // Listen for our custom event from the SW registration
    document.addEventListener("swUpdated", this.updateAvailable.bind(this), { once: true });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      console.warn("event controllerchange fired");
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },

  methods: {
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable(event: any) {
      console.warn("update available method in mixin");
      this.registration = event.detail;
      this.updateExists = true;

      this.$buefy.dialog.alert({
        title: "Update available",
        message: "There is an update available, the app will update once you click <b>OK</b>!",
        confirmText: "Ok!",
        onConfirm: () => this.refreshApp(),
      });
    },

    // Called when the user accepts the update
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration?.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },
});
